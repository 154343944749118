import { BigNumber } from "ethers";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import ReaderV2 from "abis/ReaderV2.json";
import { getContract } from "config/contracts";
import { getWhitelistedTokens } from "config/tokens";
import useSWR from "swr";
import { InfoTokens } from "domain/tokens";
import { expandDecimals } from "lib/numbers";
import { useWeb3React } from "@web3-react/core";
import VaultV2 from "abis/VaultV2.json";

/**
 * Returns the total fee accrued in USD
 * @param tokenAddresses
 * @param fees Fees in token
 * @param infoTokens Token info containing price
 * @returns
 */
function getCurrentFeesUsd(tokenAddresses: string[], fees?: BigNumber[], infoTokens?: InfoTokens) {
  if (!fees || !infoTokens) {
    return BigNumber.from(0);
  }

  let currentFeesUsd = BigNumber.from(0);
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenInfo = infoTokens[tokenAddress];
    if (!tokenInfo || !tokenInfo.contractMinPrice) {
      continue;
    }

    // Multiply fee amount with USD price
    const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals));
    currentFeesUsd = currentFeesUsd.add(feeUsd);
  }

  return currentFeesUsd;
}

export function useUncollectedFees(infoTokens: InfoTokens | undefined) {
  const { chainId } = useChainId();

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);

  const readerAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");

  const { data } = useSWR<BigNumber[]>([`Dashboard:fees:${chainId}`, chainId, readerAddress, "getFees", vaultAddress], {
    fetcher: contractFetcher(undefined, ReaderV2, [whitelistedTokenAddresses]),
  });

  return getCurrentFeesUsd(whitelistedTokenAddresses, data, infoTokens);
}

export function useTotalTokenWeights() {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();
  const vaultAddress = getContract(chainId, "Vault");

  const { data } = useSWR<BigNumber>(
    [`GlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: contractFetcher(library, VaultV2),
    }
  );

  return data;
}
