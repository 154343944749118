import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Reads Discord access token from the URL, clears the query params and loads the value into state.
 *
 * @returns
 */
export default function useDiscordAccessToken(searchString: string): string | undefined {
  const history = useHistory();

  const [discordAccessToken, setAccessToken] = useState<string | undefined>();
  const searchParams = new URLSearchParams(searchString);
  const paramsAccessToken = searchParams.get("discord_access_token");

  useEffect(() => {
    if (paramsAccessToken !== null) {
      setAccessToken(paramsAccessToken);

      history.push({ search: "" });
    }
  }, [paramsAccessToken]);

  return discordAccessToken;
}
