import React from "react";
import style from "./ChartTokenSelectorValues.module.css";

import cx from "classnames";
import { useChainId } from "lib/chains";
import { getWhitelistedTokens } from "config/tokens";

export const ChartTokenSelectorValues = (props) => {
  const { symbol } = props;

  const { chainId } = useChainId();

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  const firstTokenImage = visibleTokens.filter((token) => token.symbol === symbol)[0]?.imageUrl;

  return (
    <div className={style.chertTokenSelectorValues}>
      <div className={style.chertTokenSelectorValues__images}>
        <img
          className={cx(style.chertTokenSelectorValues__image, style.ChartTokenSelectorValues__image_move)}
          src={firstTokenImage}
          alt="firstTokenImage"
        />
      </div>
      <span className="chart-token-selector--current">{symbol}/USD</span>
    </div>
  );
};
