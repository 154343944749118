import React, { useState, useEffect, useCallback, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import { Link } from "react-router-dom";
import Tooltip from "components/Tooltip/Tooltip";

import {
  USD_DECIMALS,
  MAX_LEVERAGE,
  BASIS_POINTS_DIVISOR,
  LIQUIDATION_FEE,
  TRADES_PAGE_SIZE,
  getExchangeRateDisplay,
} from "lib/legacy";
import { useTrades, useLiquidationsData, LiquidatedPosition } from "domain/legacy";
import { getContract } from "config/contracts";

import "./TradeHistory.css";
import { getExplorerUrl } from "config/chains";
import { formatAmount } from "lib/numbers";
import { formatDateTime } from "lib/dates";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { t, Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import {
  BuyUSDGEvent,
  SwapEvent,
  CreateIncreasePositionEvent,
  CreateDecreasePositionEvent,
  LiquidatePositionEvent,
  ExecuteIncreaseOrderEvent,
  ExecuteDecreaseOrderEvent,
  CancelDecreaseOrderEvent,
  CancelIncreaseOrderEvent,
  CreateDecreaseOrderEvent,
  CreateIncreaseOrderEvent,
  UpdateDecreaseOrderEvent,
  UpdateIncreaseOrderEvent,
  ExecuteSwapOrderEvent,
  CancelSwapOrderEvent,
  CreateSwapOrderEvent,
  UpdateSwapOrderEvent,
  CancelIncreasePositionEvent,
  CancelDecreasePositionEvent,
  IncreasePositionEvent,
  DecreasePositionEvent,
  TrackedEvent,
} from "./TradeHistoryEvents";
import { getTokenInfo, InfoTokens } from "domain/tokens";

const { AddressZero } = ethers.constants;

function getPositionDisplay(increase: boolean, indexToken, isLong: boolean, sizeDelta: string) {
  const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : "";
  return `
    ${increase ? t`Increase` : t`Decrease`} ${symbol} ${isLong ? t`Long` : t`Short`}
    ${increase ? "+" : "-"}${formatAmount(sizeDelta, USD_DECIMALS, 2, true)} USD`;
}

function getOrderActionTitle(action: string) {
  let actionDisplay!: string;

  if (action.startsWith("Create")) {
    actionDisplay = t`Create`;
  } else if (action.startsWith("Cancel")) {
    actionDisplay = t`Cancel`;
  } else {
    actionDisplay = t`Update`;
  }

  return t`${actionDisplay} Order`;
}

function renderLiquidationTooltip(liquidationData: LiquidatedPosition, label: string) {
  const minCollateral = liquidationData.size.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE);
  const text =
    liquidationData.type === "full"
      ? t`This position was liquidated as the max leverage of 100x was exceeded.`
      : t`Max leverage of 100x was exceeded, the remaining collateral after deducting losses and fees have been sent back to your account:`;
  return (
    <Tooltip
      position="left-top"
      handle={label}
      renderContent={() => (
        <div className="Exchange-info-tooltip">
          {text}
          <br />
          <br />
          <StatsTooltipRow
            label={t`Initial collateral`}
            showDollar
            value={formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label={t`Min required collateral`}
            showDollar
            value={formatAmount(minCollateral, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label={t`Borrow Fee`}
            showDollar
            value={formatAmount(liquidationData.borrowFee, USD_DECIMALS, 2, true)}
          />
          <StatsTooltipRow
            label={t`PnL`}
            showDollar={false}
            value={`-$${formatAmount(liquidationData.loss, USD_DECIMALS, 2, true)}`}
          />
          {liquidationData.type === "full" && (
            <StatsTooltipRow label={t`Liquidation Fee`} showDollar value={formatAmount(LIQUIDATION_FEE, 30, 2, true)} />
          )}
        </div>
      )}
    />
  );
}

interface LiquidatedPositionMap {
  [key: string]: LiquidatedPosition;
}

function getLiquidationData(liquidationsDataMap: LiquidatedPositionMap | null, key: string, timestamp: number) {
  return liquidationsDataMap?.[`${key}:${timestamp}`];
}

interface TradeHistoryProps {
  account: string;
  infoTokens: InfoTokens;
  chainId: number;
  nativeTokenAddress: string;
  shouldShowPaginationButtons: boolean;
  forSingleAccount?: boolean;
}

export default function TradeHistory({
  account,
  infoTokens,
  chainId,
  nativeTokenAddress,
  shouldShowPaginationButtons,
  forSingleAccount = false,
}: TradeHistoryProps) {
  const [pageIds, setPageIds] = useState<{ [key: number]: string }>({});
  const [pageIndex, setPageIndex] = useState(0);

  const { trades, updateTrades } = useTrades(chainId, account, forSingleAccount, pageIds[pageIndex]);

  const liquidationsData = useLiquidationsData(chainId, account);

  const liquidationsDataMap = useMemo(() => {
    if (!liquidationsData) {
      return null;
    }
    return liquidationsData.reduce((memo, item) => {
      const liquidationKey = `${item.key}:${item.timestamp}`;
      memo[liquidationKey] = item;
      return memo;
    }, {}) as LiquidatedPositionMap;
  }, [liquidationsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTrades(undefined, true);
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [updateTrades]);

  const loadNextPage = () => {
    if (!trades || trades.length === 0) {
      return;
    }

    const lastTrade = trades[trades.length - 1];
    pageIds[pageIndex + 1] = lastTrade.transactionHash;
    setPageIds(pageIds);
    setPageIndex(pageIndex + 1);
  };

  const loadPrevPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const getMsg = useCallback(
    (trade: TrackedEvent) => {
      const params = trade.params;

      const defaultMsg = "";

      if (trade.name === "BuyUSDG") {
        const typedParams = params as BuyUSDGEvent;
        const token = getTokenInfo(infoTokens, typedParams.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }
        return t`Swap ${formatAmount(typedParams.tokenAmount, token.decimals, 4, true)} ${
          token.symbol
        } for ${formatAmount(typedParams.usdgAmount, 18, 4, true)} USDG`;
      }

      if (trade.name === "SellUSDG") {
        const typedParams = params as BuyUSDGEvent;
        const token = getTokenInfo(infoTokens, typedParams.token, true, nativeTokenAddress);
        if (!token) {
          return defaultMsg;
        }
        return t`Swap ${formatAmount(typedParams.usdgAmount, 18, 4, true)} USDG for ${formatAmount(
          typedParams.tokenAmount,
          token.decimals,
          4,
          true
        )} ${token.symbol}`;
      }

      if (trade.name === "Swap") {
        const typedParams = params as SwapEvent;
        const tokenIn = getTokenInfo(infoTokens, typedParams.tokenIn, true, nativeTokenAddress);
        const tokenOut = getTokenInfo(infoTokens, typedParams.tokenOut, true, nativeTokenAddress);
        if (!tokenIn || !tokenOut) {
          return defaultMsg;
        }
        return t`Swap ${formatAmount(typedParams.amountIn, tokenIn.decimals, 4, true)} ${
          tokenIn.symbol
        } for ${formatAmount(typedParams.amountOut, tokenOut.decimals, 4, true)} ${tokenOut.symbol}`;
      }

      if (trade.name === "CreateIncreasePosition") {
        const typedParams = params as CreateIncreasePositionEvent;
        const longOrShortText = typedParams?.isLong ? t`Long` : t`Short`;

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (BigNumber.from(typedParams.sizeDelta).eq(0)) {
          return t`Request deposit into ${indexToken.symbol} ${longOrShortText}`;
        }

        return t`Request increase ${indexToken.symbol} ${longOrShortText}, +${formatAmount(
          typedParams.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD, Acceptable Price: ${typedParams.isLong ? "<" : ">"} ${formatAmount(
          typedParams.acceptablePrice,
          USD_DECIMALS,
          4,
          true
        )} USD`;
      }

      if (trade.name === "CreateDecreasePosition") {
        const typedParams = params as CreateDecreasePositionEvent;
        const longOrShortText = typedParams?.isLong ? t`Long` : t`Short`;

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (BigNumber.from(typedParams.sizeDelta).eq(0)) {
          return t`Request withdrawal from ${indexToken.symbol} ${longOrShortText}`;
        }

        return t`Request decrease ${indexToken.symbol} ${longOrShortText}, -${formatAmount(
          typedParams.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD, Acceptable Price: ${typedParams.isLong ? ">" : "<"} ${formatAmount(
          typedParams.acceptablePrice,
          USD_DECIMALS,
          4,
          true
        )} USD`;
      }

      if (trade.name === "CancelIncreasePosition") {
        const typedParams = params as CancelIncreasePositionEvent;
        const longOrShortText = typedParams?.isLong ? t`Long` : t`Short`;

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (BigNumber.from(typedParams.sizeDelta).eq(0)) {
          return (
            <Trans>
              Could not execute deposit into {indexToken.symbol} {longOrShortText}
            </Trans>
          );
        }

        return (
          <>
            <Trans>
              Could not increase {indexToken.symbol} {longOrShortText},
              {`+${formatAmount(typedParams.sizeDelta, USD_DECIMALS, 2, true)}`} USD, Acceptable Price:&nbsp;
              {typedParams.isLong ? "<" : ">"}&nbsp;
            </Trans>
            <Tooltip
              position="center-top"
              handle={`${formatAmount(typedParams.acceptablePrice, USD_DECIMALS, 4, true)} USD`}
              renderContent={() => (
                <div className="Exchange-info-tooltip">
                  <Trans>Try increasing the "Allowed Slippage", under the Settings menu on the top right.</Trans>
                </div>
              )}
            />
          </>
        );
      }

      if (trade.name === "CancelDecreasePosition") {
        const typedParams = params as CancelDecreasePositionEvent;
        const longOrShortText = typedParams?.isLong ? t`Long` : t`Short`;

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }

        if (BigNumber.from(typedParams.sizeDelta).eq(0)) {
          return t`Could not execute withdrawal from ${indexToken.symbol} ${longOrShortText}`;
        }

        return (
          <>
            <Trans>
              Could not decrease {indexToken.symbol} {longOrShortText},
              {`+${formatAmount(typedParams.sizeDelta, USD_DECIMALS, 2, true)}`} USD, Acceptable Price:&nbsp;
              {typedParams.isLong ? ">" : "<"}&nbsp;
            </Trans>
            <Tooltip
              position="right-top"
              handle={`${formatAmount(typedParams.acceptablePrice, USD_DECIMALS, 4, true)} USD`}
              renderContent={() => (
                <div className="Exchange-info-tooltip">
                  <Trans>Try increasing the "Allowed Slippage", under the Settings menu on the top right</Trans>
                </div>
              )}
            />
          </>
        );
      }

      if (trade.name === "IncreasePosition") {
        const typedParams = params as IncreasePositionEvent;
        const longOrShortText = typedParams?.isLong ? t`Long` : t`Short`;

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        // Add collateral to reduce leverage
        if (BigNumber.from(typedParams.sizeDelta).eq(0)) {
          return t`Deposit ${formatAmount(typedParams.collateralDelta, USD_DECIMALS, 2, true)} USD into ${
            indexToken.symbol
          } ${longOrShortText}`;
        }
        // Increase long case
        return t`Increased ${indexToken.symbol} ${longOrShortText}, +${formatAmount(
          typedParams.sizeDelta,
          USD_DECIMALS,
          2,
          true
        )} USD, ${indexToken.symbol} Price: ${formatAmount(typedParams.price, USD_DECIMALS, 4, true)} USD`;
      }

      // Also includes LiquidatePositionEvent case
      if (trade.name === "DecreasePosition") {
        const typedParams = params as DecreasePositionEvent;
        const longOrShortText = typedParams?.isLong ? t`Long` : t`Short`;

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        if (BigNumber.from(typedParams.sizeDelta).eq(0)) {
          return t`Withdraw ${formatAmount(typedParams.collateralDelta, USD_DECIMALS, 2, true)} USD from ${
            indexToken.symbol
          } ${longOrShortText}`;
        }

        // Subgraph should eventually index partial liquidation
        // But we're not indexing timestamp on server
        const liquidationData = getLiquidationData(liquidationsDataMap, typedParams.key, trade.blockTime);

        if (liquidationData !== undefined) {
          return (
            <>
              {renderLiquidationTooltip(liquidationData, t`Partial Liquidation`)}&nbsp;
              {indexToken.symbol} {longOrShortText}, -{formatAmount(typedParams.sizeDelta, USD_DECIMALS, 2, true)} USD,{" "}
              {indexToken.symbol}&nbsp; Price: ${formatAmount(typedParams.price, USD_DECIMALS, 4, true)} USD
            </>
          );
        }

        // Decreased ETH Long, 100 USD, ETH price 1500 USD
        return t`Decreased ${indexToken.symbol} ${longOrShortText},
        -${formatAmount(typedParams.sizeDelta, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} Price: ${formatAmount(typedParams.price, USD_DECIMALS, 4, true)} USD
      `;
      }

      if (trade.name === "LiquidatePosition") {
        const typedParams = params as LiquidatePositionEvent;
        const longOrShortText = typedParams?.isLong ? t`Long` : t`Short`;

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        const liquidationData = getLiquidationData(liquidationsDataMap, typedParams.key, trade.blockTime);
        if (liquidationData !== undefined) {
          return (
            <Trans>
              {renderLiquidationTooltip(liquidationData, t`Liquidated`)}&nbsp; {indexToken.symbol} {longOrShortText}, -
              {formatAmount(typedParams.size, USD_DECIMALS, 2, true)} USD,&nbsp;
              {indexToken.symbol} Price: ${formatAmount(typedParams.markPrice, USD_DECIMALS, 4, true)} USD
            </Trans>
          );
        }
        return t`
        Liquidated ${indexToken.symbol} ${longOrShortText},
        -${formatAmount(typedParams.size, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} Price: ${formatAmount(typedParams.markPrice, USD_DECIMALS, 4, true)} USD
      `;
      }

      if (["ExecuteIncreaseOrder", "ExecuteDecreaseOrder"].includes(trade.name)) {
        const [typedParams, increase] =
          trade.name === "ExecuteIncreaseOrder"
            ? [params as ExecuteIncreaseOrderEvent, true]
            : [params as ExecuteDecreaseOrderEvent, false];

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken, true, nativeTokenAddress);
        if (!indexToken) {
          return defaultMsg;
        }
        const longShortDisplay = typedParams.isLong ? t`Long` : t`Short`;
        const orderTypeText = increase ? t`Increase` : t`Decrease`;
        const executionPriceDisplay = formatAmount(typedParams.executionPrice, USD_DECIMALS, 4, true);
        const sizeDeltaDisplay = `${increase ? "+" : "-"}${formatAmount(typedParams.sizeDelta, USD_DECIMALS, 2, true)}`;
        return t`Execute Order: ${orderTypeText} ${indexToken.symbol} ${longShortDisplay} ${sizeDeltaDisplay} USD, Price: ${executionPriceDisplay} USD`;
      }

      if (
        [
          "CreateIncreaseOrder",
          "CancelIncreaseOrder",
          "UpdateIncreaseOrder",
          "CreateDecreaseOrder",
          "CancelDecreaseOrder",
          "UpdateDecreaseOrder",
        ].includes(trade.name)
      ) {
        let typedParams!:
          | CreateIncreaseOrderEvent
          | CancelIncreaseOrderEvent
          | UpdateIncreaseOrderEvent
          | CreateDecreaseOrderEvent
          | CancelDecreaseOrderEvent
          | UpdateDecreaseOrderEvent;

        switch (trade.name) {
          case "CreateIncreaseOrder":
            typedParams = params as CreateIncreaseOrderEvent;
            break;
          case "CancelIncreaseOrder":
            typedParams = params as CancelIncreaseOrderEvent;
            break;
          case "UpdateIncreaseOrder":
            typedParams = params as UpdateIncreaseOrderEvent;
            break;
          case "CreateDecreaseOrder":
            typedParams = params as CreateDecreaseOrderEvent;
            break;
          case "CancelDecreaseOrder":
            typedParams = params as CancelDecreaseOrderEvent;
            break;
          case "UpdateDecreaseOrder":
            typedParams = params as UpdateDecreaseOrderEvent;
            break;
        }

        const indexToken = getTokenInfo(infoTokens, typedParams.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const increase = trade.name.includes("Increase");
        const priceDisplay = `${typedParams.triggerAboveThreshold ? ">" : "<"} ${formatAmount(
          typedParams.triggerPrice,
          USD_DECIMALS,
          4,
          true
        )}`;
        return t`
        ${getOrderActionTitle(trade.name)}:
        ${getPositionDisplay(increase, indexToken, typedParams.isLong, typedParams.sizeDelta)},
        Price: ${priceDisplay}
      `;
      }

      if (trade.name === "ExecuteSwapOrder") {
        const typedParams = params as ExecuteSwapOrderEvent;

        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(
          infoTokens,
          typedParams.path[0] === nativeTokenAddress ? AddressZero : typedParams.path[0]
        );
        const toToken = getTokenInfo(
          infoTokens,
          typedParams.shouldUnwrap ? AddressZero : typedParams.path[typedParams.path.length - 1]
        );
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const fromAmountDisplay = formatAmount(
          typedParams.amountIn,
          fromToken.decimals,
          fromToken.isStable ? 2 : 4,
          true
        );
        const toAmountDisplay = formatAmount(typedParams.amountOut, toToken.decimals, toToken.isStable ? 2 : 4, true);
        return t`
        Execute Order: Swap ${fromAmountDisplay} ${fromToken.symbol} for ${toAmountDisplay} ${toToken.symbol}
      `;
      }

      if (["CreateSwapOrder", "UpdateSwapOrder", "CancelSwapOrder"].includes(trade.name)) {
        let typedParams!: CreateSwapOrderEvent | UpdateSwapOrderEvent | CancelSwapOrderEvent;

        switch (trade.name) {
          case "CreateSwapOrder":
            typedParams = params as CreateSwapOrderEvent;
            break;
          case "UpdateSwapOrder":
            typedParams = params as UpdateSwapOrderEvent;
            break;
          case "CancelSwapOrder":
            typedParams = params as CancelSwapOrderEvent;
            break;
        }

        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(
          infoTokens,
          typedParams.path[0] === nativeTokenAddress ? AddressZero : typedParams.path[0]
        );
        const toToken = getTokenInfo(
          infoTokens,
          typedParams.shouldUnwrap ? AddressZero : typedParams.path[typedParams.path.length - 1]
        );
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const amountInDisplay = fromToken
          ? formatAmount(typedParams.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
          : "";
        const minOutDisplay = toToken
          ? formatAmount(typedParams.minOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
          : "";

        return t`${getOrderActionTitle(trade.name)}: Swap ${amountInDisplay}
        ${fromToken?.symbol || ""} for ${minOutDisplay} ${toToken?.symbol || ""},
        Price: ${getExchangeRateDisplay(typedParams.triggerRatio, fromToken, toToken)}`;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getTokenInfo, infoTokens, nativeTokenAddress, chainId, liquidationsDataMap]
  );

  const tradesWithMessages = useMemo(() => {
    if (!trades) {
      return [];
    }

    return trades
      .map((trade) => ({
        msg: getMsg(trade),
        ...trade,
      }))
      .filter((trade) => trade.msg);
  }, [trades, getMsg]);

  return (
    <div className="TradeHistory">
      {tradesWithMessages.length === 0 && (
        <div className="Exchange-empty-positions-list-note-row-trades">
          <div className="TradeHistory-row">
            <Trans>No trades yet</Trans>
          </div>
        </div>
      )}
      {tradesWithMessages.length > 0 &&
        tradesWithMessages.map((trade, index) => {
          const txUrl = getExplorerUrl(chainId) + "tx/" + trade.transactionHash;

          let msg = getMsg(trade);

          if (!msg) {
            return null;
          }

          return (
            <div className="TradeHistory-row App-box-border" key={index}>
              <div>
                <div className="muted TradeHistory-time">
                  {formatDateTime(trade.blockTime)}
                  {(!account || account.length === 0) && (
                    <span>
                      {" "}
                      (<Link to={`/actions/${trade.params.account}`}>{trade.params.account}</Link>)
                    </span>
                  )}
                </div>
                <ExternalLink className="plain TradeHistory-item-link" href={txUrl}>
                  {msg}
                </ExternalLink>
              </div>
            </div>
          );
        })}
      {shouldShowPaginationButtons && (
        <div>
          {pageIndex > 0 && (
            <button className="App-button-option App-card-option" onClick={loadPrevPage}>
              <Trans>Prev</Trans>
            </button>
          )}
          {trades && trades.length >= TRADES_PAGE_SIZE && (
            <button className="App-button-option App-card-option" onClick={loadNextPage}>
              <Trans>Next</Trans>
            </button>
          )}
        </div>
      )}
    </div>
  );
}
