import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import { USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import React from "react";

interface HoldingCardProps {
  name: "VMX/ETH" | "esVMX" | "VLP";
  image: string;
  priceE30: BigNumber | undefined;
  balance: BigNumber | undefined;
  balanceUsdE30: BigNumber | undefined;
  staked: BigNumber | undefined;
  stakedUsdE30: BigNumber | undefined;
  // setTokenToStake: React.Dispatch<React.SetStateAction<"lpToken" | "esGmx" | undefined>>;
  setTokenToStake: (token: "lpToken" | "esGmx") => void;
  setTokenToUnstake: React.Dispatch<React.SetStateAction<"lpToken" | "esGmx" | undefined>>;
  connectWallet: any;
}

export default function HoldingCard({
  name,
  image,
  priceE30,
  balance,
  balanceUsdE30,
  staked,
  stakedUsdE30,
  setTokenToStake,
  setTokenToUnstake,
  connectWallet,
}: HoldingCardProps) {
  const { active } = useWeb3React();

  const tokenToManage = name === "VMX/ETH" ? "lpToken" : name === "esVMX" ? "esGmx" : undefined;

  return (
    <div className="StakeV2-card StakeV2-card-Holdings">
      <div className="StakeV2-Holdings-card-head-row">
        <div className="StakeV2-Holdings-card-title-wrapper">
          <img className="StakeV2-Holdings-card-title-image" src={image} alt={name} />
          <div className="StakeV2-Holdings-card-title">{name}</div>
        </div>
        <div className="StakeV2-Holdings-card-title-wrapper">
          <div className="StakeV2-margin-right-8px StakeV2-Holdings-card-price-value">
            {priceE30 !== undefined ? `$${formatAmount(priceE30, USD_DECIMALS, 3, true)}` : "0"}
          </div>
        </div>
      </div>

      <div className="StakeV2-Holdings-card-row">
        <div>
          <div className="StakeV2-Grey-16-Text">In wallet</div>
        </div>
        <div className="StakeV2-Holdings-wallet-esGMX-wrapper">
          <div className="StakeV2-Holdings-wallet-esGMX">{formatAmount(balance, 18, 2, true)}</div>
          <div className="StakeV2-Holdings-wallet-esGMX-usd">${formatAmount(balanceUsdE30, USD_DECIMALS, 2, true)}</div>
        </div>
      </div>
      <div className="StakeV2-Holdings-card-row">
        <div>
          <div className="StakeV2-Grey-16-Text">Staked</div>
        </div>

        <div className="StakeV2-Holdings-wallet-esGMX-wrapper">
          <div className="StakeV2-Holdings-wallet-esGMX">{formatAmount(staked, 18, 2, true)}</div>
          <div className="StakeV2-Holdings-wallet-esGMX-usd">${formatAmount(stakedUsdE30, USD_DECIMALS, 2, true)}</div>
        </div>
      </div>
      <div className="StakeV2-card-ManageAssets-hooter">
        {active && tokenToManage !== undefined && (
          <>
            <button className="StakeV2-button-outline App-card-option" disabled={true} onClick={() => setTokenToStake(tokenToManage)}>
              <Trans>Staking disabled</Trans>
            </button>

            <button className="StakeV2-button-outline App-card-option" onClick={() => setTokenToUnstake(tokenToManage)}>
              <Trans>Unstake</Trans>
            </button>
          </>
        )}
        {!active && (
          <button className="StakeV2-button-outline App-card-option" onClick={() => connectWallet()}>
            <Trans> Connect Wallet</Trans>
          </button>
        )}
      </div>
    </div>
  );
}
