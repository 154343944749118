import { ARBITRUM, AVALANCHE, BASE, BASE_TESTNET, FANTOM, FANTOM_TESTNET } from "./chains";

const BACKEND_URLS = {
  default: "https://gmx-server-mainnet.uw.r.appspot.com",

  [ARBITRUM]: "https://gmx-server-mainnet.uw.r.appspot.com",
  [AVALANCHE]: "https://gmx-avax-server.uc.r.appspot.com",
  [FANTOM_TESTNET]: "https://api.voodoo.trade/testnet",
  [FANTOM]: "https://api.voodoo.trade",
  [BASE]: "https://api.voodoo.trade",
  [BASE_TESTNET]: "https://api.voodoo.trade/testnet",
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
