import React from "react";

interface ArrowDownIconProps {
  color?: string;
}

export const ArrowDownIcon = (props: ArrowDownIconProps) => {
  const { color = "#fefdff" } = props;
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.83337 1L6.00004 5.16667L10.1667 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
