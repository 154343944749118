import { MAINNET_ENABLED_KEY } from "config/useMainnetEnabled";
import { confetti } from "party-js";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { switchNetwork } from "lib/wallets";
import { BASE } from "config/chains";
import { useWeb3React } from "@web3-react/core";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";

import starsImg from "img/Stars.svg";
import potImg from "img/Pot.svg";
import skeletonImg from "img/Skeleton.svg";
import Footer from "components/Footer/Footer";

import "./EnableMainnet.css";
import { Trans } from "@lingui/macro";

export default function EnableMainnet() {
  const { active } = useWeb3React();
  const history = useHistory();
  const [mainnetEnabled, setMainnetEnabled] = useLocalStorage(MAINNET_ENABLED_KEY, false);

  async function enableMainnet(event: MouseEvent) {
    confetti(event);

    setTimeout(() => {
      setMainnetEnabled(true);
      switchNetwork(BASE, active);
    }, 1000);
  }

  useEffect(() => {
    if (mainnetEnabled) {
      history.push("/dashboard");
    }
  }, [mainnetEnabled, history]);

  return (
    <SEO title={getPageTitle("Mainnet Invite")}>
      <div className="background__wrapper">
        <div className="backgroundStars">
          <img src={starsImg} alt="stars" className="backgroundStars__image" />
        </div>
        <div className="backgroundPot">
          <img src={potImg} alt="pot" className="backgroundPot__image" />
        </div>
        <div className="backgroundSkeleton">
          <img src={skeletonImg} alt="pot" className="backgroundSkeleton__image" />
        </div>
      </div>
      <div className="page-layout z-index-100">
        <div className="Stats-container">
          <div className="Enable-mainnet-container">
            <p className="Invite-text">
              <Trans>You are invited to trade on the Voodoo private mainnet</Trans>
            </p>
            <button
              onClick={(event) => {
                enableMainnet(event.nativeEvent);
              }}
              className="Enable-mainnet-button Token-card-button-solid"
            >
              <Trans>Start trading</Trans>
            </button>
          </div>
        </div>

        <Footer />
      </div>
    </SEO>
  );
}
