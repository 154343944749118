import { Trans, t } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Modal from "components/Modal/Modal";
import { approveTokens } from "domain/tokens";
import { BigNumber, ethers } from "ethers";
import { contractFetcher, callContract } from "lib/contracts";
import { formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { useState } from "react";
import useSWR from "swr";

import LpRouter from "abis/LpRouter.json";
import RewardRouter from "abis/RewardRouterV3.json";
import Token from "abis/Token.json";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import Checkbox from "components/Checkbox/Checkbox";
import useEthBalance from "domain/useEthBalance";
import { usePoolReserves, useTokenSupply } from "domain/gmxHooks";
import { getZapEstimate } from "domain/zapper/ZapEstimator";
import { useNativeTokenPriceE30 } from "domain/prices";

const { AddressZero } = ethers.constants;

const SLIPPAGE_BPS = 30;

interface StakeLpTokenModalProps {
  stakeLpToken: boolean;
  setStakeLpToken: React.Dispatch<React.SetStateAction<boolean>>;
  lpTokenBalance: BigNumber | undefined;
  setPendingTxns: any;
}

export default function StakeLpTokenModal({
  stakeLpToken,
  setStakeLpToken,
  lpTokenBalance,
  setPendingTxns,
}: StakeLpTokenModalProps) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const ethBalance = useEthBalance();
  const ethPriceE30 = useNativeTokenPriceE30() ?? BigNumber.from(0);

  const lpTokenSupply = useTokenSupply("lpToken");

  const { gmxReserve, nativeTokenReserve } = usePoolReserves();

  const [zapping, setZapping] = useState(false);
  const [value, setValue] = useState<string>("");
  const ethIn = ethers.utils.parseEther(value === "" ? "0" : value);

  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const lpRouterAddress = getContract(chainId, "lpRouter");
  const lpTokenAddress = getContract(chainId, "lpToken");
  const stakedLpTokenTracker = getContract(chainId, "StakedLpTokenTracker");

  const gmxAddress = getContract(chainId, "GMX");
  const wethAddress = getContract(chainId, "NATIVE_TOKEN");
  const correctOrder = gmxAddress.toLowerCase() < wethAddress.toLowerCase();

  const [reserve0, reserve1] = correctOrder ? [gmxReserve, nativeTokenReserve] : [nativeTokenReserve, gmxReserve];

  const isVisible = stakeLpToken;
  function setIsVisible(visible: boolean) {
    setStakeLpToken(visible);
  }

  const title = "Stake VMX-ETH LP";
  const maxAmount = zapping ? ethBalance : lpTokenBalance;

  const stakingTokenSymbol = zapping ? "ETH" : "VMX-ETH LP";
  const stakingTokenAddress = lpTokenAddress;
  const farmAddress = stakedLpTokenTracker;
  const stakeMethodName = "stakeLpToken";

  let zapQuoteE18 = 0n;
  let zapQuoteUsdE18 = 0n;
  let zapError: string | undefined;

  if (zapping) {
    try {
      const { liquidityMin, reserveEthNew, reserveGmxNew, newPairSupply } = getZapEstimate({
        correctOrder,
        pairSupply: lpTokenSupply?.toBigInt(),
        reserve0: reserve0?.toBigInt(),
        reserve1: reserve1?.toBigInt(),
        ethIn: ethIn.toBigInt(),
        slippageBps: BigInt(SLIPPAGE_BPS),
      });
      zapQuoteE18 = liquidityMin;

      const gmxPriceInEthE18 = (reserveEthNew * 10n ** 18n) / reserveGmxNew;
      const gmxPriceE30 = (gmxPriceInEthE18 * ethPriceE30.toBigInt()) / 10n ** 18n;

      // lp token price formula from https://slowmist.medium.com/slowmist-sharing-of-a-safe-way-to-obtain-lp-prices-e58c78a4926b
      const lpTokenpriceE30 = (reserveGmxNew * gmxPriceE30 + reserveEthNew * ethPriceE30.toBigInt()) / newPairSupply;

      zapQuoteUsdE18 = (liquidityMin * lpTokenpriceE30) / 10n ** 30n;
    } catch (error) {
      zapError = (error as Error).message;
      console.log('set zapError', zapError)
    }
  }

  const zapQuoteFormatted = (Number(zapQuoteE18) / 10 ** 18).toLocaleString(undefined, {
    maximumFractionDigits: 3,
  });
  const zapQuoteUsdFormatted = (Number(zapQuoteUsdE18) / 10 ** 18).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });

  const { data: tokenAllowance } = useSWR<BigNumber>(
    active && stakingTokenAddress !== undefined
      ? [active, chainId, stakingTokenAddress, "allowance", account, farmAddress]
      : null,
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = !zapping && farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }

    if (zapping && zapError) {
      return zapError;
    }
  };

  const onClickPrimary = async () => {
    if (stakingTokenAddress === undefined) {
      throw Error("stakingTokenAddress is undefined");
    }
    if (farmAddress === undefined) {
      throw Error("Farm is undefined");
    }

    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    if (stakeMethodName === undefined) {
      throw Error("stakeMethodName is undefined");
    }

    if (account == null) {
      throw Error("No connected account");
    }

    setIsStaking(true);

    let txPromise: Promise<any>;
    if (zapping) {
      // TODO call lpRouter.zapMintAndStakeLpTokenEth() based on quote
      const contract = new ethers.Contract(lpRouterAddress, LpRouter.abi, library.getSigner());
      txPromise = callContract(chainId, contract, "zapMintAndStakeLpTokenEth", [zapQuoteE18, account], {
        value: ethIn,
        sentMsg: t`Zap submitted!`,
        failMsg: t`Zap failed.`,
        setPendingTxns,
      });
    } else {
      const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

      txPromise = callContract(chainId, contract, stakeMethodName, [amount], {
        sentMsg: t`Stake submitted!`,
        failMsg: t`Stake failed.`,
        setPendingTxns,
      });
    }

    txPromise
      .then(async (res) => {
        setIsVisible(false);
      })
      .catch((error) => {
        console.error("TX error", error);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error || stakingTokenAddress === undefined || isApproving || isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return t`Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return t`Staking...`;
    }
    if (zapping) {
      return t`Zap and Stake`;
    }
    return t`Stake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        {zapping ? (
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <Trans>Zap ETH</Trans>
                </div>
              </div>
              <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 4))}>
                <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
            </div>
          </div>
        ) : (
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <Trans>Stake</Trans>
                </div>
              </div>
              <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 4))}>
                {/* <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}> */}
                <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
            </div>
            <ExternalLink className="StakeModal-addLiquidity-link" href="https://pancakeswap.finance/v2/add/ETH/0x44d05c7Bf593b4cC4322088fFCa35613079E8D37?chain=base">
              Get LP tokens on Pancakeswap
            </ExternalLink>
          </div>
        )}

        <div>
          {zapping ? (
            <div>
              <p>Min received: {zapQuoteFormatted} VMX-ETH LP</p>
              <p>Value: ${zapQuoteUsdFormatted}</p>
            </div>
          ) : null}

          {/* <Checkbox isChecked={zapping} setIsChecked={setZapping}>
            <p style={{ marginLeft: "8px" }}>
              <Trans>Zap with ETH</Trans>
            </p>
          </Checkbox> */}
        </div>
        <div className="Exchange-swap-button-container">
          <button
            className="StakeV2-button-solid Exchange-swap-button"
            onClick={onClickPrimary}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
