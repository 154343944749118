import { gql, useQuery } from "@apollo/client";
import { getGmxStatsClient } from "lib/subgraph";
import { BigNumber } from "ethers";
import { useChainId } from "lib/chains";

const DivFactor = BigNumber.from(10).pow(30);
const Zero = BigNumber.from(0);

const GET_STATS = gql`
  {
    totalVolume: volumeStats(where: { period: total }) {
      margin
      liquidation
      swap
      mint
      burn
    }

    hourlyVolume: volumeStats(where: { period: hourly }, orderBy: timestamp, orderDirection: desc, first: 24) {
      margin
      liquidation
      swap
      mint
      burn
    }

    feeStats(where: { period: total }) {
      marginAndLiquidation
      swap
      mint
      burn
    }

    tradingStats(where: { period: daily }, first: 1, orderBy: timestamp, orderDirection: desc) {
      longOpenInterest
      shortOpenInterest
    }

    withdrawnFeeStats(orderBy: timestamp, orderDirection: desc, first: 1) {
      timestamp
    }
  }
`;

export default function useStats() {
  const { chainId } = useChainId();
  const client = getGmxStatsClient(chainId)

  const { data } = useQuery<Data>(GET_STATS, {
    client,
  });

  return formatStats(data);
}

function formatStats(data?: Data) {
  if (data == undefined) {
    return {
      totalVolume: Zero,
      dailyVolume: Zero,
      fees: Zero,
      longPositions: Zero,
      shortPositions: Zero,
    };
  }
  const { totalVolume: cumulativeVolume, hourlyVolume, feeStats, tradingStats, withdrawnFeeStats } = data;

  const totalVolume = sumOfVolume(cumulativeVolume[0]);
  const dailyVolume = hourlyVolume.reduce((prev, current) => {
    return prev.add(sumOfVolume(current));
  }, BigNumber.from(0));

  const feeStatsTotal = feeStats[0];
  const fees = sumOfFees(feeStatsTotal);

  const longOpenInterest = tradingStats[0]?.longOpenInterest;
  const shortOpenInterest = tradingStats[0]?.shortOpenInterest;

  const longPositions =
    longOpenInterest !== undefined ? BigNumber.from(longOpenInterest).div(DivFactor) : BigNumber.from(0);

  const shortPositions =
    shortOpenInterest !== undefined ? BigNumber.from(shortOpenInterest).div(DivFactor) : BigNumber.from(0);

  const lastWithdrawTimestamp = withdrawnFeeStats?.[0]?.timestamp;
  let lastWithdrawDate: string | undefined = undefined;
  if (lastWithdrawTimestamp !== undefined) {
    const date = new Date(lastWithdrawTimestamp * 1000); // Convert to milliseconds
    lastWithdrawDate = date.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
  }
  return {
    totalVolume,
    dailyVolume,
    fees,
    longPositions,
    shortPositions,
    lastWithdrawDate,
  };
}

function sumOfFees(fee?: FeeStat): BigNumber {
  if (fee == undefined) {
    return BigNumber.from(0);
  }
  const { burn, marginAndLiquidation, mint, swap } = fee;

  return BigNumber.from(burn).add(marginAndLiquidation).add(mint).add(swap).div(DivFactor);
}

function sumOfVolume(volume?: Volume): BigNumber {
  if (volume == undefined) {
    return BigNumber.from(0);
  }
  const { margin, liquidation, swap, mint, burn } = volume;

  return BigNumber.from(margin).add(liquidation).add(swap).add(mint).add(burn);
}

interface Data {
  totalVolume: [] | [Volume];
  hourlyVolume: Volume[];
  feeStats: [] | [FeeStat];
  tradingStats: [] | [TradingStat];
  withdrawnFeeStats: [] | [{ timestamp: number }];
}

interface Volume {
  margin: string;
  liquidation: string;
  swap: string;
  mint: string;
  burn: string;
}

interface FeeStat {
  marginAndLiquidation: string;
  swap: string;
  mint: string;
  burn: string;
}

interface TradingStat {
  longOpenInterest: string;
  shortOpenInterest: string;
}
