import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import { useChainId } from "lib/chains";
import { getProvider } from "lib/rpc";
import { useEffect, useState } from "react";

export default function useEthBalance() {
  const { library, active, account } = useWeb3React();
  const { chainId } = useChainId();

  const [balance, setBalance] = useState<BigNumber | undefined>();

  useEffect(() => {
    async function getBalance() {
      if (account != null) {
        const provider = getProvider(undefined, chainId);

        try {
          const fetchedBalance = await provider.getBalance(account);
          setBalance(fetchedBalance);
        } catch (error) {
          console.error("ETH balance fetch failed", error);
        }
      } else {
        setBalance(undefined);
      }
    }
    getBalance();
  }, [library, active, chainId, account]);

  return balance;
}
