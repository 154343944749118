import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { BigNumber, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

import { getContract } from "config/contracts";

import Modal from "components/Modal/Modal";
import Footer from "components/Footer/Footer";

import Token from "abis/Token.json";
import Vester from "abis/Vester.json";
import RewardTracker from "abis/RewardTracker.json";
import RewardRouter from "abis/RewardRouterV3.json";

import { FaCheck, FaTimes } from "react-icons/fa";

import { Trans, t } from "@lingui/macro";

import "./BeginAccountTransfer.css";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens } from "domain/tokens";
import { useChainId } from "lib/chains";

function ValidationRow({ isValid, children }) {
  return (
    <div className="ValidationRow">
      <div className="ValidationRow-icon-container">
        {isValid && <FaCheck className="ValidationRow-icon" />}
        {!isValid && <FaTimes className="ValidationRow-icon" />}
      </div>
      <div>{children}</div>
    </div>
  );
}

export default function BeginAccountTransfer(props) {
  const { setPendingTxns } = props;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const [receiver, setReceiver] = useState("");
  const [isTransferring, setIsTransferring] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isTransferSubmittedModalVisible, setIsTransferSubmittedModalVisible] = useState(false);
  let parsedReceiver = ethers.constants.AddressZero;
  if (ethers.utils.isAddress(receiver)) {
    parsedReceiver = receiver;
  }

  const gmxAddress = getContract(chainId, "GMX");

  const lpTokenVesterAddress = getContract(chainId, "LpTokenVester");
  const esGmxVesterAddress = getContract(chainId, "EsGmxVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const { data: lpTokenVesterBalance } = useSWR<BigNumber>(
    active ? [active, chainId, lpTokenVesterAddress, "balanceOf", account] : null,
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: esGmxVesterBalance } = useSWR<BigNumber>(
    active ? [active, chainId, esGmxVesterAddress, "balanceOf", account] : null,
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: glpVesterBalance } = useSWR<BigNumber>(
    active ? [active, chainId, glpVesterAddress, "balanceOf", account] : null,
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  // Whether the receiver has staked lpToken, esGmx or GLP
  const stakedLpTokenTrackerAddress = getContract(chainId, "StakedLpTokenTracker");
  const { data: cumulativeLpTokenRewards } = useSWR<BigNumber>(
    [active, chainId, stakedLpTokenTrackerAddress, "cumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const stakedEsGmxTrackerAddress = getContract(chainId, "StakedEsGmxTracker");
  const { data: cumulativeEsGmxRewards } = useSWR<BigNumber>(
    [active, chainId, stakedEsGmxTrackerAddress, "cumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const { data: cumulativeGlpRewards } = useSWR<BigNumber>(
    [active, chainId, stakedGlpTrackerAddress, "cumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const { data: transferredCumulativeLpTokenRewards } = useSWR<BigNumber>(
    [active, chainId, lpTokenVesterAddress, "transferredCumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, Vester),
    }
  );

  const { data: transferredCumulativeEsGmxRewards } = useSWR<BigNumber>(
    [active, chainId, esGmxVesterAddress, "transferredCumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, Vester),
    }
  );

  const { data: transferredCumulativeGlpRewards } = useSWR<BigNumber>(
    [active, chainId, glpVesterAddress, "transferredCumulativeRewards", parsedReceiver],
    {
      fetcher: contractFetcher(library, Vester),
    }
  );
  // TODO esGmx transferredCumulativeEsGmxRewards

  const { data: pendingReceiver } = useSWR<string>(
    active ? [active, chainId, rewardRouterAddress, "pendingReceivers", account] : null,
    {
      fetcher: contractFetcher(library, RewardRouter),
    }
  );

  const { data: lpTokenAllowance } = useSWR<BigNumber>(
    active ? [active, chainId, gmxAddress, "allowance", account, stakedLpTokenTrackerAddress] : null,
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: lpTokenStaked } = useSWR<BigNumber>(
    active ? [active, chainId, stakedLpTokenTrackerAddress, "depositBalances", account, gmxAddress] : null,
    {
      fetcher: contractFetcher(library, RewardTracker),
    }
  );

  const needApproval = lpTokenAllowance && lpTokenStaked && lpTokenStaked.gt(lpTokenAllowance);

  const hasVestedLpToken = lpTokenVesterBalance && lpTokenVesterBalance.gt(0);
  const hasVestedEsGmx = esGmxVesterBalance && esGmxVesterBalance.gt(0);
  const hasVestedGlp = glpVesterBalance && glpVesterBalance.gt(0);

  const hasStakedLpToken =
    (cumulativeLpTokenRewards && cumulativeLpTokenRewards.gt(0)) ||
    (transferredCumulativeLpTokenRewards && transferredCumulativeLpTokenRewards.gt(0));

  const hasStakedEsGmx =
    (cumulativeEsGmxRewards && cumulativeEsGmxRewards.gt(0)) ||
    (transferredCumulativeEsGmxRewards && transferredCumulativeEsGmxRewards.gt(0));

  const hasStakedGlp =
    (cumulativeGlpRewards && cumulativeGlpRewards.gt(0)) ||
    (transferredCumulativeGlpRewards && transferredCumulativeGlpRewards.gt(0));
  const hasPendingReceiver = pendingReceiver && pendingReceiver !== ethers.constants.AddressZero;

  const getError = () => {
    if (!account) {
      return t`Wallet is not connected`;
    }
    if (hasVestedLpToken) {
      return `Vested LP Token not withdrawn`;
    }
    if (hasVestedEsGmx) {
      return `Vested esGmx not withdrawn`;
    }
    if (hasVestedGlp) {
      return `Vested VLP not withdrawn`;
    }
    if (!receiver || receiver.length === 0) {
      return t`Enter Receiver Address`;
    }
    if (!ethers.utils.isAddress(receiver)) {
      return t`Invalid Receiver Address`;
    }
    if (hasStakedLpToken || hasStakedEsGmx || hasStakedGlp) {
      return t`Invalid Receiver`;
    }
    if ((parsedReceiver || "").toString().toLowerCase() === (account || "").toString().toLowerCase()) {
      return t`Self-transfer not supported`;
    }

    if (
      (parsedReceiver || "").length > 0 &&
      (parsedReceiver || "").toString().toLowerCase() === (pendingReceiver || "").toString().toLowerCase()
    ) {
      return t`Transfer already initiated`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isTransferring) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (needApproval) {
      return `Approve VMX`;
    }
    if (isApproving) {
      return t`Approving...`;
    }
    if (isTransferring) {
      return t`Transferring`;
    }

    return t`Begin Transfer`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: gmxAddress,
        spender: stakedLpTokenTrackerAddress,
        chainId,
      });
      return;
    }

    setIsTransferring(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, "signalTransfer", [parsedReceiver], {
      sentMsg: t`Transfer submitted!`,
      failMsg: t`Transfer failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsTransferSubmittedModalVisible(true);
      })
      .finally(() => {
        setIsTransferring(false);
      });
  };

  const completeTransferLink = `/complete_account_transfer/${account}/${parsedReceiver}`;
  const pendingTransferLink = `/complete_account_transfer/${account}/${pendingReceiver}`;

  return (
    <div>
      <div className="BeginAccountTransfer Page BeginAccountTransfer-page-layout">
        <Modal
          isVisible={isTransferSubmittedModalVisible}
          setIsVisible={setIsTransferSubmittedModalVisible}
          label={t`Transfer Submitted`}
        >
          <Trans>Your transfer has been initiated.</Trans>
          <br />
          <br />
          <Link className="App-cta" to={completeTransferLink}>
            <Trans>Continue</Trans>
          </Link>
        </Modal>
        <div className="Page-title-section">
          <div className="Page-title">
            <Trans>Transfer Account</Trans>
          </div>
          <div className="Page-description">
            Please only use this for full account transfers.
            <br />
            This will transfer all your staked VMX/ETH LP, esVMX, VLP and Multiplier Points to your new account.
            <br />
            Transfers are only supported if the receiving account has not staked VMX or VLP tokens before.
            <br />
            Transfers are one-way, you will not be able to transfer staked tokens back to the sending account.
          </div>
          {hasPendingReceiver && (
            <div className="Page-description">
              <Trans>
                You have a <Link to={pendingTransferLink}>pending transfer</Link> to {pendingReceiver}.
              </Trans>
            </div>
          )}
        </div>
        <div className="Page-content">
          <div className="input-form">
            <div className="input-row">
              <label className="input-label">
                <Trans>Receiver Address</Trans>
              </label>
              <div>
                <input
                  type="text"
                  value={receiver}
                  onChange={(e) => setReceiver(e.target.value)}
                  className="text-input"
                />
              </div>
            </div>
            <div className="BeginAccountTransfer-validations">
              <ValidationRow isValid={!hasVestedLpToken}>
                <>Sender has withdrawn all tokens from VMX/ETH LP Vesting Vault</>
              </ValidationRow>
              <ValidationRow isValid={!hasVestedEsGmx}>
                <>Sender has withdrawn all tokens from esVMX Vesting Vault</>
              </ValidationRow>
              <ValidationRow isValid={!hasVestedGlp}>
                <>Sender has withdrawn all tokens from VLP Vesting Vault</>
              </ValidationRow>
              <ValidationRow isValid={!hasStakedLpToken}>
                <>Receiver has not staked VMX/ETH LP tokens before</>
              </ValidationRow>
              <ValidationRow isValid={!hasStakedEsGmx}>
                <>Receiver has not staked esVMX tokens before</>
              </ValidationRow>
              <ValidationRow isValid={!hasStakedGlp}>
                <>Receiver has not staked VLP tokens before</>
              </ValidationRow>
            </div>
            <div className="input-row">
              <button
                className="App-cta Exchange-swap-button"
                disabled={!isPrimaryEnabled()}
                onClick={() => onClickPrimary()}
              >
                {getPrimaryText()}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
