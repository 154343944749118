import { JsonRpcProvider } from "@ethersproject/providers";
import { BASE, FANTOM } from "config/chains";
import { useChainId } from "lib/chains";
import { getProvider } from "lib/rpc";
import useSWR from "swr";

/**
 * Checks whether the last indexed block is within 25 blocks of the last block on the RPC
 *
 * @returns
 */
export default function useIndexerIsSynced() {
  const { chainId } = useChainId();
  const provider = getProvider(undefined, chainId);
  const lastBlockUrl =
    chainId === BASE ? "https://api.voodoo.trade/lastBlock" : "https://api.voodoo.trade/testnet/lastBlock";

  const { data: lastIndexedBlockResp } = useSWR(lastBlockUrl, (url) => {
    return fetch(url).then((resp) => resp.text());
  });

  const lastIndexedBlock = lastIndexedBlockResp !== undefined ? Number(lastIndexedBlockResp) : 0;

  const { data: lastBlock } = useSWR(`lastBlock:${chainId}`, () => {
    return (provider as JsonRpcProvider).getBlockNumber();
  });

  if (lastIndexedBlock === undefined || lastBlock === undefined) {
    return false;
  }

  // Max 25 block delay
  return lastBlock - lastIndexedBlock < 25;
}
