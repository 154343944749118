import { gql, useQuery } from "@apollo/client";
import { BigNumber } from "ethers";
import { useChainId } from "lib/chains";
import { fantomTestnetGraphClient, getGmxStatsClient } from "lib/subgraph";

interface LeaderboardStat {
  id: string;
  positions: LeaderboardPosition[];
}

interface LeaderboardPosition {
  id: string;
  indexToken: string
  isLong: boolean
  collateralToken: string
  fee: string
  instances: PositionInstance[]
}

type PositionState = 'open' | 'closed' | 'liquidated'

interface PositionInstance {
  id: string
  state: PositionState
  realisedPnl: string
  size: string
  collateral: string
  updateTime: number
}

export interface LeaderboardRow {
  wallet: string;
  tradingVolume: string;
  wins: number;
  losses: number;
  netProfit: number;
}

export interface LeaderboardRowWithRoI {
  top: number;
  wallet: string;
  tradingVolume: number;
  wins: number;
  losses: number;
  netProfit: number;
  RoI: number;
  totalCollateral: number
}

// Trading competition params
// export const START_TIME = 1685534400 // 12 PM 31st March UTC
// const MIN_TRADES = 5
// const MIN_SIZE = 20_000

interface UseLeaderboard {
  traderStats: LeaderboardRowWithRoI[],
  traderCount: number,
  totalProfit: number,
  totalVolume: number
}

export default function useLeaderboard(): UseLeaderboard {
  const { chainId } = useChainId()
  const LEADERBOARD_QUERY = gql`
  {
    leaderboardStats0: leaderboardStats(first: 1000) {
      id
      positions {
        id
        indexToken
        isLong
        collateralToken
        fee
        instances {
          id
          state
          realisedPnl
          size
          collateral
          updateTime
        }
      }
    }

    leaderboardStats1: leaderboardStats(first: 1000, skip: 1000) {
      id
      positions {
        id
        indexToken
        isLong
        collateralToken
        fee
        instances {
          id
          state
          realisedPnl
          size
          collateral
          updateTime
        }
      }
    }

    leaderboardStats2: leaderboardStats(first: 1000, skip: 2000) {
      id
      positions {
        id
        indexToken
        isLong
        collateralToken
        fee
        instances {
          id
          state
          realisedPnl
          size
          collateral
          updateTime
        }
      }
    }
  }
  `;

  const client = getGmxStatsClient(chainId)

  const { data } = useQuery<{
    leaderboardStats0?: LeaderboardStat[],
    leaderboardStats1?: LeaderboardStat[],
    leaderboardStats2?: LeaderboardStat[],
  }>(LEADERBOARD_QUERY, {
    client,
  });

  const leaderboardStatsConcated = data !== undefined ? [
    ...(data.leaderboardStats0 ?? []),
    ...(data.leaderboardStats1 ?? []),
    ...(data.leaderboardStats2 ?? []),
  ] : []

  let traderCount = 0
  let totalProfit = 0
  let totalVolume = 0

  const stats = leaderboardStatsConcated.map((trader) => {
      // There can only be 2 positions- long, and short. We find total wins and losses from both
      const { wins, losses, netProfit, totalSize, totalCollateral } = trader.positions.reduce(
        (acc, current) => {

          for (const instance of current.instances) {
            const realisedPnl = Number(instance.realisedPnl) / 10 ** 30

            if (instance.state === 'liquidated') {
              acc.losses += 1
            } else if (instance.state === 'closed') {
              if (realisedPnl > 0) {
                acc.wins += 1
              } else {
                acc.losses += 1
              }
            } else {
              continue
            }

            acc.netProfit += realisedPnl

            const factor = BigNumber.from(10).pow(28)
            acc.totalSize += BigNumber.from(instance.size).div(factor).toNumber() / 100
            acc.totalCollateral += BigNumber.from(instance.collateral).div(factor).toNumber() / 100
          }

          return acc;
        },
        {
          wins: 0,
          losses: 0,
          netProfit: 0,
          totalSize: 0,
          totalCollateral: 0
        }
      );

      const RoI = totalCollateral !== 0
        ? netProfit * 100 / totalCollateral
        : 0

      // Aggregate stats
      traderCount += 1
      totalProfit += netProfit
      totalVolume += totalSize

      return {
        wallet: trader.id,
        tradingVolume: totalSize,
        wins,
        losses,
        netProfit,
        RoI,
        totalCollateral
      };
    }) ?? [];


  const traderStats = stats
    .sort((statA, statB) => { // Sort by RoI
      return statB.RoI - statA.RoI;
    })
    .map((item, i) => ({ ...item, top: i + 1 }));


    return {
      traderStats,
      traderCount,
      totalProfit,
      totalVolume
    }
}
