/// Discord Oauth2 workaround for HashRouter
/// Redirects to /airdrop if callback string exists, else opens home page

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function DiscordOauth() {
  const history = useHistory();
  const location = useLocation();
  const callbackParams = new URLSearchParams(location.pathname);
  const accessToken = callbackParams.get("access_token");

  useEffect(() => {
    if (accessToken === null) {
      history.push("/dashboard");
    } else {
      history.push(`/airdrop?discord_access_token=${accessToken}`);
    }
  }, [accessToken]);

  return <>Search: {location.search}</>;
}
