import { useWeb3React } from "@web3-react/core";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import ConnectWalletButton from "../Common/ConnectWalletButton";
import React, { useCallback, useEffect, useState } from "react";
import { HeaderLink } from "./HeaderLink";
import connectWalletImg from "img/ic_wallet_24.svg";
import { ethers } from "ethers";

import "./Header.css";
import { isHomeSite, getAccountUrl } from "lib/legacy";
import cx from "classnames";
import { Trans } from "@lingui/macro";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import { BASE, BASE_TESTNET, FANTOM, FANTOM_TESTNET, getChainName, getExplorerUrl } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import { getIcon } from "config/icons";

import MultiFaucetAbi from "abis/MultiFaucet.json";
import { getContract } from "config/contracts";
import { callContract } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import ExternalLink from "components/ExternalLink/ExternalLink";
import useMainnetEnabled from "config/useMainnetEnabled";

type Props = {
  openSettings: () => void;
  small?: boolean;
  setWalletModalVisible: (visible: boolean) => void;
  disconnectAccountAndCloseSettings: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderUser({
  openSettings,
  small,
  setWalletModalVisible,
  disconnectAccountAndCloseSettings,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const { chainId } = useChainId();
  const { active, account, library } = useWeb3React();
  const showConnectionOptions = !isHomeSite();

  const [isLoading, setIsLoading] = useState(false);

  const mainnetEnabled = useMainnetEnabled();

  const NETWORK_OPTIONS = mainnetEnabled
    ? [
        {
          label: getChainName(BASE),
          value: BASE,
          icon: getIcon(BASE, "network"),
          color: "#264f79",
        },
        {
          label: getChainName(BASE_TESTNET),
          value: BASE_TESTNET,
          icon: getIcon(BASE_TESTNET, "network"),
          color: "#264f79",
        },
      ]
    : [
        {
          label: getChainName(BASE_TESTNET),
          value: BASE_TESTNET,
          icon: getIcon(BASE_TESTNET, "network"),
          color: "#264f79",
        },
      ];

  const faucetOnClick = () => {
    if (library && chainId) {
      setIsLoading(true);
      const MultiFaucetAddress = getContract(chainId, "MultiFaucet");
      const MultiFaucetContract = new ethers.Contract(MultiFaucetAddress, MultiFaucetAbi.abi, library.getSigner());

      callContract(chainId, MultiFaucetContract, "mintTokens", [], {
        sentMsg: "Mint Tokens",
        failMsg: "Tokens mint failed.",
      })
        .then(async (res) => {
          const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
          helperToast.success(
            <div>
              {"Tokens mint successful!"}{" "}
              <ExternalLink href={txUrl}>
                <Trans>View</Trans>
              </ExternalLink>
            </div>
          );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const selectorLabel = getChainName(chainId);

  if (!active || !account) {
    return (
      <div className="App-header-user">
        <div className={cx("App-header-trade-link header__trade_btn_large", { "homepage-header": isHomeSite() })}>
          <HeaderLink
            className="header__trade_btn"
            to="/trade"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
          >
            {isHomeSite() ? <Trans>Launch App</Trans> : <Trans>Trade</Trans>}
          </HeaderLink>
        </div>

        {/* TODO set faucet here */}
        {chainId === FANTOM_TESTNET && (
          <button className="header__faucet_btn" onClick={faucetOnClick} disabled={true}>
            Faucet
          </button>
        )}

        {showConnectionOptions ? (
          <>
            <ConnectWalletButton onClick={() => setWalletModalVisible(true)} imgSrc={connectWalletImg}>
              {small ? <Trans>Connect</Trans> : <Trans>Connect Wallet</Trans>}
            </ConnectWalletButton>
            <NetworkDropdown
              small={small}
              networkOptions={NETWORK_OPTIONS}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
            />
          </>
        ) : (
          // <LanguagePopupHome />
          <></>
        )}
      </div>
    );
  }

  const accountUrl = getAccountUrl(chainId, account);

  return (
    <div className="App-header-user">
      <div className="App-header-trade-link header__trade_btn_large">
        <HeaderLink
          className="header__trade_btn header__trade_btn_large"
          // className="default-btn"
          to="/trade"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Trade</Trans>
        </HeaderLink>
      </div>

      {[FANTOM_TESTNET, BASE_TESTNET].includes(chainId) && (
        <button className="header__faucet_btn" onClick={faucetOnClick} disabled={isLoading}>
          Faucet
        </button>
      )}

      {showConnectionOptions ? (
        <>
          <div className="App-header-user-address">
            <AddressDropdown
              account={account}
              accountUrl={accountUrl}
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            />
          </div>
          <NetworkDropdown
            small={small}
            networkOptions={NETWORK_OPTIONS}
            selectorLabel={selectorLabel}
            onNetworkSelect={onNetworkSelect}
            openSettings={openSettings}
          />
        </>
      ) : (
        // <LanguagePopupHome />
        <></>
      )}
    </div>
  );
}
