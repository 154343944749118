import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";

import Tooltip from "components/Tooltip/Tooltip";
import Footer from "components/Footer/Footer";

import { BASIS_POINTS_DIVISOR, USD_DECIMALS } from "lib/legacy";
import { getConstant } from "config/chains";

import "./Stake.css";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { BigZero, formatAmount } from "lib/numbers";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";

import SwapYellowRectangleImage from "img/swapYellowRectangle.svg";
import StakeV2WalletImage from "img/StakeV2Wallet.svg";
import StakeV2TrendingUpImage from "img/StakeV2TrendingUp.svg";

import VLPTokenImage from "img/VLPToken.png";
import esVMXTokenImage from "img/esVMXToken.png";
import VMXETHImage from "img/VMXETHImage.svg";
import { useLiquidityPoolInfo, useCirculatingEsGmxSupply, calculateAPRs } from "domain/gmxHooks";
import {
  getTotalRewards,
  useBalancesAndSupplies,
  useTrackerData,
  useTrackerStakesForUser,
  useUserVestingInfo,
} from "domain/readerHooks";
import { UnstakeModal } from "./Modals/UnstakeModal";
import VesterDepositModal from "./Modals/VesterDepositModal";
import VesterWithdrawModal from "./Modals/VesterWithdrawModal";
import { getGlpPriceE30, useGlpAumUsdE30 } from "domain/glpHooks";
import PageTitle from "./Components/PageTitle";
import getBalancesUsdE30, { getRewardsUsdE30, getValueUsd } from "./getUsdValue";
import TotalRewardsBox from "./Components/TotalRewardsBox";
import HoldingCard from "./Components/HoldingCard";
import LpTokenAprTooltip from "./Components/GMXAprTooltip";
import VesterCard from "./Components/VesterCard";

import starsImg from "img/Stars.svg";
import potImg from "img/Pot.svg";
import skeletonImg from "img/Skeleton.svg";
import StakeLpTokenModal from "./Modals/StakeLpTokenModal";
import StakeEsGmxModal from "./Modals/StakeEsGmxModal";

export default function Stake({ setPendingTxns, connectWallet }) {
  const { active } = useWeb3React();
  const { chainId } = useChainId();

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  // User data
  const { balanceData, supplyData } = useBalancesAndSupplies();
  const userStakes = useTrackerStakesForUser();

  const userVestingInfo = useUserVestingInfo();
  const rewardTrackerData = useTrackerData();

  // Unclaimed user rewards
  const totalRewards = getTotalRewards(rewardTrackerData, userVestingInfo);

  // Token prices
  const glpAumUsdE30 = useGlpAumUsdE30();
  const glpPriceE30 = getGlpPriceE30(glpAumUsdE30, supplyData?.glp);
  const { nativeTokenPriceE30, gmxPriceE30, lpTokenPriceE30 } = useLiquidityPoolInfo();

  // Protocol data
  const totalStakedLpTokenSupply = rewardTrackerData?.stakedLpTokenTracker.totalSupply;
  const totalStakedEsGmxSupply = rewardTrackerData?.stakedEsGmxTracker.totalSupply;

  const circulatingEsGmxSupply = useCirculatingEsGmxSupply();

  // Dollar value of data
  // 1. supplies
  const lpTokenSupplyUsdE30 = getValueUsd(supplyData?.lpToken, lpTokenPriceE30);
  const stakedLpTokenSupplyUsdE30 = getValueUsd(totalStakedLpTokenSupply, lpTokenPriceE30);
  const circulatingEsGmxSupplyUsdE30 = getValueUsd(circulatingEsGmxSupply, gmxPriceE30);
  const totalStakedEsGmxSupplyUsdE30 = getValueUsd(totalStakedEsGmxSupply, gmxPriceE30);

  // 2. balances
  const balancesUsdE30 = getBalancesUsdE30(balanceData, lpTokenPriceE30, gmxPriceE30, glpPriceE30);

  // 3. user stakes- gmx and esGmx
  const userLpTokenStakeUsdE30 = getValueUsd(userStakes?.lpToken, lpTokenPriceE30);
  const userEsGmxStakeUsdE30 = getValueUsd(userStakes?.esGmx, gmxPriceE30);

  // 4. tracker rewards for user in USD for 3 stakers- esGmx, nativeToken, total
  const claimableRewardsUsdE30 = getRewardsUsdE30(rewardTrackerData, gmxPriceE30, nativeTokenPriceE30);

  const totalVesterRewardsUsdE30 = getValueUsd(totalRewards.totalVesterRewards, gmxPriceE30);

  const boostBasisPoints =
    userStakes !== undefined && !userStakes.lpToken.eq(0)
      ? userStakes.bonusLpToken.mul(BASIS_POINTS_DIVISOR).div(userStakes.lpToken)
      : BigZero;

  // APR fields
  const aprData = calculateAPRs(
    gmxPriceE30,
    nativeTokenPriceE30,
    lpTokenPriceE30,
    glpPriceE30,
    rewardTrackerData,
    boostBasisPoints
  );

  // Modal states
  const [stakeLpToken, setStakeLpToken] = useState(false);
  const [stakeEsGmx, setStakeEsGmx] = useState(false);
  const [tokenToUnstake, setTokenToUnstake] = useState<"lpToken" | "esGmx">();
  const [tokenToDepositInVester, setTokenToDepositInVester] = useState<"lpToken" | "esGmx" | "glp">();
  const [tokenToWithdrawFromVester, setTokenToWithdrawFromVester] = useState<"lpToken" | "esGmx" | "glp">();

  function setTokenToStake(token: "lpToken" | "esGmx") {
    if (token === "lpToken") {
      setStakeLpToken(true);
    } else if (token === "esGmx") {
      setStakeEsGmx(true);
    }
  }

  return (
    <>
      <div className="background__wrapper">
        <div className="backgroundStars">
          <img src={starsImg} alt="stars" className="backgroundStars__image" />
        </div>
        <div className="backgroundPot">
          <img src={potImg} alt="pot" className="backgroundPot__image" />
        </div>
        <div className="backgroundSkeleton">
          <img src={skeletonImg} alt="pot" className="backgroundSkeleton__image" />
        </div>
      </div>
      <div className="default-container Earn-page-layout z-index-100">
        <PageTitle />

        <div className="StakeV2-content">
          {/* 1. Total rewards */}
          <TotalRewardsBox
            rewardTrackerData={rewardTrackerData}
            trackerStakesForUser={userStakes}
            userVestingInfo={userVestingInfo}
            totalRewards={totalRewards}
            claimableRewardsUsdE30={claimableRewardsUsdE30}
            totalVesterRewardsUsdE30={totalVesterRewardsUsdE30}
            setPendingTxns={setPendingTxns}
            connectWallet={connectWallet}
          />

          <br />
          {/* 2. Stakers */}
          <div id="stakers" className="StakeV2-cards">
            {/* Holdings column */}
            <div className="StakeV2-Holdings-wrapper">
              <div className="StakeV2-block-title-wrapper">
                <img
                  className="StakeV2-yellow-rectangle-image"
                  src={SwapYellowRectangleImage}
                  alt="SwapYellowRectangleImage"
                />
                <img className="StakeV2-title-image" src={StakeV2WalletImage} alt="StakeV2WalletImage" />
                <div className="StakeV2-block-title">Holdings</div>
              </div>

              {/* Holdings cards */}
              <div id="holding-cards" className="StakeV2-Holdings-block">
                <HoldingCard
                  name="VMX/ETH"
                  image={VMXETHImage}
                  priceE30={lpTokenPriceE30}
                  balance={balanceData?.lpToken}
                  balanceUsdE30={balancesUsdE30.lpToken}
                  staked={userStakes?.lpToken}
                  stakedUsdE30={userLpTokenStakeUsdE30}
                  setTokenToStake={setTokenToStake}
                  setTokenToUnstake={setTokenToUnstake}
                  connectWallet={connectWallet}
                />
                <HoldingCard
                  name="esVMX"
                  image={esVMXTokenImage}
                  priceE30={gmxPriceE30}
                  balance={balanceData?.esGmx}
                  balanceUsdE30={balancesUsdE30.esGmx}
                  staked={userStakes?.esGmx}
                  stakedUsdE30={userEsGmxStakeUsdE30}
                  setTokenToStake={setTokenToStake}
                  setTokenToUnstake={setTokenToUnstake}
                  connectWallet={connectWallet}
                />
                <HoldingCard
                  name="VLP"
                  image={VLPTokenImage}
                  priceE30={glpPriceE30}
                  balance={balanceData?.glp}
                  balanceUsdE30={balancesUsdE30.glp}
                  staked={balanceData?.glp}
                  stakedUsdE30={balancesUsdE30.glp}
                  setTokenToStake={setTokenToStake}
                  setTokenToUnstake={setTokenToUnstake}
                  connectWallet={connectWallet}
                />
              </div>
            </div>

            {/* Manage assets column */}
            <div className="StakeV2-ManageAssets-wrapper">
              <div className="StakeV2-block-title-wrapper StakeV2-ManageAssets-title-wrapper">
                <img
                  className="StakeV2-yellow-rectangle-image"
                  src={SwapYellowRectangleImage}
                  alt="SwapYellowRectangleImage"
                />
                <img className="StakeV2-title-image" src={StakeV2TrendingUpImage} alt="StakeV2TrendingUpImage" />
                <div className="StakeV2-block-title">Manage Assets</div>
              </div>

              {/* Manage assets cards */}
              <div className="StakeV2-ManageAssets-block">
                {/* 1. VMX/ETH staker */}
                <div id="manage-vmx-ftm-lp" className="StakeV2-card StakeV2-card-ManageAssets">
                  <div className="StakeV2-card-ManageAssets-header">
                    <div className="StakeV2-card-ManageAssets-header-left">
                      <div className="StakeV2-card-ManageAssets-header-row">
                        <div className="StakeV2-card-ManageAssets-header-row-title">APR VMX/ETH</div>
                        <div className="StakeV2-card-ManageAssets-header-row-value">
                          <Tooltip
                            handle={`${formatAmount(aprData?.lpTokenApr.totalWithBoost, 2, 2, true)}%`}
                            position="right-bottom"
                            renderContent={() => <LpTokenAprTooltip lpTokenApr={aprData?.lpTokenApr} />}
                          />
                        </div>
                      </div>

                      <div className="StakeV2-card-ManageAssets-header-row">
                        <div className="StakeV2-card-ManageAssets-header-row-title">Boost Percentage</div>
                        <div className="StakeV2-card-ManageAssets-header-row-value">
                          <Tooltip
                            handle={`${formatAmount(boostBasisPoints, 2, 2, false)}%`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <div className="StakeV2-Holdings-card-tooltip">
                                  <Trans>
                                    You are earning {formatAmount(boostBasisPoints, 2, 2, false)}% more{" "}
                                    {nativeTokenSymbol} rewards using{" "}
                                    {formatAmount(userStakes?.bonusLpToken, 18, 4, true)} Staked Multiplier Points.
                                  </Trans>
                                  <br />
                                  <br />
                                  <Trans>Use the "Compound" button to stake your Multiplier Points.</Trans>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="StakeV2-card-ManageAssets-header-right">
                      <div className="StakeV2-card-ManageAssets-header-row">
                        <div className="StakeV2-card-ManageAssets-header-row-title">Rewards</div>
                        <div className="StakeV2-card-ManageAssets-header-row-value">
                          <Tooltip
                            handle={`$${formatAmount(
                              claimableRewardsUsdE30.lpTokenTrackerTotal,
                              USD_DECIMALS,
                              2,
                              true
                            )}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <div className="StakeV2-Holdings-card-tooltip">
                                  <StatsTooltipRow
                                    label={`${nativeTokenSymbol}`}
                                    value={`${formatAmount(
                                      rewardTrackerData?.feeLpTokenTracker.claimable,
                                      18,
                                      4
                                    )} ($${formatAmount(
                                      claimableRewardsUsdE30.feeLpTokenTracker,
                                      USD_DECIMALS,
                                      2,
                                      true
                                    )})`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label="Escrowed VMX"
                                    value={`${formatAmount(
                                      rewardTrackerData?.stakedLpTokenTracker.claimable,
                                      18,
                                      4
                                    )} ($${formatAmount(
                                      claimableRewardsUsdE30.stakedLpTokenTracker,
                                      USD_DECIMALS,
                                      2,
                                      true
                                    )})`}
                                    showDollar={false}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="StakeV2-card-ManageAssets-main">
                    <div className="StakeV2-card-ManageAssets-header-row-title">Total Staked</div>
                    <div className="StakeV2-card-ManageAssets-header-row-value-total">
                      {totalStakedLpTokenSupply !== undefined ? (
                        <div>
                          {formatAmount(totalStakedLpTokenSupply, 18, 2, true)} VMX/ETH ($
                          {stakedLpTokenSupplyUsdE30
                            ? formatAmount(stakedLpTokenSupplyUsdE30, USD_DECIMALS, 2, true)
                            : 0}
                          )
                        </div>
                      ) : (
                        "..."
                      )}
                    </div>
                  </div>
                  <div className="StakeV2-card-ManageAssets-main">
                    <div className="StakeV2-card-ManageAssets-header-row-title">Total Supply</div>
                    <div className="StakeV2-card-ManageAssets-header-row-value-total">
                      {supplyData === undefined ? (
                        "..."
                      ) : (
                        <div>
                          {formatAmount(supplyData.lpToken, 18, 2, true)} VMX/ETH ($
                          {formatAmount(lpTokenSupplyUsdE30, USD_DECIMALS, 2, true)})
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="StakeV2-card-ManageAssets-hooter">
                    {/* TODO set liquidity page link */}
                    <ExternalLink
                      className="StakeV2-button-outline App-card-option"
                      href="https://pancakeswap.finance/"
                    >
                      Buy VMX
                    </ExternalLink>
                    {active && (
                      <Link className="StakeV2-button-outline App-card-option" to="/begin_account_transfer">
                        <Trans>Transfer Account</Trans>
                      </Link>
                    )}
                  </div>
                </div>

                {/* 2. esVMX staker */}
                <div id="manage-es-vmx" className="StakeV2-card StakeV2-card-ManageAssets">
                  <div className="StakeV2-card-ManageAssets-header">
                    <div className="StakeV2-card-ManageAssets-header-left">
                      <div className="StakeV2-card-ManageAssets-header-row">
                        <div className="StakeV2-card-ManageAssets-header-row-title">APR esVMX</div>
                        <div className="StakeV2-card-ManageAssets-header-row-value">
                          <Tooltip
                            handle={`${formatAmount(aprData?.esGmxApr?.total, 2, 2, true)}%`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <div className="StakeV2-Holdings-card-tooltip">
                                  <StatsTooltipRow
                                    label={`${nativeTokenSymbol} APR`}
                                    value={`${formatAmount(aprData?.esGmxApr?.nativeToken, 2, 2, true)}%`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label="Escrowed VMX APR"
                                    value={`${formatAmount(aprData?.esGmxApr?.esGmx, 2, 2, true)}%`}
                                    showDollar={false}
                                  />
                                  <br />

                                  <Trans>
                                    APRs are updated weekly on Wednesday and will depend on the fees collected for the
                                    week.
                                  </Trans>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="StakeV2-card-ManageAssets-header-right">
                      <div className="StakeV2-card-ManageAssets-header-row">
                        <div className="StakeV2-card-ManageAssets-header-row-title">Rewards</div>
                        <div className="StakeV2-card-ManageAssets-header-row-value">
                          <Tooltip
                            handle={`$${formatAmount(claimableRewardsUsdE30.esGmxTrackerTotal, USD_DECIMALS, 2, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <div className="StakeV2-Holdings-card-tooltip">
                                  <StatsTooltipRow
                                    label={`${nativeTokenSymbol}`}
                                    value={`${formatAmount(
                                      rewardTrackerData?.feeEsGmxTracker.claimable,
                                      18,
                                      4
                                    )} ($${formatAmount(
                                      claimableRewardsUsdE30.feeEsGmxTracker,
                                      USD_DECIMALS,
                                      2,
                                      true
                                    )})`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label="Escrowed VMX"
                                    value={`${formatAmount(
                                      rewardTrackerData?.stakedEsGmxTracker.claimable,
                                      18,
                                      4
                                    )} ($${formatAmount(
                                      claimableRewardsUsdE30.stakedEsGmxTracker,
                                      USD_DECIMALS,
                                      2,
                                      true
                                    )})`}
                                    showDollar={false}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="StakeV2-card-ManageAssets-header-row"></div>
                    </div>
                  </div>

                  <div className="StakeV2-card-ManageAssets-main">
                    <div className="StakeV2-card-ManageAssets-header-row-title">Total Staked</div>
                    <div className="StakeV2-card-ManageAssets-header-row-value-total">
                      {formatAmount(totalStakedEsGmxSupply, 18, 2, true)} esVMX ($
                      {formatAmount(totalStakedEsGmxSupplyUsdE30, USD_DECIMALS, 2, true)})
                    </div>
                  </div>
                  <div className="StakeV2-card-ManageAssets-main">
                    <div className="StakeV2-card-ManageAssets-header-row-title">Total Supply</div>
                    <div className="StakeV2-card-ManageAssets-header-row-value-total">
                      {formatAmount(circulatingEsGmxSupply, 18, 2, true)} esVMX ($
                      {formatAmount(circulatingEsGmxSupplyUsdE30, USD_DECIMALS, 2, true)})
                    </div>
                  </div>
                </div>

                {/* 3. VLP staker */}
                <div id="manage-vlp" className="StakeV2-card StakeV2-card-ManageAssets">
                  <div className="StakeV2-card-ManageAssets-header">
                    <div className="StakeV2-card-ManageAssets-header-left">
                      <div className="StakeV2-card-ManageAssets-header-row">
                        <div className="StakeV2-card-ManageAssets-header-row-title">APR VLP</div>
                        <div className="StakeV2-card-ManageAssets-header-row-value">
                          <Tooltip
                            handle={`${formatAmount(aprData?.glpApr?.total, 2, 2, true)}%`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <div className="StakeV2-Holdings-card-tooltip">
                                  <StatsTooltipRow
                                    label={`${nativeTokenSymbol} APR`}
                                    value={`${formatAmount(aprData?.glpApr?.nativeToken, 2, 2, true)}%`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label="Escrowed VMX APR"
                                    value={`${formatAmount(aprData?.glpApr?.esGmx, 2, 2, true)}%`}
                                    showDollar={false}
                                  />
                                  <br />

                                  <Trans>
                                    APRs are updated weekly on Wednesday and will depend on the fees collected for the
                                    week.
                                  </Trans>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="StakeV2-card-ManageAssets-header-right">
                      <div className="StakeV2-card-ManageAssets-header-row">
                        <div className="StakeV2-card-ManageAssets-header-row-title">Rewards</div>
                        <div className="StakeV2-card-ManageAssets-header-row-value">
                          <Tooltip
                            handle={`$${formatAmount(claimableRewardsUsdE30.glpTrackerTotal, USD_DECIMALS, 2, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <div className="StakeV2-Holdings-card-tooltip">
                                  <StatsTooltipRow
                                    label={`${nativeTokenSymbol}`}
                                    value={`${formatAmount(
                                      rewardTrackerData?.feeGlpTracker.claimable,
                                      18,
                                      4
                                    )} ($${formatAmount(claimableRewardsUsdE30.feeGlpTracker, USD_DECIMALS, 2, true)})`}
                                    showDollar={false}
                                  />
                                  <StatsTooltipRow
                                    label="Escrowed VMX"
                                    value={`${formatAmount(
                                      rewardTrackerData?.stakedGlpTracker.claimable,
                                      18,
                                      4
                                    )} ($${formatAmount(
                                      claimableRewardsUsdE30.stakedGlpTracker,
                                      USD_DECIMALS,
                                      2,
                                      true
                                    )})`}
                                    showDollar={false}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="StakeV2-card-ManageAssets-header-row"></div>
                    </div>
                  </div>

                  <div className="StakeV2-card-ManageAssets-main">
                    <div className="StakeV2-card-ManageAssets-header-row-title">Total Staked</div>
                    <div className="StakeV2-card-ManageAssets-header-row-value-total">
                      {formatAmount(supplyData?.glp, 18, 2, true)} VLP ($
                      {formatAmount(glpAumUsdE30, USD_DECIMALS, 2, true)})
                    </div>
                  </div>
                  <div className="StakeV2-card-ManageAssets-main">
                    <div className="StakeV2-card-ManageAssets-header-row-title">Total Supply</div>
                    <div className="StakeV2-card-ManageAssets-header-row-value-total">
                      {formatAmount(supplyData?.glp, 18, 2, true)} VLP ($
                      {formatAmount(glpAumUsdE30, USD_DECIMALS, 2, true)})
                    </div>
                  </div>
                  <div className="StakeV2-card-ManageAssets-hooter">
                    <Link className="StakeV2-button-outline App-card-option" to="/buy_vlp">
                      Buy VLP
                    </Link>
                    <Link className="StakeV2-button-outline App-card-option" to="/buy_vlp#redeem">
                      Sell VLP
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 3. Vesters */}
          <div id="vesters">
            <div className="StakeV2-Tab-title-section">
              <div className="StakeV2-Page-title">
                <Trans>Vest</Trans>
              </div>
              <div className="StakeV2-subtitle">
                Convert esVMX tokens to VMX tokens. Please read the {/* <br /> */}
                <ExternalLink
                  className="StakeV2-link"
                  href="https://voodootrade.gitbook.io/voodoo-trade/rewards#vesting"
                >
                  vesting details
                </ExternalLink>{" "}
                before using the vaults.
              </div>
            </div>
            <div>
              <div className="StakeV2-Vest-cards">
                <VesterCard
                  name="VMX/ETH"
                  icon={VMXETHImage}
                  userStake={userStakes?.bonusPlusLpToken}
                  vestingInfo={userVestingInfo?.lpTokenVester}
                  depositCallback={() => setTokenToDepositInVester("lpToken")}
                  withdrawCallback={() => setTokenToWithdrawFromVester("lpToken")}
                  connectWallet={connectWallet}
                />
                <VesterCard
                  name="esVMX"
                  icon={esVMXTokenImage}
                  userStake={userStakes?.esGmx}
                  vestingInfo={userVestingInfo?.esGmxVester}
                  depositCallback={() => setTokenToDepositInVester("esGmx")}
                  withdrawCallback={() => setTokenToWithdrawFromVester("esGmx")}
                  connectWallet={connectWallet}
                />
                <VesterCard
                  name="VLP"
                  icon={VLPTokenImage}
                  userStake={userStakes?.glp}
                  vestingInfo={userVestingInfo?.glpVester}
                  depositCallback={() => setTokenToDepositInVester("glp")}
                  withdrawCallback={() => setTokenToWithdrawFromVester("glp")}
                  connectWallet={connectWallet}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        <div id="modals">
          <StakeLpTokenModal
            stakeLpToken={stakeLpToken}
            setStakeLpToken={setStakeLpToken}
            lpTokenBalance={balanceData?.lpToken}
            setPendingTxns={setPendingTxns}
          />
          <StakeEsGmxModal
            stakeEsGmx={stakeEsGmx}
            setStakeEsGmx={setStakeEsGmx}
            esGmxBalance={balanceData?.esGmx}
            setPendingTxns={setPendingTxns}
          />
          <UnstakeModal
            tokenToUnstake={tokenToUnstake}
            setTokenToUnstake={setTokenToUnstake}
            reservedLpToken={userVestingInfo?.lpTokenVester.pairAmount}
            reservedEsGmx={userVestingInfo?.esGmxVester.pairAmount}
            claimableBonusLpToken={rewardTrackerData?.bonusLpTokenTracker.claimable}
            userStakes={userStakes}
            setPendingTxns={setPendingTxns}
          />
          <VesterDepositModal
            tokenToDepositInVester={tokenToDepositInVester}
            setTokenToDepositInVester={setTokenToDepositInVester}
            esGmxBalance={balanceData?.esGmx}
            userStakes={userStakes}
            userVestingInfo={userVestingInfo}
            setPendingTxns={setPendingTxns}
          />
          <VesterWithdrawModal
            tokenToWithdrawFromVester={tokenToWithdrawFromVester}
            setTokenToWithdrawFromVester={setTokenToWithdrawFromVester}
            setPendingTxns={setPendingTxns}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
