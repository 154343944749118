import { useEffect, useState } from "react";
import invariant from "tiny-invariant";

export interface LinkedUser {
  telegramId: number;
  discordId: string;
  airdrop1: boolean;
}

/**
 * Returns the linked telegram ID, discord ID and airdrop 1 qualification if an account is linked for airdrop
 *
 * @param account
 * @returns
 */
export default function useLinkedUser(
  linkUrl: string,
  account: string | null | undefined,
  toggleRefresh: boolean
): LinkedUser | undefined {
  const [linkedUser, setLinkedUser] = useState<LinkedUser | undefined>();

  useEffect(() => {
    async function getAccount() {
      if (account == null) {
        setLinkedUser(undefined);
      }
      const endpoint = `${linkUrl}?account=${account}`;

      try {
        const resp = await fetch(endpoint);
        const { telegramId, discordId, airdrop1 } = await resp.json();
        invariant(
          typeof telegramId === "number" && typeof discordId === "string" && typeof airdrop1 === "boolean",
          "Invalid linked user response"
        );
        setLinkedUser({ telegramId, discordId, airdrop1 });
      } catch (error) {
        console.log("failed to fetch", error);
      }
    }

    getAccount();
  }, [account, toggleRefresh]);

  return linkedUser;
}
