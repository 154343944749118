import React from "react";
import { Trans } from "@lingui/macro";
import SEO from "components/Common/SEO";

import Footer from "components/Footer/Footer";
import { getPageTitle } from "lib/legacy";

import arbitrumIcon from "img/ic_arbitrum_16.svg";
import avalancheIcon from "img/ic_avalanche_16.svg";

import "./Ecosystem.css";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ARBITRUM, AVALANCHE, BASE, BASE_TESTNET } from "config/chains";
import { t } from "@lingui/macro";
import ArrowLeftYellowImage from "img/la_arrow-right.svg";
import DashboardImage from "img/dashboards.svg";
import TwitterImage from "img/la_twitter.svg";

import DiscordImage from "img/discord-v2.svg";
import ZealyImage from "img/zealy.svg";
import CoinGeckoImage from "img/coingecko.png";
import CoinMarketCapImage from "img/CoinMarketCap.png";

import DeBankImage from "img/deBank.svg";
import DefiLlamaImage from "img/defiLlama.svg";
import DopexImage from "img/dopex.svg";
import RookImage from "img/rook.svg";
import TelegramImage from "img/telegram.svg";
import BitcoinImage from "img/ic_btc_40.svg";
import EthereumImage from "img/ic_eth_40.svg";
import MediumImage from "img/icons8-medium.svg";
import LitecoinImage from "img/Litecoin_40.svg";
import BinanceCoinImage from "img/BinanceCoin_40.svg";
import TetherImage from "img/Tether_40.svg";
import SolanaImage from "img/Solana_40.svg";
import SwapYellowRectangleImage from "img/swapYellowRectangle.svg";
import EcosystemBookmarkImage from "img/EcosystemBookmark.svg";
import EcosystemHeartHandShake from "img/EcosystemHeartHandShake.svg";
import EcosystemTelegramPlane from "img/EcosystemTelegramPlane.svg";
import EcosystemCoins from "img/EcosystemCoins.svg";
import EcosystemUSDCImage from "img/ic_usdc_40.svg";

import EcosystemGMXImage from "img/EcosystemGMX.svg";

import VLPTokenImage from "img/VLPToken.png";
import VMXTokenImage from "img/VMXToken.png";

import starsImg from "img/Stars.svg";
import potImg from "img/Pot.svg";
import skeletonImg from "img/Skeleton.svg";

const NETWORK_ICONS = {
  [ARBITRUM]: arbitrumIcon,
  [AVALANCHE]: avalancheIcon,
};

const NETWORK_ICON_ALTS = {
  [ARBITRUM]: "Arbitrum Icon",
  [AVALANCHE]: "Avalanche Icon",
};

export default function Ecosystem() {
  const gmxPages = [
    // {
    //   title: "Governance",
    //   link: "https://tokenterminal.com/",
    //   linkLabel: "tokenterminal.com",
    //   about: `VMX Governance Page`,
    //   chainIds: [ARBITRUM, AVALANCHE],
    //   imageUrl: VMXTokenImage,
    // },
    // {
    //   title: "Stats",
    //   link: "https://cryptofees.info/",
    //   linkLabel: "cryptofees.info",
    //   about: `VMX Stats Page`,
    //   chainIds: [ARBITRUM, AVALANCHE],
    //   imageUrl: VMXTokenImage,
    // },
    {
      title: "Twitter",
      link: "https://twitter.com/tradevoodoo",
      linkLabel: "twitter.com",
      about: `Official VMX Twitter`,
      chainIds: [BASE, BASE_TESTNET],
      imageUrl: TwitterImage,
    },
    {
      title: "Official Telegram",
      link: "https://t.me/voodootradeofficial",
      linkLabel: "t.me",
      about: `Official VMX Group Chat`,
      chainIds: [BASE, BASE_TESTNET],
      imageUrl: TelegramImage,
    },
    {
      title: "TG Announcements",
      link: "https://t.me/voodootradeofficial",
      linkLabel: "t.me",
      about: `Official VMX TG Announcements`,
      chainIds: [BASE, BASE_TESTNET],
      imageUrl: TelegramImage,
    },
    {
      title: "Discord",
      link: "https://discord.com/invite/uWrDdekrPb",
      linkLabel: "discord.com",
      about: `Official VMX Discord Channel`,
      chainIds: [BASE, BASE_TESTNET],
      imageUrl: DiscordImage,
    },
    {
      title: "Medium",
      link: "https://medium.com/@voodootrade",
      linkLabel: "medium.com",
      about: `VMX Medium Articles`,
      chainIds: [BASE, BASE_TESTNET],
      imageUrl: MediumImage,
    },
    {
      title: "Zealy",
      link: "https://zealy.io/c/voodootrade/questboard",
      linkLabel: "zealy.io",
      about: `Community Rewards`,
      chainIds: [BASE, BASE_TESTNET],
      imageUrl: ZealyImage,
    },
    // {
    //   title: "VMX Chinese",
    //   link: "https://t.me/voodootradeofficial",
    //   linkLabel: "t.me",
    //   about: `中文组`,
    //   chainIds: [BASE, BASE_TESTNET],
    //   imageUrl: TelegramImage,
    // },
    {
      title: "VMX Token Trading Chat",
      link: "https://t.me/voodootradeofficial",
      linkLabel: "t.me",
      about: `Community Telegram Discussion`,
      chainIds: [BASE, BASE_TESTNET],
      imageUrl: TelegramImage,
    },
  ];

  const communityProjects = [
    {
      title: "GMX Blueberry Club",
      link: "https://www.blueberry.club/",
      linkLabel: "blueberry.club",
      about: t`GMX Blueberry NFTs`,
      creatorLabel: "@xm92boi",
      creatorLink: "https://t.me/xm92boi",
      chainIds: [ARBITRUM],
    },
    {
      title: "GMX Leaderboard",
      link: "https://www.gmx.house/",
      linkLabel: "gmx.house",
      about: t`Leaderboard for GMX traders`,
      creatorLabel: "@Itburnz",
      creatorLink: "https://t.me/Itburnz",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "GMX Positions Bot",
      link: "https://t.me/GMXPositions",
      linkLabel: "t.me",
      about: t`Telegram bot for GMX position updates`,
      creatorLabel: "@zhongfu",
      creatorLink: "https://t.me/zhongfu",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "Blueberry Pulse",
      link: "https://blueberrypulse.substack.com/",
      linkLabel: "substack.com",
      about: t`GMX Weekly Updates`,
      creatorLabel: "@puroscohiba",
      creatorLink: "https://t.me/puroscohiba",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "DegenClip",
      link: "https://degenclip.com/gmx",
      linkLabel: "degenclip.com",
      about: t`Community curated tweet collection`,
      creatorLabel: "@ox21l",
      creatorLink: "https://t.me/ox21l",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "GMX Yield Simulator",
      link: "https://gmx.defisims.com/",
      linkLabel: "defisims.com",
      about: t`Yield simulator for GMX`,
      creatorLabel: "@kyzoeth",
      creatorLink: "https://twitter.com/kyzoeth",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "GMX Returns Calculator",
      link: "https://docs.google.com/spreadsheets/u/4/d/1mQZlztz_NpTg5qQiYIzc_Ls1OTLfMOUtmEQN-WW8jj4/copy",
      linkLabel: "docs.google.com",
      about: t`Returns calculator for GMX and GLP`,
      creatorLabel: "@AStoicTrader1",
      creatorLink: "https://twitter.com/AStoicTrader1",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "GMX Compound Calculator",
      link: "https://docs.google.com/spreadsheets/d/14DiIE1wZkK9-Y5xSx1PzIgmpcj4ccz1YVw5nwzIWLgI/edit#gid=0",
      linkLabel: "docs.google.com",
      about: t`Optimal compound interval calculator`,
      creatorLabel: "@ChasenKaminsky",
      creatorLink: "https://twitter.com/ChasenKaminsky",
      chainIds: [AVALANCHE],
    },
    {
      title: "GMX Trading Stats",
      link: "https://t.me/GMXTradingStats",
      linkLabel: "t.me",
      about: t`Telegram bot for Open Interest on GMX`,
      creatorLabel: "@SniperMonke01",
      creatorLink: "https://twitter.com/SniperMonke01",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "GMX Staking Bot",
      link: "https://t.me/GMX_Staking_bot",
      linkLabel: "t.me",
      about: t`GMX staking rewards updates and insights`,
      creatorLabel: "@GMX_Staking_bot",
      creatorLink: "https://twitter.com/GMX_Staking_bot",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "GMX Staking Calculator",
      link: "https://gmxstaking.com",
      linkLabel: "gmxstaking.com",
      about: t`GMX staking calculator`,
      creatorLabel: "@n1njawtf",
      creatorLink: "https://t.me/n1njawtf",
      chainIds: [ARBITRUM, AVALANCHE],
    },
  ];

  const dashboardProjects = [
    {
      title: "GMX Referrals Dashboard",
      link: "https://www.gmxreferrals.com/",
      linkLabel: "gmxreferrals.com",
      about: t`Dashboard for GMX referral stats`,
      creatorLabel: "@kyzoeth",
      creatorLink: "https://twitter.com/kyzoeth",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "GMX Terminal",
      link: "https://gmxterminal.com",
      linkLabel: "gmxterminal.com",
      about: t`GMX explorer for stats and traders`,
      creatorLabel: "@vipineth",
      creatorLink: "https://t.me/vipineth",
      chainIds: [ARBITRUM],
    },
    {
      title: "GMX Analytics",
      link: "https://gmxstats.com/",
      linkLabel: "gmxstats.com",
      about: t`Financial reports and protocol analytics`,
      creatorLabel: "@CryptoMessiah",
      creatorLink: "https://t.me/LarpCapital",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "TokenTerminal",
      link: "https://tokenterminal.com/terminal/projects/gmx",
      linkLabel: "tokenterminal.com",
      about: t`GMX fundamentals`,
      creatorLabel: "@tokenterminal",
      creatorLink: "https://twitter.com/tokenterminal",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "CryptoFees",
      link: "https://cryptofees.info",
      linkLabel: "cryptofees.info",
      about: t`Fees generated by GMX`,
      creatorLabel: "@CryptoFeesInfo",
      creatorLink: "https://twitter.com/CryptoFeesInfo",
      chainIds: [ARBITRUM, AVALANCHE],
    },
    {
      title: "Shogun Dashboard (Dune Arbitrum)",
      link: "https://dune.com/shogun/gmx-analytics-arbitrum",
      linkLabel: "dune.com",
      about: t`Protocol analytics`,
      creatorLabel: "@JamesCliffyz",
      creatorLink: "https://twitter.com/JamesCliffyz",
      chainIds: [ARBITRUM],
    },
    {
      title: "Shogun Dashboard (Dune Avalanche)",
      link: "https://dune.com/shogun/gmx-analytics-avalanche",
      linkLabel: "dune.com",
      about: t`Protocol analytics`,
      creatorLabel: "@JamesCliffyz",
      creatorLink: "https://twitter.com/JamesCliffyz",
      chainIds: [AVALANCHE],
    },
    {
      title: "GMX Perpetuals Data",
      link: "https://app.laevitas.ch/altsderivs/GMX/perpetualswaps",
      linkLabel: "laevitas.ch",
      about: t`GMX Perpetuals Data`,
      creatorLabel: "@laevitas1",
      creatorLink: "https://twitter.com/laevitas1",
      chainIds: [ARBITRUM],
    },
    {
      title: "GMX Blueberry Leaderboard",
      link: "https://www.blueberryboard.com",
      linkLabel: "blueberryboard.com",
      about: t`GBC NFTs APR tracker and rewards`,
      creatorLabel: "@kyzoeth",
      creatorLink: "https://twitter.com/kyzoeth",
      chainIds: [ARBITRUM],
    },
  ];

  const integrations = [
    {
      title: "Defi Llama",
      link: "https://defillama.com/protocol/voodoo-trade",
      linkLabel: "defillama.com",
      about: `DeFi Dashboard`,
      // about: t`Decentralized Finance Dashboard`,
      // announcementLabel: "twitter.com",
      // announcementLink: "https://twitter.com/GMX_IO/status/1438124768033660938",
      // chainIds: [ARBITRUM, AVALANCHE],
      imageUrl: DefiLlamaImage,
    },
    // {
    //   title: "CoinGecko",
    //   link: "https://www.coingecko.com/",
    //   linkLabel: "coingecko.com",
    //   about: `Token Info`,
    //   // about: t`MEV Optimizer`,
    //   // announcementLabel: "twitter.com",
    //   // announcementLink: "https://twitter.com/Rook/status/1509613786600116251",
    //   // chainIds: [ARBITRUM, AVALANCHE],
    //   imageUrl: CoinGeckoImage,
    // },
    // {
    //   title: "CoinMarketCap",
    //   link: "https://coinmarketcap.com/",
    //   linkLabel: "coinmarketcap.com",
    //   about: `Token Info`,
    //   // about: t`Decentralized Options Protocol`,
    //   // announcementLabel: "twitter.com",
    //   // announcementLink: "https://twitter.com/GMX_IO/status/1482445801523716099",
    //   // chainIds: [ARBITRUM, AVALANCHE],
    //   imageUrl: CoinMarketCapImage,
    // },
    // {
    //   title: "Open Ocean",
    //   link: "https://openocean.finance/",
    //   linkLabel: "openocean.finance",
    //   about: t`DEX Aggregator`,
    //   announcementLabel: "twitter.com",
    //   announcementLink: "https://twitter.com/GMX_IO/status/1495780826016989191",
    //   chainIds: [ARBITRUM, AVALANCHE],
    // },
    // {
    //   title: "Paraswap",
    //   link: "https://www.paraswap.io/",
    //   linkLabel: "paraswap.io",
    //   about: t`DEX Aggregator`,
    //   announcementLabel: "twitter.com",
    //   announcementLink: "https://twitter.com/paraswap/status/1546869879336222728",
    //   chainIds: [ARBITRUM, AVALANCHE],
    // },
    // {
    //   title: "1inch",
    //   link: "https://1inch.io/",
    //   linkLabel: "1inch.io",
    //   about: t`DEX Aggregator`,
    //   announcementLabel: "twitter.com",
    //   announcementLink: "https://twitter.com/GMX_IO/status/1522247451410845696",
    //   chainIds: [ARBITRUM, AVALANCHE],
    // },
    // {
    //   title: "Firebird Finance",
    //   link: "https://app.firebird.finance/swap",
    //   linkLabel: "firebird.finance",
    //   about: t`DEX Aggregator`,
    //   announcementLabel: "twitter.com",
    //   announcementLink: "https://twitter.com/financefirebird/status/1561767094064238595",
    //   chainIds: [AVALANCHE],
    // },
    // {
    //   title: "Yield Yak Swap",
    //   link: "https://yieldyak.com/swap",
    //   linkLabel: "yieldyak.com",
    //   about: t`DEX Aggregator`,
    //   announcementLabel: "twitter.com",
    //   announcementLink: "https://twitter.com/yieldyak_/status/1484458884827947008",
    //   chainIds: [AVALANCHE],
    // },
  ];

  const telegramGroups = [
    {
      title: "VMX",
      link: "https://t.me/GMX_IO",
      linkLabel: "t.me",
      about: t`Telegram Group`,
    },
    {
      title: "VMX (Chinese)",
      link: "https://t.me/gmxch",
      linkLabel: "t.me",
      about: t`Telegram Group (Chinese)`,
    },
    {
      title: "VMX (Portuguese)",
      link: "https://t.me/GMX_Portuguese",
      linkLabel: "t.me",
      about: t`Telegram Group (Portuguese)`,
    },
    {
      title: "VMX Trading Chat",
      link: "https://t.me/gambittradingchat",
      linkLabel: "t.me",
      about: `VMX community discussion`,
    },
  ];

  const supportedTokens = [
    {
      title: "Ether",
      about: "ETH",
      imageUrl: EthereumImage,
    },
    {
      title: "USDC",
      about: "USDC",
      imageUrl: EcosystemUSDCImage,
    },
    // {
    //   title: 'Bitcoin',
    //   about: 'BTC',
    //   imageUrl: BitcoinImage,
    // },
    // {
    //   title: 'Ethereum',
    //   about: 'ETH',
    //   imageUrl: EthereumImage,
    // },
    // {
    //   title: 'Litecoin',
    //   about: 'LTC',
    //   imageUrl: LitecoinImage,
    // },
    // {
    //   title: 'Binance Coin',
    //   about: 'BNB',
    //   imageUrl: BinanceCoinImage,
    // },
    // {
    //   title: 'Tether',
    //   about: 'USDT',
    //   imageUrl: TetherImage,
    // },
    // {
    //   title: 'Solana',
    //   about: 'SOL',
    //   imageUrl: SolanaImage,
    // },
  ];

  return (
    <SEO title={getPageTitle("Ecosystem Projects")}>
      <div className="background__wrapper">
        <div className="backgroundStars">
          <img src={starsImg} alt="stars" className="backgroundStars__image" />
        </div>
        <div className="backgroundPot">
          <img src={potImg} alt="pot" className="backgroundPot__image" />
        </div>
        <div className="backgroundSkeleton">
          <img src={skeletonImg} alt="pot" className="backgroundSkeleton__image" />
        </div>
      </div>
      <div className="default-container Ecosystem-page-layout Ecosystem-position-relative z-index-100">
        {/* <div className="Ecosystem-bg-top-image "></div> */}
        {/* <div className="Ecosystem-bg-bottom-image "></div> */}
        <div className="Ecosystem-z-index-10">
          <div className="Ecosystem-page-title">Ecosystem</div>
          <div className="Ecosystem-section-title-block">
            <div className="section-title-icon" />
            <div className="Ecosystem-section-title-content">
              <img
                className="Ecosystem-yellow-rectangle-image"
                src={SwapYellowRectangleImage}
                alt={SwapYellowRectangleImage}
              />
              <img className="Ecosystem-title-image" src={EcosystemBookmarkImage} alt="EcosystemBookmarkImage" />
              <div className="Ecosystem-section-title">
                <Trans>Explore the Voodoo Ecosystem</Trans>
              </div>
            </div>
          </div>
          <div className="Ecosystem-projects">
            {gmxPages.map((item) => {
              const linkLabel = item.linkLabel ? item.linkLabel : item.link;
              return (
                <div className="Ecosystem-card" key={item.title}>
                  <div className="Ecosystem-card-title-block-with-image">
                    <div className="Ecosystem-card-title-image-wrapper">
                      <img className="Ecosystem-card-title-image" src={item.imageUrl} alt={item.title} />
                    </div>
                    <div>
                      <div className="Ecosystem-card-title">{item.title}</div>
                      <div className="Ecosystem-card-subtitle">{item.about}</div>
                    </div>
                  </div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div>
                        <ExternalLink className="Ecosystem-card-link" href={item.link}>
                          {linkLabel}
                        </ExternalLink>
                      </div>
                      <div className="Ecosystem-card-link-image-arrow">
                        <ExternalLink href={item.link}>
                          <img src={ArrowLeftYellowImage} alt="arrow" />
                        </ExternalLink>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="Ecosystem-section-title-block">
            <div className="section-title-icon" />
            <div className="Ecosystem-section-title-content">
              <img
                className="Ecosystem-yellow-rectangle-image"
                src={SwapYellowRectangleImage}
                alt="SwapYellowRectangleImage"
              />
              <img className="Ecosystem-title-image" src={EcosystemHeartHandShake} alt="EcosystemHeartHandShake" />
              <div className="Ecosystem-section-title">
                <Trans>Partnerships and Integrations</Trans>
              </div>
            </div>
          </div>
          <div className="Ecosystem-projects">
            {integrations.map((item) => {
              const linkLabel = item.linkLabel ? item.linkLabel : item.link;
              return (
                <div key={item.title} className="Ecosystem-card">
                  <div className="Ecosystem-card-title-block-with-image">
                    <div className="Ecosystem-card-title-image-wrapper">
                      <img src={item.imageUrl} alt={item.title} className="Ecosystem-card-title-image" />
                    </div>
                    <div>
                      <div className="Ecosystem-card-title">{item.title}</div>
                      <div className="Ecosystem-card-subtitle">{item.about}</div>
                    </div>
                  </div>
                  <div>
                    <div className="App-card-row">
                      {/* <div className="Ecosystem-card-announcement">
                        <Trans>Announcement</Trans>
                      </div> */}
                      <div>
                        {/* <ExternalLink className="Ecosystem-card-announcement-link" href={item.announcementLink}>
                          <img
                            className="Ecosystem-card-announcement-image"
                            src={TwitterImage}
                            alt="twitter"
                          />
                        </ExternalLink> */}
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div>
                        <ExternalLink className="Ecosystem-card-link" href={item.link}>
                          {linkLabel}
                        </ExternalLink>
                      </div>
                      <div className="Ecosystem-card-link-image-arrow">
                        <ExternalLink href={item.link}>
                          <img src={ArrowLeftYellowImage} alt="arrow" />
                        </ExternalLink>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* <div className="Ecosystem-section-title-block">
            <div className="section-title-icon" />
            <div className="Ecosystem-section-title-content">
              <img
                className="Ecosystem-yellow-rectangle-image"
                src={SwapYellowRectangleImage}
                alt="SwapYellowRectangleImage"
              />
              <img className="Ecosystem-title-image" src={EcosystemTelegramPlane} alt="EcosystemTelegramPlane" />
              <div className="Ecosystem-section-title">
                <Trans>Social media groups</Trans>
              </div>
            </div>
          </div>
          <div className="Ecosystem-projects">
            {telegramGroups.map((item) => {
              const linkLabel = item.linkLabel ? item.linkLabel : item.link;
              return (
                <div className="Ecosystem-card" key={item.title}>
                  <div className="Ecosystem-card-title-block-with-image">
                    <div className="Ecosystem-card-title-image-wrapper">
                      <img src={TelegramImage} alt={item.title} />
                    </div>
                    <div>
                      <div className="Ecosystem-card-title">{item.title}</div>
                      <div className="Ecosystem-card-subtitle">{item.about}</div>
                    </div>
                  </div>
                  <div>
                    <div className="App-card-row">
                      <div>
                        <ExternalLink className="Ecosystem-card-link" href={item.link}>
                          {linkLabel}
                        </ExternalLink>
                      </div>
                      <div className="Ecosystem-card-link-image-arrow">
                        <ExternalLink href={item.link}>
                          <img src={ArrowLeftYellowImage} alt="arrow" />
                        </ExternalLink>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
          <div className="Ecosystem-section-title-block">
            <div className="section-title-icon" />
            <div className="Ecosystem-section-title-content">
              <img
                className="Ecosystem-yellow-rectangle-image"
                src={SwapYellowRectangleImage}
                alt="SwapYellowRectangleImage"
              />
              <img className="Ecosystem-title-image" src={EcosystemCoins} alt="EcosystemCoins" />
              <div className="Ecosystem-section-title">
                <Trans>Supported Tokens</Trans>
              </div>
            </div>
          </div>
          <div className="Ecosystem-projects">
            {supportedTokens &&
              supportedTokens.map((item) => {
                return (
                  <div className="Ecosystem-card Ecosystem-card-height-short" key={item.title}>
                    <div className="Ecosystem-card-title-block-with-image">
                      <div className="Ecosystem-card-title-image-wrapper">
                        <img src={item.imageUrl} alt={item.title} className="Ecosystem-card-title-image" />
                      </div>
                      <div>
                        <div className="Ecosystem-card-title">{item.title}</div>
                        <div className="Ecosystem-card-subtitle">{item.about}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </SEO>
  );
}
