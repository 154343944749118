import { LinkUserPayload } from "./LinkAccountTypes";

/**
 * Makes POST request to link accounts and register for airdrop
 *
 * @param linkUrl
 * @param payload
 * @returns
 */
export default async function linkUser(linkUrl: string, payload: LinkUserPayload): Promise<Response> {
  return fetch(linkUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}
