import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";
import { BigNumber } from "ethers";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";

import GlpManager from "abis/GlpManager.json";
import { GLP_DECIMALS } from "lib/legacy";
import { expandDecimals } from "lib/numbers";

/**
 * Get total AUM of GLP in dollars. This is an average of max and min AUM
 * @returns
 */
export function useGlpAumUsdE30() {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();

  const glpManagerAddress = getContract(chainId, "GlpManager");
  const { data: aums } = useSWR<BigNumber[]>([`glpHooks:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });
  const aum = aums && aums.length > 0 ? aums[0].add(aums[1]).div(2) : undefined;

  return aum;
}

/**
 * Get the price of GLP
 *
 * @param glpAumUsdE30
 * @param glpSupply
 * @returns
 */
export function getGlpPriceE30(glpAumUsdE30: BigNumber | undefined, glpSupply: BigNumber | undefined) {
  return glpAumUsdE30 !== undefined && glpSupply !== undefined && glpSupply.gt(0)
    ? glpAumUsdE30.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
    : undefined;
}
