import React from "react";
import { Menu } from "@headlessui/react";
import { SortOption, sortOptions } from "pages/Leaderboard/Leaderboard";

interface LeaderboardSortDropdownProps {
  sortBy: SortOption;
  setSortBy: React.Dispatch<React.SetStateAction<SortOption>>;
  sortAscending: boolean;
  setSortAscending: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LeaderboardSortDropdown = ({
  sortBy,
  setSortBy,
  sortAscending,
  setSortAscending,
}: LeaderboardSortDropdownProps) => {
  function toggleSort(sortOption: SortOption) {
    if (sortBy === sortOption) {
      setSortAscending(!sortAscending);
    } else {
      setSortBy(sortOption);
      setSortAscending(false);
    }
  }

  return (
    <Menu>
      <Menu.Button as="div">
        <button className="App-cta transparent address-btn Leaderboard-sort-dropdown-button-label">{sortBy}</button>
      </Menu.Button>
      <div>
        <Menu.Items as="div" className="menu-items">
          {sortOptions?.map((item, i) => {
            return (
              <Menu.Item key={i}>
                <div
                  className="menu-item"
                  onClick={() => {
                    toggleSort(item);
                  }}
                >
                  <p>{item}</p>
                </div>
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </div>
    </Menu>
  );
};
