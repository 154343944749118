import { ARBITRUM, AVALANCHE, BASE, BASE_TESTNET, FANTOM, FANTOM_TESTNET } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-arbitrum-referrals",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault", // legacy
  },

  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/chicken-juju/gmx-avalanche-stats-v3",
    referrals: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-avalanche-referrals",
  },

  [FANTOM_TESTNET]: {
    stats: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-fantom-testnet-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-fantom-testnet-referral",
    prices: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-fantom-testnet-prices",
  },

  [FANTOM]: {
    stats: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-fantom-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-fantom-referrals",
    prices: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-fantom-prices",
  },

  [BASE]: {
    stats: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-base-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-base-referrals",
    prices: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-base-prices",
  },

  [BASE_TESTNET]: {
    stats: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-base-testnet-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-base-testnet-referrals",
    prices: "https://api.thegraph.com/subgraphs/name/chicken-juju/voodoo-base-testnet-prices",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/chicken-juju/chainlink-prices-subgraph-ftm2",
  },
};
