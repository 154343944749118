import React from "react";
import cx from "classnames";

import TradesPosition1Image from "img/TradesPosition1Image.svg";
import TradesPosition2Image from "img/TradesPosition2Image.svg";
import TradesPosition3Image from "img/TradesPosition3Image.svg";

interface LeaderboardCardRowProps {
  top?: number;
  label: string;
  value: string | number;
  valueColor?: string;
  usd?: boolean;
  wallet?: boolean;
}

export const LeaderboardCardRow = (props: LeaderboardCardRowProps) => {
  const { top, label, value, valueColor, usd, wallet } = props;
  return (
    <div
      className={cx("Leaderboard-card-row-wrapper", {
        "Leaderboard-card-row-wrapper-padding-right": !top || top > 3,
      })}
    >
      <div className="Leaderboard-card-row-label">{label}</div>
      <div
        className={cx("Leaderboard-card-row-value", { "Leaderboard-card-row-value-wallet": wallet })}
        style={{
          color: valueColor,
        }}
      >
        {valueColor ? (valueColor === "#16d156" ? "+" : "-") : null}
        {usd && "$"}
        {top ? (
          top < 4 ? (
            <img
              src={top === 1 ? TradesPosition1Image : top === 2 ? TradesPosition2Image : TradesPosition3Image}
              alt="TradesPositionImage"
              className="Leaderboard-table-position-image"
            />
          ) : (
            top
          )
        ) : (
          value
        )}
      </div>
    </div>
  );
};
