import { LeaderboardRowWithRoI } from "pages/Leaderboard/useLeaderboard";
import React from "react";
import TradesPosition1Image from "img/TradesPosition1Image.svg";
import TradesPosition2Image from "img/TradesPosition2Image.svg";
import TradesPosition3Image from "img/TradesPosition3Image.svg";
import ReferralsDisconnectAccountWalletImage from "img/ReferralsDisconnectAccountWalletImage.svg";

export const TableRow = ({ currentItems }: { currentItems: LeaderboardRowWithRoI[] }) => {
  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => {
          const { wallet, wins, losses, tradingVolume, netProfit, RoI, top, totalCollateral } = item;

          const showRoI = RoI === 0 ? "0" : RoI.toFixed(2);

          return (
            <tr key={index} className="Leaderboard-table-row">
              <td>
                {top < 4 ? (
                  <img
                    src={top === 1 ? TradesPosition1Image : top === 2 ? TradesPosition2Image : TradesPosition3Image}
                    alt="TradesPositionImage"
                    className="Leaderboard-table-position-image"
                  />
                ) : (
                  top
                )}
              </td>
              <td>
                <img
                  src={ReferralsDisconnectAccountWalletImage}
                  alt="ReferralsDisconnectAccountWalletImage"
                  className="Leaderboard-table-wallet-image"
                />
                {wallet}
              </td>
              <td>{showRoI}</td>
              <td>{wins}</td>
              <td>{losses}</td>
              <td>${tradingVolume.toLocaleString()}</td>
              <td>${totalCollateral.toLocaleString()}</td>
              <td
                style={{
                  color: netProfit >= 0 ? "#16d156" : "#df1417",
                }}
              >
                {(netProfit >= 0 ? "$" : "-$") +
                  Math.abs(netProfit).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </td>
              {/* TODO arrow button leads to trader page */}
              {/* <td>
                <img
                  src={ArrowLeftYellowImage}
                  alt="ArrowLeftYellowImage"
                  className="Leaderboard-table-arrow-image"
                />
              </td> */}
            </tr>
          );
        })}
    </>
  );
};
