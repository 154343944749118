import { Trans } from "@lingui/macro";
import { USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";

import SwapYellowRectangleImage from "img/swapYellowRectangle.svg";
import StakeV2CoinsImage from "img/StakeV2Coins.svg";
import { getConstant } from "config/chains";
import { useChainId } from "lib/chains";
import { TrackerStates, TrackerStakesForUser, UserVestingInfo, TotalRewards } from "domain/readerHooks";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import { ClaimableRewards } from "../getUsdValue";
import { useState } from "react";
import { ClaimModal } from "../Modals/ClaimModal";
import { CompoundModal } from "../Modals/CompoundModal";

interface TotalRewardsBoxProps {
  rewardTrackerData: TrackerStates | undefined;
  trackerStakesForUser: TrackerStakesForUser | undefined;
  userVestingInfo: UserVestingInfo | undefined;
  totalRewards: TotalRewards;
  claimableRewardsUsdE30: ClaimableRewards;
  totalVesterRewardsUsdE30: BigNumber | undefined;
  setPendingTxns: any;
  connectWallet: () => any;
}

export default function TotalRewardsBox({
  rewardTrackerData,
  trackerStakesForUser,
  userVestingInfo,
  totalRewards,
  claimableRewardsUsdE30,
  totalVesterRewardsUsdE30,
  setPendingTxns,
  connectWallet,
}: TotalRewardsBoxProps) {
  const { active } = useWeb3React();
  const { chainId } = useChainId();
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  const { totalEsGmxRewards, totalNativeTokenRewards, totalVesterRewards } = totalRewards;

  const totalStakerAndVesterRewardsUsdE30 =
    claimableRewardsUsdE30?.total !== undefined && totalVesterRewardsUsdE30 !== undefined
      ? claimableRewardsUsdE30.total.add(totalVesterRewardsUsdE30)
      : undefined;

  const [isCompoundModalVisible, setIsCompoundModalVisible] = useState(false);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  return (
    <>
      <div id="total-rewards" className="StakeV2-TotalRewards-wrapper">
        <div className="StakeV2-block-title-wrapper">
          <img
            className="StakeV2-yellow-rectangle-image"
            src={SwapYellowRectangleImage}
            alt="SwapYellowRectangleImage"
          />
          <img className="StakeV2-title-image" src={StakeV2CoinsImage} alt="StakeV2CoinsImage" />
          <div className="StakeV2-block-title">Total Rewards</div>
        </div>

        {/* Card */}
        <div className="StakeV2-TotalRewards-block">
          <div id="total-rewards-usd" className="StakeV2-TotalRewards-block-total-wrapper">
            <div className="StakeV2-TotalRewards-block-total-title">Total</div>
            <div className="StakeV2-TotalRewards-block-total-value">
              ${formatAmount(totalStakerAndVesterRewardsUsdE30, USD_DECIMALS, 2, true)}
            </div>
          </div>

          {/* Balances column */}
          <div className="StakeV2-TotalRewards-block-center">
            {/* Token names */}
            <div className="StakeV2-TotalRewards-block-titles-wrapper">
              <div className="StakeV2-TotalRewards-block-title">{nativeTokenSymbol}</div>
              <div className="StakeV2-TotalRewards-block-title">esVMX</div>
              <div className="StakeV2-TotalRewards-block-title">VMX vested</div>
            </div>

            {/* Balances */}
            <div className="StakeV2-TotalRewards-block-values-wrapper">
              <div id="native-token-rewards" className="StakeV2-TotalRewards-block-value-wrapper">
                <div className="StakeV2-TotalRewards-block-value">
                  {formatAmount(totalNativeTokenRewards, 18, 4, true)}
                </div>
                <div className="StakeV2-TotalRewards-block-value-usd">
                  ${formatAmount(claimableRewardsUsdE30.nativeTokenRewards, USD_DECIMALS, 2, true)}
                </div>
              </div>

              <div id="es-gmx-rewards" className="StakeV2-TotalRewards-block-value-wrapper">
                <div className="StakeV2-TotalRewards-block-value">{formatAmount(totalEsGmxRewards, 18, 4, true)}</div>
                <div className="StakeV2-TotalRewards-block-value-usd">
                  ${formatAmount(claimableRewardsUsdE30.esGmxRewards, USD_DECIMALS, 2, true)}
                </div>
              </div>

              <div id="gmx-rewards-from-vester" className="StakeV2-TotalRewards-block-value-wrapper">
                <div className="StakeV2-TotalRewards-block-value">{formatAmount(totalVesterRewards, 18, 4, true)}</div>
                <div className="StakeV2-TotalRewards-block-value-usd">
                  ${formatAmount(totalVesterRewardsUsdE30, USD_DECIMALS, 2, true)}
                </div>
              </div>
            </div>
          </div>

          {/* Multiplier points and actions */}
          <div className="StakeV2-TotalRewards-block-4-wrapper">
            <div id="multiplier-points" className="StakeV2-TotalRewards-block-4-row">
              <div className="StakeV2-TotalRewards-block-4-row-title">Multiplier Points</div>
              <div className="StakeV2-TotalRewards-block-4-row-value">
                {formatAmount(rewardTrackerData?.bonusLpTokenTracker.claimable, 18, 2, true)}
              </div>
            </div>
            <div id="staked-multiplier-points" className="StakeV2-TotalRewards-block-4-row">
              <div className="StakeV2-TotalRewards-block-4-row-title">Staked Multiplier Points</div>
              <div className="StakeV2-TotalRewards-block-4-row-value">
                {formatAmount(trackerStakesForUser?.bonusLpToken, 18, 2, true)}
              </div>
            </div>
            <div id="buttons" className="StakeV2-TotalRewards-block-4-row-button-wrapper">
              {active ? (
                <>
                  <button
                    className="StakeV2-button-outline App-card-option"
                    onClick={() => setIsCompoundModalVisible(true)}
                  >
                    <Trans>Compound</Trans>
                  </button>
                  <button
                    className="StakeV2-button-outline App-card-option"
                    onClick={() => setIsClaimModalVisible(true)}
                  >
                    <Trans>Claim</Trans>
                  </button>
                </>
              ) : (
                <button className="StakeV2-button-outline App-card-option" onClick={connectWallet}>
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <CompoundModal
        isVisible={isCompoundModalVisible}
        setIsVisible={setIsCompoundModalVisible}
        userVestingInfo={userVestingInfo}
        setPendingTxns={setPendingTxns}
      />
      <ClaimModal
        isVisible={isClaimModalVisible}
        setIsVisible={setIsClaimModalVisible}
        setPendingTxns={setPendingTxns}
      />
    </>
  );
}
