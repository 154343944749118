import { LAST_BAR_REFRESH_INTERVAL, SUPPORTED_RESOLUTIONS } from "config/tradingview";
import { getFastPrices, getStablePriceData } from "domain/prices";
import { getTokenChartPrice } from "./requests";
import { formatTimeInBar } from "./utils";

type Bar = {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  ticker?: string;
  volume?: number;
};

/**
 * Getter class to read historic and latest price bars
 */
export class TVDataProvider {
  lastBar: Bar | null;
  startTime: number;
  lastTicker: string;
  lastPeriod: string;

  constructor() {
    this.lastBar = null;
    this.startTime = 0;
    this.lastTicker = "";
    this.lastPeriod = "";
  }

  // Get past bars- called in useTVDatafeed.getBars()
  async getHistoryBars(
    chainId: number,
    ticker: string,
    resolution: string,
    isStable: boolean,
    countBack: number
  ): Promise<Bar[]> {
    const period = SUPPORTED_RESOLUTIONS[resolution];
    try {
      const bars = isStable
        ? getStablePriceData(period, countBack)
        : (await getTokenChartPrice(chainId, ticker, period)) ?? [];

      const formattedBars = bars.map(formatTimeInBar);
      this.lastBar = formattedBars.at(-1) ?? null;

      return formattedBars;
    } catch {
      throw new Error("Failed to get history bars");
    }
  }
}
