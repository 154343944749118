import { Trans } from "@lingui/macro";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { getConstant } from "config/chains";
import { useChainId } from "lib/chains";
import { formatAmount } from "lib/numbers";

// import "../Stake.css";
import { LpTokenApr } from "domain/gmxHooks";

// TODO separate esGMX and GMX, they have different stakers now
type Props = {
  lpTokenApr: LpTokenApr | undefined;
};

/**
 * APR tooltip for GMX and esGMX
 * @param param0
 * @returns
 */
export default function LpTokenAprTooltip({ lpTokenApr }: Props) {
  const { chainId } = useChainId();
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  return (
    <div className="StakeV2-Holdings-card-tooltip">
      {/* 1. esGMX APR */}
      <StatsTooltipRow
        label="Escrowed VMX APR"
        showDollar={false}
        value={`${formatAmount(lpTokenApr?.esGmx, 2, 2, true)}%`}
      />
      {/* 2. native token APR */}
      {lpTokenApr === undefined || lpTokenApr.boost.eq(0) ? (
        <StatsTooltipRow
          label={`${nativeTokenSymbol} APR`}
          showDollar={false}
          value={`${formatAmount(lpTokenApr?.nativeToken, 2, 2, true)}%`}
        />
      ) : (
        <div>
          <br />
          <StatsTooltipRow
            label={`${nativeTokenSymbol} Base APR`}
            showDollar={false}
            value={`${formatAmount(lpTokenApr.nativeToken, 2, 2, true)}%`}
          />
          <StatsTooltipRow
            label={`${nativeTokenSymbol} Boosted APR`}
            showDollar={false}
            value={`${formatAmount(lpTokenApr.boost, 2, 2, true)}%`}
          />
          <div className="Tooltip-divider" />
          <StatsTooltipRow
            label={`${nativeTokenSymbol} Total APR`}
            showDollar={false}
            value={`${formatAmount(lpTokenApr.nativeTokenWithBoost, 2, 2, true)}%`}
          />

          <br />

          <Trans>The Boosted APR is from your staked Multiplier Points.</Trans>
        </div>
      )}
      <div>
        <br />
        <Trans>APRs are updated weekly on Wednesday and will depend on the fees collected for the week.</Trans>
      </div>
    </div>
  );
}
