import { ArrowDownIcon } from "IconComponents/ArrowDownIcon";
import { ArrowUpIcon } from "IconComponents/ArrowUpIcon";
import { SortOption } from "pages/Leaderboard/Leaderboard";
import React from "react";

interface TableHeaderWithArrowsProps {
  sortKey: SortOption;
  sortBy: SortOption;
  setSortBy: React.Dispatch<React.SetStateAction<SortOption>>;
  sortAscending: boolean;
  setSortAscending: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TableHeaderWithArrows = ({
  sortKey,
  sortBy,
  setSortBy,
  sortAscending,
  setSortAscending,
}: TableHeaderWithArrowsProps) => {
  function sortColor(selected: boolean) {
    return selected ? "#fefdff" : "#6B7C86";
  }

  return (
    <div
      className="Leaderboard-table-header-item-wrapper"
      onClick={() => {
        setSortBy(sortKey);
        setSortAscending(!sortAscending);
      }}
    >
      <div className="Leaderboard-table-th">{sortKey}</div>
      <div className="Leaderboard-table-buttons">
        <div className="Leaderboard-table-button">
          <ArrowUpIcon color={sortColor(sortKey === sortBy && sortAscending)} />
        </div>
        <div className="Leaderboard-table-button">
          <ArrowDownIcon color={sortColor(sortKey === sortBy && !sortAscending)} />
        </div>
      </div>
    </div>
  );
};
