import React from "react";
import { Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";

export default function PageTitle() {
  return (
    <div className="section-title-block">
      <div className="section-title-icon"></div>
      <div className="section-title-content">
        <div className="StakeV2-Page-title">
          <Trans>Earn</Trans>
        </div>
        <div className="StakeV2-subtitle">
          Stake{" "}
          <ExternalLink className="StakeV2-link" href="https://voodootrade.gitbook.io/voodoo-trade/tokenomics">
            VMX
          </ExternalLink>{" "}
          and{" "}
          <ExternalLink
            className="StakeV2-link"
            href="https://voodootrade.gitbook.io/voodoo-trade/vlp-liquidity-provision"
          >
            VLP
          </ExternalLink>{" "}
          to earn rewards.
        </div>
      </div>
    </div>
  );
}
