import React, { useState } from "react";
import { t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import Vester from "abis/Vester.json";
import { ethers } from "ethers";

import { callContract } from "lib/contracts";

import "../Stake.css";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";

interface VesterWithdrawModalProps {
  tokenToWithdrawFromVester: "lpToken" | "esGmx" | "glp" | undefined;
  setTokenToWithdrawFromVester: React.Dispatch<React.SetStateAction<"lpToken" | "esGmx" | "glp" | undefined>>;
  setPendingTxns: any;
}
export default function VesterWithdrawModal({
  tokenToWithdrawFromVester,
  setTokenToWithdrawFromVester,
  setPendingTxns,
}: VesterWithdrawModalProps) {
  const { library } = useWeb3React();
  const { chainId } = useChainId();
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const lpTokenVesterAddress = getContract(chainId, "LpTokenVester");
  const esGmxVesterAddress = getContract(chainId, "EsGmxVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  let title: string = "";
  let vesterAddress: string | undefined;

  switch (tokenToWithdrawFromVester) {
    case "lpToken":
      title = t`Withdraw from VMX-FTM LP Vault`;
      vesterAddress = lpTokenVesterAddress;

      break;

    case "esGmx":
      title = t`Withdraw from esVMX Vault`;
      vesterAddress = esGmxVesterAddress;

      break;

    case "glp":
      title = t`Withdraw from VLP Vault`;
      vesterAddress = glpVesterAddress;
  }

  const isVisible = tokenToWithdrawFromVester !== undefined;
  function setIsVisible(visible: boolean) {
    if (!visible) {
      setTokenToWithdrawFromVester(undefined);
    }
  }

  const onClickPrimary = () => {
    if (vesterAddress === undefined) {
      throw Error("vesterAddress undefined");
    }
    setIsWithdrawing(true);
    const contract = new ethers.Contract(vesterAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "withdraw", [], {
      sentMsg: t`Withdraw submitted.`,
      failMsg: t`Withdraw failed.`,
      successMsg: t`Withdrawn!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsWithdrawing(false);
      });
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div>
          This will withdraw and unreserve all tokens as well as pause vesting.
          <br />
          <br />
          esVMX tokens that have been converted to VMX will remain as VMX tokens.
          <br />
          <br />
          To claim VMX tokens without withdrawing, use the "Claim" button under the Total Rewards section.
          <br />
          <br />
        </div>

        <div className="Exchange-swap-button-container">
          <button
            className="StakeV2-button-solid Exchange-swap-button"
            onClick={onClickPrimary}
            disabled={isWithdrawing}
          >
            {!isWithdrawing && "Confirm Withdraw"}
            {isWithdrawing && "Confirming..."}
          </button>
        </div>
      </Modal>
    </div>
  );
}
