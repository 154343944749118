import React, { useState, useCallback } from "react";
import { t, Trans } from "@lingui/macro";

import {
  SWAP,
  INCREASE,
  DECREASE,
  USD_DECIMALS,
  getOrderError,
  getExchangeRateDisplay,
  getExchangeRate,
  getPositionForOrder,
} from "lib/legacy";
import { handleCancelOrder } from "domain/legacy";
import { getContract } from "config/contracts";

import Tooltip from "../Tooltip/Tooltip";
import OrderEditor from "./OrderEditor";

import "./OrdersList.css";
import Checkbox from "../Checkbox/Checkbox";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { formatAmount } from "lib/numbers";
import ExternalLink from "components/ExternalLink/ExternalLink";

function getOrderTitle(order, indexTokenSymbol) {
  const orderTypeText = order.type === INCREASE ? t`Increase` : t`Decrease`;
  const longShortText = order.isLong ? t`Long` : t`Short`;
  const sizeDeltaText = formatAmount(order.sizeDelta, USD_DECIMALS, 2, true);

  return `${orderTypeText} ${indexTokenSymbol} ${longShortText} by $${sizeDeltaText}`;
}

export default function OrdersList(props) {
  const {
    account,
    library,
    setPendingTxns,
    pendingTxns,
    infoTokens,
    positionsMap,
    totalTokenWeights,
    usdgSupply,
    orders,
    hideActions,
    chainId,
    savedShouldDisableValidationForTesting,
    cancelOrderIdList,
    setCancelOrderIdList,
  } = props;

  const [editingOrder, setEditingOrder] = useState(null);

  const onCancelClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, { pendingTxns, setPendingTxns });
    },
    [library, pendingTxns, setPendingTxns, chainId]
  );

  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order);
    },
    [setEditingOrder]
  );

  const renderHead = useCallback(() => {
    const isAllOrdersSelected = cancelOrderIdList?.length > 0 && cancelOrderIdList?.length === orders.length;
    return (
      <tr className="Exchange-list-header">
        {!hideActions && orders.length > 0 && (
          <th>
            <div className="checkbox-inline ">
              <Checkbox
                isChecked={isAllOrdersSelected}
                setIsChecked={() => {
                  if (isAllOrdersSelected) {
                    setCancelOrderIdList([]);
                  } else {
                    const allOrderIds = orders.map((o) => `${o.type}-${o.index}`);
                    setCancelOrderIdList(allOrderIds);
                  }
                }}
              />
            </div>
          </th>
        )}

        <th>
          <div>
            <Trans>Type</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Order</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Price</Trans>
          </div>
        </th>
        <th>
          <div>
            <Trans>Mark Price</Trans>
          </div>
        </th>
      </tr>
    );
  }, [cancelOrderIdList, orders, setCancelOrderIdList, hideActions]);

  const renderEmptyRow = useCallback(() => {
    if (orders && orders.length) {
      return null;
    }

    return (
      <div className="Exchange-empty-positions-list-note-row">
        <div>No open orders</div>
      </div>
      // <tr>
      //   <td colSpan="5">
      //     <div className="Exchange-positions-card">No open orders</div>
      //   </td>
      // </tr>
    );
  }, [orders]);

  const renderActions = useCallback(
    (order) => {
      return (
        <>
          <td>
            <button className="Exchange-list-action" onClick={() => onEditClick(order)}>
              <Trans>Edit</Trans>
            </button>
          </td>
          <td>
            <button className="Exchange-list-action" onClick={() => onCancelClick(order)}>
              <Trans>Cancel</Trans>
            </button>
          </td>
        </>
      );
    },
    [onEditClick, onCancelClick]
  );

  const renderLargeList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }
    return orders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );

        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);
        const orderId = `${order.type}-${order.index}`;

        return (
          <tr className="Exchange-list-item" key={orderId}>
            {!hideActions && (
              <td>
                <div className="checkbox-inline ">
                  <Checkbox
                    isChecked={cancelOrderIdList?.includes(orderId)}
                    setIsChecked={() => {
                      setCancelOrderIdList((prevState) => {
                        if (prevState.includes(orderId)) {
                          return prevState.filter((i) => i !== orderId);
                        } else {
                          return prevState.concat(orderId);
                        }
                      });
                    }}
                  />
                </div>
              </td>
            )}
            <td className="Exchange-list-item-type">
              <Trans>Limit</Trans>
            </td>
            <td>
              <Trans>Swap</Trans>{" "}
              {formatAmount(
                order.amountIn,
                fromTokenInfo.decimals,
                fromTokenInfo.isStable || fromTokenInfo.isUsdg ? 2 : 4,
                true
              )}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(
                order.minOut,
                toTokenInfo.decimals,
                toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                true
              )}{" "}
              {toTokenInfo.symbol}
            </td>
            <td>
              {!hideActions ? (
                <Tooltip
                  handle={getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                  position="left-top"
                  renderContent={() => (
                    <div className="Exchange-info-tooltip">
                      {t`
                          You will receive at least ${formatAmount(
                            order.minOut,
                            toTokenInfo.decimals,
                            toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                            true
                          )} ${
                        toTokenInfo.symbol
                      } if this order is executed. The execution price may vary depending on swap fees at the time the order is executed.
                        `}
                    </div>
                  )}
                />
              ) : (
                getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)
              )}
            </td>
            <td>{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, { omitSymbols: true })}</td>
            {!hideActions && renderActions(order)}
          </tr>
        );
      }

      const indexToken = getTokenInfo(infoTokens, order.indexToken);

      // Longs Increase: max price
      // Longs Decrease: min price
      // Short Increase: min price
      // Short Decrease: max price
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);

      const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      const error = getOrderError(account, order, positionsMap);
      const orderId = `${order.type}-${order.index}`;
      const orderTitle = getOrderTitle(order, indexTokenSymbol);

      const orderText = (
        <>
          {error ? (
            <Tooltip
              className="order-error"
              handle={orderTitle}
              position="left-top"
              handleClassName="plain"
              renderContent={() => (
                <div className="Exchange-info-tooltip">
                  <span className="negative">{error}</span>
                </div>
              )}
            />
          ) : (
            orderTitle
          )}
        </>
      );

      return (
        <tr className="Exchange-list-item" key={`${order.isLong}-${order.type}-${order.index}`}>
          {!hideActions && (
            <td className="Exchange-list-item-type">
              <div>
                <Checkbox
                  isChecked={cancelOrderIdList?.includes(orderId)}
                  setIsChecked={() => {
                    setCancelOrderIdList((prevState) => {
                      if (prevState.includes(orderId)) {
                        return prevState.filter((i) => i !== orderId);
                      } else {
                        return prevState.concat(orderId);
                      }
                    });
                  }}
                />
              </div>
            </td>
          )}
          <td className="Exchange-list-item-type">{order.type === INCREASE ? t`Limit` : t`Trigger`}</td>
          <td>
            {order.type === DECREASE ? (
              orderText
            ) : (
              <Tooltip
                handle={orderText}
                position="left-top"
                renderContent={() => {
                  const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
                  const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens);
                  return (
                    <div className="Exchange-info-tooltip">
                      <StatsTooltipRow
                        label={t`Collateral`}
                        value={`${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (${formatAmount(
                          order.purchaseTokenAmount,
                          collateralTokenInfo.decimals,
                          4,
                          true
                        )}
                        ${collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})`}
                      />
                    </div>
                  );
                }}
              />
            )}
          </td>
          <td>
            {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 4, true)}
          </td>
          <td>
            <Tooltip
              handle={formatAmount(markPrice, USD_DECIMALS, 4, true)}
              position="left-top"
              renderContent={() => {
                return (
                  <div className="Exchange-info-tooltip">
                    <Trans>
                      <p>
                        The price that orders can be executed at may differ slightly from the chart price, as market
                        orders update oracle prices, while limit/trigger orders do not.
                      </p>
                      <p>
                        This can also cause limit/triggers to not be executed if the price is not reached for long
                        enough.{" "}
                        <ExternalLink href="https://voodootrade.gitbook.io/voodoo-trade/">Read more</ExternalLink>.
                      </p>
                    </Trans>
                  </div>
                );
              }}
            />
          </td>
          {!hideActions && renderActions(order)}
        </tr>
      );
    });
  }, [
    orders,
    renderActions,
    infoTokens,
    positionsMap,
    hideActions,
    chainId,
    account,
    cancelOrderIdList,
    setCancelOrderIdList,
  ]);

  const renderSmallList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }

    return orders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );

        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);

        return (
          <div key={`${order.type}-${order.index}`} className="Exchange-positions-card">
            <div className="App-card-title-small">
              Swap {formatAmount(order.amountIn, fromTokenInfo.decimals, fromTokenInfo.isStable ? 2 : 4, true)}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(order.minOut, toTokenInfo.decimals, toTokenInfo.isStable ? 2 : 4, true)}{" "}
              {toTokenInfo.symbol}
            </div>
            <div className="Exchange-card-divider" />
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">
                  <Trans>Price</Trans>
                </div>
                <div>
                  <Tooltip
                    position="center-top"
                    handle={getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                    renderContent={() => (
                      <div className="Exchange-info-tooltip">
                        {t`
                            You will receive at least ${formatAmount(
                              order.minOut,
                              toTokenInfo.decimals,
                              toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                              true
                            )} ${
                          toTokenInfo.symbol
                        } if this order is executed. The exact execution price may vary depending on fees at the time the order is executed.
                          `}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">
                  <Trans>Mark Price</Trans>
                </div>
                <div>{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo)}</div>
              </div>
              {!hideActions && (
                <>
                  <div className="Exchange-card-divider" />
                  <div className="App-card-options">
                    <button
                      className="Exchange-position-button-custom App-card-option"
                      onClick={() => onEditClick(order)}
                    >
                      <Trans>Edit</Trans>
                    </button>
                    <button
                      className="Exchange-position-button-custom App-card-option"
                      onClick={() => onCancelClick(order)}
                    >
                      <Trans>Cancel</Trans>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      }

      const indexToken = getTokenInfo(infoTokens, order.indexToken);
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);
      const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice;
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      // order.purchaseToken is undefined
      const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
      const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, true, infoTokens);

      const error = getOrderError(account, order, positionsMap);
      const orderTitle = getOrderTitle(order, indexTokenSymbol);

      return (
        <div key={`${order.isLong}-${order.type}-${order.index}`} className="Exchange-positions-card">
          <div className="App-card-title-small">
            {error ? (
              <Tooltip
                className="order-error"
                handle={orderTitle}
                position="left-top"
                handleClassName="plain"
                renderContent={() => (
                  <div className="Exchange-info-tooltip">
                    <span className="negative">{error}</span>
                  </div>
                )}
              />
            ) : (
              orderTitle
            )}
          </div>
          <div className="Exchange-card-divider"></div>
          <div className="App-card-content">
            <div className="App-card-row">
              <div className="label">
                <Trans>Price</Trans>
              </div>
              <div>
                {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 4, true)}
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">
                <Trans>Mark Price</Trans>
              </div>
              <div>
                <Tooltip
                  handle={formatAmount(markPrice, USD_DECIMALS, 4, true)}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div className="Exchange-info-tooltip">
                        <Trans>
                          The price that the order can be executed at may differ slightly from the chart price as market
                          orders can change the price while limit / trigger orders cannot.
                        </Trans>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            {order.type === INCREASE && (
              <div className="App-card-row">
                <div className="label">
                  <Trans>Collateral</Trans>
                </div>
                <div>
                  ${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (
                  {formatAmount(order.purchaseTokenAmount, collateralTokenInfo.decimals, 4, true)}{" "}
                  {collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})
                </div>
              </div>
            )}
            {!hideActions && (
              <>
                <div className="Exchange-card-divider"></div>
                <div className="App-card-options">
                  <button
                    className="Exchange-position-button-custom App-card-option"
                    onClick={() => onEditClick(order)}
                  >
                    <Trans>Edit</Trans>
                  </button>
                  <button
                    className="Exchange-position-button-custom App-card-option"
                    onClick={() => onCancelClick(order)}
                  >
                    <Trans>Cancel</Trans>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    });
  }, [orders, onEditClick, onCancelClick, infoTokens, positionsMap, hideActions, chainId, account]);

  return (
    <React.Fragment>
      <table className="Exchange-list Orders large">
        <tbody>
          {renderHead()}
          {/* {renderEmptyRow()} */}
          {renderLargeList()}
        </tbody>
      </table>
      {renderEmptyRow()}
      <div className="Exchange-list Orders small">
        {/* {(!orders || orders.length === 0) && (
          <div className="Exchange-positions-card-wrapper">
            <div className="Exchange-empty-positions-list-note Exchange-positions-card">
              <Trans>No open orders</Trans>
            </div>
          </div>
        )} */}
        {renderSmallList()}
      </div>
      {editingOrder && (
        <OrderEditor
          account={account}
          order={editingOrder}
          setEditingOrder={setEditingOrder}
          infoTokens={infoTokens}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          getPositionForOrder={getPositionForOrder}
          positionsMap={positionsMap}
          library={library}
          totalTokenWeights={totalTokenWeights}
          usdgSupply={usdgSupply}
          savedShouldDisableValidationForTesting={savedShouldDisableValidationForTesting}
        />
      )}
    </React.Fragment>
  );
}
