import React, { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { TableHeaderWithArrows } from "./TableHeaderWithArrows";
import { TableRow } from "./TableRow";
import { LeaderboardRowWithRoI } from "pages/Leaderboard/useLeaderboard";
import { SortOption } from "pages/Leaderboard/Leaderboard";

interface LeaderboardTableLargeProps {
  sortedLeaderboardData: LeaderboardRowWithRoI[];
  sortBy: SortOption;
  setSortBy: React.Dispatch<React.SetStateAction<SortOption>>;
  sortAscending: boolean;
  setSortAscending: React.Dispatch<React.SetStateAction<boolean>>;
}
export const LeaderboardTableLarge = ({
  sortedLeaderboardData,
  sortBy,
  setSortBy,
  sortAscending,
  setSortAscending,
}: LeaderboardTableLargeProps) => {
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;
  const endOffset = useMemo(() => {
    return itemOffset + itemsPerPage;
  }, [itemOffset, itemsPerPage]);

  const currentItems = useMemo(() => {
    return sortedLeaderboardData ? sortedLeaderboardData.slice(itemOffset, endOffset) : [];
  }, [sortedLeaderboardData, itemOffset, endOffset]);

  const pageCount = useMemo(() => {
    return sortedLeaderboardData ? Math.ceil(sortedLeaderboardData.length / itemsPerPage) : 1;
  }, [sortedLeaderboardData, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % sortedLeaderboardData.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <table className="Leaderboard-table-large">
        <tbody>
          <tr className="Leaderboard-table-header">
            <th>Top</th>
            <th>Wallet</th>
            <th>
              <TableHeaderWithArrows
                sortKey="RoI %"
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortAscending={sortAscending}
                setSortAscending={setSortAscending}
              />
            </th>
            <th>Wins</th>
            <th>Losses</th>
            <th>
              <TableHeaderWithArrows
                sortKey="Trading Volume"
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortAscending={sortAscending}
                setSortAscending={setSortAscending}
              />
            </th>
            <th>
              <TableHeaderWithArrows
                sortKey="Margin"
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortAscending={sortAscending}
                setSortAscending={setSortAscending}
              />
            </th>
            <th>
              <TableHeaderWithArrows
                sortKey="Net Profit"
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortAscending={sortAscending}
                setSortAscending={setSortAscending}
              />
            </th>
          </tr>
          <TableRow currentItems={currentItems} />
        </tbody>
      </table>

      <div className="Leaderboard-paginate-wrapper">
        <ReactPaginate
          renderOnZeroPageCount={undefined}
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={7}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="Leaderboard-page-item"
          pageLinkClassName="Leaderboard-page-link"
          previousClassName="Leaderboard-page-item"
          previousLinkClassName="Leaderboard-page-link"
          nextClassName="Leaderboard-page-item"
          nextLinkClassName="Leaderboard-page-link"
          breakLabel="..."
          breakClassName="Leaderboard-page-item"
          breakLinkClassName="Leaderboard-page-link"
          containerClassName="Leaderboard-pagination"
          activeClassName="active"
        />
      </div>
    </>
  );
};
