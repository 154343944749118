import React, { useState } from "react";
import { Trans, t } from "@lingui/macro";
import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";
import Vester from "abis/Vester.json";

import { BigNumber, ethers } from "ethers";
import { getPageTitle } from "lib/legacy";

import SEO from "components/Common/SEO";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { callContract } from "lib/contracts";
import { BigZero, formatAmount, formatAmountFree, parseValue } from "lib/numbers";

import "../Stake.css";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { TrackerStakesForUser, UserVestingInfo } from "domain/readerHooks";

interface VesterDepositModalProps {
  tokenToDepositInVester: "lpToken" | "esGmx" | "glp" | undefined;
  setTokenToDepositInVester: React.Dispatch<React.SetStateAction<"lpToken" | "esGmx" | "glp" | undefined>>;
  esGmxBalance: BigNumber | undefined;
  userStakes: TrackerStakesForUser | undefined;
  userVestingInfo: UserVestingInfo | undefined;
  setPendingTxns: any;
}

export default function VesterDepositModal({
  tokenToDepositInVester,
  setTokenToDepositInVester,
  esGmxBalance,
  userStakes,
  userVestingInfo,
  setPendingTxns,
}: VesterDepositModalProps) {
  const { library } = useWeb3React();
  const { chainId } = useChainId();

  const lpTokenVesterAddress = getContract(chainId, "LpTokenVester");
  const esGmxVesterAddress = getContract(chainId, "EsGmxVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  const [isDepositing, setIsDepositing] = useState(false);
  const [value, setValue] = useState<string>("");

  const vesterDepositBalance = esGmxBalance;

  let title: string = "";
  let tokenLabel: string = "";
  let vesterDepositAddress: string = "";

  let vesterDepositMaxAmount: BigNumber | undefined;

  // Tokens that have vested
  let vesterDepositVestedAmount: BigNumber | undefined;
  let vesterDepositAverageStakedAmount: BigNumber | undefined;
  let vesterDepositMaxVestableAmount: BigNumber | undefined;
  // The amount currently reserved for the user
  let vesterDepositReserveAmount: BigNumber | undefined;
  // Max amount that can be reserved. Equals to the tokens staked in tracker
  let maxReserveAmount: BigNumber | undefined;

  switch (tokenToDepositInVester) {
    case "lpToken":
      title = "VMX-FTM LP Vester";
      tokenLabel = "staked VMX-FTM LP + Multiplier Points";
      vesterDepositAddress = lpTokenVesterAddress;

      vesterDepositVestedAmount = userVestingInfo?.lpTokenVester.vestedAmount;
      vesterDepositAverageStakedAmount = userVestingInfo?.lpTokenVester.averageStakedAmount;
      vesterDepositMaxVestableAmount = userVestingInfo?.lpTokenVester.maxVestableAmount;
      vesterDepositReserveAmount = userVestingInfo?.lpTokenVester.pairAmount;

      vesterDepositMaxAmount = userVestingInfo?.lpTokenVester.maxVestableAmount.sub(
        userVestingInfo.lpTokenVester.vestedAmount
      );

      maxReserveAmount = userStakes?.bonusPlusLpToken;

      break;

    case "esGmx":
      title = "esVMX Vester";
      tokenLabel = "esVMX";
      vesterDepositAddress = esGmxVesterAddress;
      vesterDepositVestedAmount = userVestingInfo?.esGmxVester.vestedAmount;

      vesterDepositAverageStakedAmount = userVestingInfo?.esGmxVester.averageStakedAmount;
      vesterDepositMaxVestableAmount = userVestingInfo?.esGmxVester.maxVestableAmount;
      vesterDepositReserveAmount = userVestingInfo?.esGmxVester.pairAmount;

      vesterDepositMaxAmount = userVestingInfo?.esGmxVester.maxVestableAmount.sub(
        userVestingInfo.esGmxVester.vestedAmount
      );
      maxReserveAmount = userStakes?.esGmx;

      break;

    case "glp":
      title = "VLP Vester";
      tokenLabel = "staked VLP";
      vesterDepositAddress = glpVesterAddress;
      vesterDepositVestedAmount = userVestingInfo?.glpVester.vestedAmount;

      vesterDepositAverageStakedAmount = userVestingInfo?.glpVester.averageStakedAmount;
      vesterDepositMaxVestableAmount = userVestingInfo?.glpVester.maxVestableAmount;
      vesterDepositReserveAmount = userVestingInfo?.glpVester.pairAmount;

      vesterDepositMaxAmount = userVestingInfo?.glpVester.maxVestableAmount.sub(userVestingInfo.glpVester.vestedAmount);
      maxReserveAmount = userStakes?.glp;
      break;
  }

  if (vesterDepositMaxAmount !== undefined && esGmxBalance !== undefined && esGmxBalance.lt(vesterDepositMaxAmount)) {
    vesterDepositMaxAmount = esGmxBalance;
  }

  const isVisible = tokenToDepositInVester !== undefined;

  function setIsVisible(visible: boolean) {
    if (!visible) {
      setTokenToDepositInVester(undefined);
    }
  }

  let amount = parseValue(value, 18);

  const nextDepositAmount =
    vesterDepositVestedAmount !== undefined ? vesterDepositVestedAmount.add(amount ?? BigZero) : undefined;

  let nextReserveAmount = vesterDepositReserveAmount;
  let additionalReserveAmount = BigZero;
  if (
    amount &&
    nextDepositAmount &&
    vesterDepositAverageStakedAmount &&
    vesterDepositMaxVestableAmount &&
    vesterDepositMaxVestableAmount.gt(0)
  ) {
    nextReserveAmount = nextDepositAmount.mul(vesterDepositAverageStakedAmount).div(vesterDepositMaxVestableAmount);
    if (vesterDepositReserveAmount && nextReserveAmount.gt(vesterDepositReserveAmount)) {
      additionalReserveAmount = nextReserveAmount.sub(vesterDepositReserveAmount);
    }
  }

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (vesterDepositMaxAmount && amount.gt(vesterDepositMaxAmount)) {
      return t`Max amount exceeded`;
    }
    if (nextReserveAmount && maxReserveAmount && nextReserveAmount.gt(maxReserveAmount)) {
      return t`Insufficient staked tokens`;
    }
  };

  const onClickPrimary = () => {
    setIsDepositing(true);
    const contract = new ethers.Contract(vesterDepositAddress, Vester.abi, library.getSigner());

    callContract(chainId, contract, "deposit", [amount], {
      sentMsg: t`Deposit submitted!`,
      failMsg: t`Deposit failed!`,
      successMsg: t`Deposited!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsDepositing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isDepositing) {
      return t`Depositing...`;
    }
    return t`Deposit`;
  };

  return (
    <SEO title={getPageTitle("Earn")}>
      <div className="StakeModal">
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title} className="non-scrollable">
          <div className="Exchange-swap-section">
            <div className="Exchange-swap-section-top">
              <div className="muted">
                <div className="Exchange-swap-usd">
                  <Trans>Deposit</Trans>
                </div>
              </div>
              <div
                className="muted align-right clickable"
                onClick={() => setValue(formatAmountFree(vesterDepositMaxAmount, 18, 4))}
              >
                <Trans>Max: {formatAmount(vesterDepositMaxAmount, 18, 4, true)}</Trans>
              </div>
            </div>
            <div className="Exchange-swap-section-bottom">
              <div>
                <input
                  type="number"
                  placeholder="0.0"
                  className="Exchange-swap-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="PositionEditor-token-symbol">esVMX</div>
            </div>
          </div>
          <div className="VesterDepositModal-info-rows">
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Wallet</Trans>
              </div>
              <div className="align-right">{formatAmount(vesterDepositBalance, 18, 2, true)} esVMX</div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Vault Capacity</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(nextDepositAmount, 18, 2, true)} / ${formatAmount(
                    vesterDepositMaxVestableAmount,
                    18,
                    2,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div className="Exchange-info-tooltip">
                        <p className="text-white">
                          <Trans>Vault Capacity for your Account:</Trans>
                        </p>
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Deposited`}
                          value={`${formatAmount(vesterDepositVestedAmount, 18, 2, true)} esVMX`}
                        />
                        <StatsTooltipRow
                          showDollar={false}
                          label={t`Max Capacity`}
                          value={`${formatAmount(vesterDepositMaxVestableAmount, 18, 2, true)} esVMX`}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="Exchange-info-row">
              <div className="Exchange-info-label">
                <Trans>Reserve Amount</Trans>
              </div>
              <div className="align-right">
                <Tooltip
                  handle={`${formatAmount(
                    vesterDepositReserveAmount &&
                      additionalReserveAmount &&
                      vesterDepositReserveAmount.gte(additionalReserveAmount)
                      ? vesterDepositReserveAmount
                      : additionalReserveAmount,
                    18,
                    2,
                    true
                  )} / ${formatAmount(maxReserveAmount, 18, 2, true)}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div className="Exchange-info-tooltip">
                        <StatsTooltipRow
                          label={t`Current Reserved`}
                          value={formatAmount(vesterDepositReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        <StatsTooltipRow
                          label={t`Additional reserve required`}
                          value={formatAmount(additionalReserveAmount, 18, 2, true)}
                          showDollar={false}
                        />
                        {amount && nextReserveAmount && maxReserveAmount && nextReserveAmount.gt(maxReserveAmount) && (
                          <>
                            <br />
                            You need a total of at least {formatAmount(nextReserveAmount, 18, 2, true)} {tokenLabel} to
                            vest {formatAmount(amount, 18, 2, true)} esVMX.
                          </>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="StakeV2-button-solid Exchange-swap-button"
              onClick={onClickPrimary}
              disabled={!isPrimaryEnabled()}
            >
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      </div>
    </SEO>
  );
}
