import { TokenInfo } from "domain/tokens";
import { BigNumber } from "ethers";
import { BASIS_POINTS_DIVISOR } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import React, { ReactNode } from "react";
import TooltipComponent from "components/Tooltip/Tooltip";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { Trans, t } from "@lingui/macro";
import { Link } from "react-router-dom";
import ExternalLink from "components/ExternalLink/ExternalLink";
import ArrowIcon from "../../img/arrowRight.svg";

interface WeightTextProps {
  tokenInfo: TokenInfo;
  adjustedUsdgSupply?: BigNumber;
  totalTokenWeights?: BigNumber;
}

export default function WeightText({ tokenInfo, adjustedUsdgSupply, totalTokenWeights }: WeightTextProps) {
  if (
    !tokenInfo.weight ||
    !tokenInfo.usdgAmount ||
    !adjustedUsdgSupply ||
    adjustedUsdgSupply.eq(0) ||
    !totalTokenWeights
  ) {
    return <>...</>;
  }

  const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
  // use add(1).div(10).mul(10) to round numbers up
  const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10);

  const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(targetWeightBps, 2, 2, false)}%`;

  return (
    <TooltipComponent
      handle={weightText}
      position="right-bottom"
      renderContent={() => {
        return (
          <div className="tip__block tip__block_visible">
            <StatsTooltipRow
              label={t`Current Weight`}
              value={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
              showDollar={false}
            />
            <StatsTooltipRow
              label={t`Target Weight`}
              value={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
              showDollar={false}
            />
            <br />
            {currentWeightBps.lt(targetWeightBps) && (
              <div className="text-white dashboard__table_weight-text">
                {tokenInfo.symbol} is below its target weight.
                <br />
                Get lower fees to{" "}
                <Link to="/buy_vlp" target="_blank" className="dashboard__table_weight-link" rel="noopener noreferrer">
                  buy VLP
                </Link>{" "}
                with {tokenInfo.symbol},&nbsp; and to{" "}
                <Link to="/trade" target="_blank" className="dashboard__table_weight-link" rel="noopener noreferrer">
                  swap
                </Link>{" "}
                {tokenInfo.symbol} for other tokens.
              </div>
            )}
            {currentWeightBps.gt(targetWeightBps) && (
              <div className="text-white dashboard__table_weight-text">
                <Trans>
                  {tokenInfo.symbol} is above its target weight.
                  <br />
                  Get lower fees to{" "}
                  <Link to="/trade" target="_blank" className="dashboard__table_weight-link" rel="noopener noreferrer">
                    swap
                  </Link>{" "}
                  tokens for {tokenInfo.symbol}.
                </Trans>
              </div>
            )}
            <br />
            <div>
              <ExternalLink
                className="link__arrow"
                href="https://voodootrade.gitbook.io/voodoo-trade/vlp-liquidity-provision"
              >
                <Trans>More info</Trans>
                <img src={ArrowIcon} alt="arrow" />
              </ExternalLink>
            </div>
          </div>
        );
      }}
    />
  );
}
