import React from "react";

import cx from "classnames";

import style from "./ExchangeListTab.module.css";

export default function ExchangeListTab(props) {
  const { options, option, setOption, onChange, type = "block", className, optionLabels, icons } = props;
  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    // <div className={cx(style["Tab"], style[type], style[className])}>
    <div className={style.TabWrapper}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
        return (
          // <div className={cx(style["Tab-option"], "muted", { [style.active]: opt === option })} onClick={() => onClick(opt)} key={opt}>
          <div
            className={cx(style.TabItem, { [style.activeTabItem]: opt === option })}
            onClick={() => onClick(opt)}
            key={opt}
          >
            {icons && icons[opt] && <img className={style["Tab-option-icon"]} src={icons[opt]} alt={option} />}
            {label}
          </div>
        );
      })}
    </div>
  );
}
