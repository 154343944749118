import { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import useSWR from "swr";
import { BigNumber, BigNumberish } from "ethers";
import { Bar } from "charting_library";

import { USD_DECIMALS, CHART_PERIODS } from "lib/legacy";
import { chainlinkClient, getGmxPricesClient } from "lib/subgraph/clients";
import { formatAmount } from "lib/numbers";
import { getNormalizedTokenSymbol, getTokenBySymbol } from "config/tokens";

export const timezoneOffset = -new Date().getTimezoneOffset() * 60;

interface PriceResponse {
  prices: {
    price: string;
  }[];
}
/**
 * Get the price of ETH, multiplied by 10^30
 */
export function useNativeTokenPriceE30() {
  const assetPair = "ETH/USD";
  const query = gql`
    query nativeTokenPrice($assetPair: String!) {
      prices(first: 1, orderBy: timestamp, orderDirection: desc, where: { assetPair: $assetPair }) {
        price
      }
    }
  `;
  const { data } = useQuery<PriceResponse>(query, {
    variables: { assetPair },
    client: chainlinkClient,
  });
  const price = data?.prices[0].price;

  // GraphQL price is in e8 format. Multiply with 10^22
  const TEN_POW_22 = BigNumber.from(10).pow(22);
  const priceE30 = price !== undefined ? BigNumber.from(price).mul(TEN_POW_22) : undefined;

  return priceE30;
}

interface FastPriceSubgraphCandle {
  timestamp: number;
  open: string;
  high: string;
  low: string;
  close: string;
}

interface FastPriceSubgraphCandles {
  priceCandles0: FastPriceSubgraphCandle[];
  priceCandles1: FastPriceSubgraphCandle[];
  priceCandles2: FastPriceSubgraphCandle[];
  priceCandles3: FastPriceSubgraphCandle[];
  priceCandles4: FastPriceSubgraphCandle[];
  priceCandles5: FastPriceSubgraphCandle[];
}

/**
 * Query fast candles for a token
 *
 * @param chainId
 * @param symbol
 * @param period 15m, 30m etc
 * @param limit
 * @returns
 */
export async function getFastPrices(
  chainId: number,
  symbol: string,
  period: string,
  limit: number = 1000
): Promise<Bar[]> {
  // Subgraph query is case sensitive
  const tokenAddress = getTokenBySymbol(chainId, symbol).address.toLowerCase();

  const query = gql(`{
    priceCandles0: priceCandles(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: {token: "${tokenAddress}", period: "${period}"}
    ) {
      timestamp
      open
      high
      low
      close
    }

    priceCandles1: priceCandles(
      first: 1000
      skip: 1000
      orderBy: timestamp
      orderDirection: desc
      where: {token: "${tokenAddress}", period: "${period}"}
    ) {
      timestamp
      open
      high
      low
      close
    }

    priceCandles2: priceCandles(
      first: 1000
      skip: 2000
      orderBy: timestamp
      orderDirection: desc
      where: {token: "${tokenAddress}", period: "${period}"}
    ) {
      timestamp
      open
      high
      low
      close
    }

    priceCandles3: priceCandles(
      first: 1000
      skip: 3000
      orderBy: timestamp
      orderDirection: desc
      where: {token: "${tokenAddress}", period: "${period}"}
    ) {
      timestamp
      open
      high
      low
      close
    }

    priceCandles4: priceCandles(
      first: 1000
      skip: 4000
      orderBy: timestamp
      orderDirection: desc
      where: {token: "${tokenAddress}", period: "${period}"}
    ) {
      timestamp
      open
      high
      low
      close
    }

    priceCandles5: priceCandles(
      first: 1000
      skip: 5000
      orderBy: timestamp
      orderDirection: desc
      where: {token: "${tokenAddress}", period: "${period}"}
    ) {
      timestamp
      open
      high
      low
      close
    }
  }`);

  const client = getGmxPricesClient(chainId);
  const resp = await client.query<FastPriceSubgraphCandles>({ query });

  const candles = [
    ...resp.data.priceCandles0,
    ...resp.data.priceCandles1,
    ...resp.data.priceCandles2,
    ...resp.data.priceCandles3,
    ...resp.data.priceCandles4,
    ...resp.data.priceCandles5,
  ]
    .map((price) => {
      return {
        time: price.timestamp + timezoneOffset,
        open: Number(price.open) / 1e30,
        close: Number(price.close) / 1e30,
        high: Number(price.high) / 1e30,
        low: Number(price.low) / 1e30,
      };
    })
    .reverse();

  const latestCandle = candles.at(-1);
  if (latestCandle === undefined) {
    throw new Error("No fast candle recorded");
  }

  if (latestCandle.time < 1000 * 60 * 30) {
    throw new Error(
      "chart data is obsolete, last price record at " +
        new Date(latestCandle.time * 1000).toISOString() +
        " now: " +
        new Date().toISOString()
    );
  }

  return candles;
}

interface PriceAtTime {
  timestamp: number;
  price: number;
}

interface PriceAtTimeResp {
  timestamp: string;
  price: string;
}

interface PaginatedPrices {
  prices0: PriceAtTimeResp[];
  prices1: PriceAtTimeResp[];
  prices2: PriceAtTimeResp[];
  prices3: PriceAtTimeResp[];
  prices4: PriceAtTimeResp[];
  prices5: PriceAtTimeResp[];
}

/**
 * Query candle data for a token from Chainlink's subgraph
 *
 * @param tokenSymbol A supported token
 * @param period
 * @returns candles
 */
export async function getChainlinkChartPricesFromGraph(tokenSymbol: string, period: string): Promise<Bar[]> {
  const normalizedSymbol = getNormalizedTokenSymbol(tokenSymbol);
  const feedId = `${normalizedSymbol}/USD`;

  const PER_CHUNK = 1000;

  const query = gql(`{
    prices0: prices(
      first: ${PER_CHUNK},
      skip: ${0 * PER_CHUNK},
      orderBy: timestamp,
      orderDirection: desc,
      where: {assetPair: "${feedId}"}
    ) {
      timestamp,
      price
    }
    prices1: prices(
      first: ${PER_CHUNK},
      skip: ${1 * PER_CHUNK},
      orderBy: timestamp,
      orderDirection: desc,
      where: {assetPair: "${feedId}"}
    ) {
      timestamp,
      price
    }
    prices2: prices(
      first: ${PER_CHUNK},
      skip: ${2 * PER_CHUNK},
      orderBy: timestamp,
      orderDirection: desc,
      where: {assetPair: "${feedId}"}
    ) {
      timestamp,
      price
    }
    prices3: prices(
      first: ${PER_CHUNK},
      skip: ${3 * PER_CHUNK},
      orderBy: timestamp,
      orderDirection: desc,
      where: {assetPair: "${feedId}"}
    ) {
      timestamp,
      price
    }
    prices4: prices(
      first: ${PER_CHUNK},
      skip: ${4 * PER_CHUNK},
      orderBy: timestamp,
      orderDirection: desc,
      where: {assetPair: "${feedId}"}
    ) {
      timestamp,
      price
    }
    prices5: prices(
      first: ${PER_CHUNK},
      skip: ${5 * PER_CHUNK},
      orderBy: timestamp,
      orderDirection: desc,
      where: {assetPair: "${feedId}"}
    ) {
      timestamp,
      price
    }
  }`);

  const resp = await chainlinkClient.query<PaginatedPrices>({ query });
  const prices = [
    ...resp.data.prices0,
    ...resp.data.prices1,
    ...resp.data.prices2,
    ...resp.data.prices3,
    ...resp.data.prices4,
    ...resp.data.prices5,
  ]
    .map((priceResp) => {
      return {
        timestamp: Number(priceResp.timestamp),
        price: Number(priceResp.price) / 100000000,
      };
    })
    .reverse();

  return getCandlesFromPrices(prices, period);
}

/**
 * Generate candles from an array of prices for a given period
 * @param prices Array of prices and timestamp in ascending order of time
 * @param period Period in [5m, 15m, 1h, 4h, 1d]
 * @returns
 */
function getCandlesFromPrices(prices: PriceAtTime[], period: string): Bar[] {
  const periodTime = CHART_PERIODS[period];

  if (prices.length < 2) {
    return [];
  }

  const candles: Bar[] = [];
  const first = prices[0];
  let prevTsGroup = Math.floor(first.timestamp / periodTime) * periodTime;
  let prevPrice = first.price;
  let open = prevPrice;
  let high = prevPrice;
  let low = prevPrice;
  let close = prevPrice;

  for (let i = 1; i < prices.length; i++) {
    const { timestamp: ts, price } = prices[i];
    const tsGroup = Math.floor(ts / periodTime) * periodTime;
    if (prevTsGroup !== tsGroup) {
      candles.push({ time: prevTsGroup + timezoneOffset, open, high, low, close });
      open = close;
      high = Math.max(open, close);
      low = Math.min(open, close);
    }
    close = price;
    high = Math.max(high, price);
    low = Math.min(low, price);
    prevTsGroup = tsGroup;
  }

  return candles;
}

export function useChartPrices(
  chainId: number,
  symbol: string,
  isStable: boolean,
  period: string,
  currentAveragePrice: number
) {
  const swrKey = !isStable && symbol ? ["getChartCandles", chainId, symbol, period] : null;
  let { data: prices, mutate: updatePrices } = useSWR(swrKey, {
    fetcher: async (...args) => {
      try {
        return await getFastPrices(chainId, symbol, period);
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn(ex);
        // eslint-disable-next-line no-console
        console.warn("Switching to graph chainlink data");
        try {
          return getChainlinkChartPricesFromGraph(symbol, period);
        } catch (ex2) {
          // eslint-disable-next-line no-console
          console.warn("getChainlinkChartPricesFromGraph failed");
          // eslint-disable-next-line no-console
          console.warn(ex2);
          return [];
        }
      }
    },
    dedupingInterval: 60000,
    focusThrottleInterval: 60000 * 10,
  });

  const currentAveragePriceString = currentAveragePrice && currentAveragePrice.toString();
  const retPrices = useMemo(() => {
    if (isStable) {
      return getStablePriceData(period);
    }

    if (!prices) {
      return [];
    }

    let _prices = [...prices];
    if (currentAveragePriceString && prices.length) {
      _prices = appendCurrentAveragePrice(_prices, BigNumber.from(currentAveragePriceString), period);
    }

    return fillGaps(_prices, CHART_PERIODS[period]);
  }, [prices, isStable, currentAveragePriceString, period]);

  return [retPrices, updatePrices];
}

export function fillGaps(prices, periodSeconds) {
  if (prices.length < 2) {
    return prices;
  }

  const newPrices = [prices[0]];
  let prevTime = prices[0].time;
  for (let i = 1; i < prices.length; i++) {
    const { time, open } = prices[i];
    if (prevTime) {
      let j = (time - prevTime) / periodSeconds - 1;
      while (j > 0) {
        newPrices.push({
          time: time - j * periodSeconds,
          open,
          close: open,
          high: open * 1.0003,
          low: open * 0.9996,
        });
        j--;
      }
    }

    prevTime = time;
    newPrices.push(prices[i]);
  }

  return newPrices;
}

function appendCurrentAveragePrice(prices: Bar[], currentAveragePrice: BigNumberish, period: string): Bar[] {
  const periodSeconds = CHART_PERIODS[period];
  const currentCandleTime = Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds + timezoneOffset;
  const last = prices[prices.length - 1];
  const averagePriceValue = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2));
  if (currentCandleTime === last.time) {
    last.close = averagePriceValue;
    last.high = Math.max(last.high, averagePriceValue);
    last.low = Math.max(last.low, averagePriceValue);
    return prices;
  } else {
    const newCandle = {
      time: currentCandleTime,
      open: last.close,
      close: averagePriceValue,
      high: averagePriceValue,
      low: averagePriceValue,
    };
    return [...prices, newCandle];
  }
}

export function getStablePriceData(period: string, countBack = 100): Bar[] {
  const periodSeconds = CHART_PERIODS[period];
  const now = Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds;
  let priceData: Bar[] = [];
  for (let i = countBack; i > 0; i--) {
    priceData.push({
      time: now - i * periodSeconds,
      open: 1,
      close: 1,
      high: 1,
      low: 1,
    });
  }
  return priceData;
}
