import React from "react";

import "./Card.scss";

function Card({ title, text }) {
  return (
    <div className="card__wrapper">
      <div className="card__title">{title}</div>
      <div className="card__text">{text}</div>
    </div>
  );
}

export default Card;
