import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import Footer from "components/Footer/Footer";
import { t } from "@lingui/macro";
import { helperToast } from "lib/helperToast";
import verifiedIcon from "img/ic_verified.svg";
import TelegramLoginButton from "telegram-login-button";
import "./AirdropPage.css";
import { useWeb3React } from "@web3-react/core";
import { Signer } from "ethers";
import useLinkedUser from "./useLinkedUser";
import { useToggle } from "usehooks-ts";
import VoodooGirlImage from "../../img/VoodooGirlImage.png";
import Card from "./components/Card/Card";
import { useHistory, useLocation } from "react-router-dom";
import { DiscordLoginButton } from "react-social-login-buttons";
import { useState } from "react";
import { LinkUserPayload, LinkedData, TelegramUser } from "./LinkAccountTypes";
import linkUser from "./linkUser";
import useDiscordAccessToken from "./useDiscordAccessToken";
import AirdropTable from "./components/AirdropTable/AirdropTable";

const firstRow = [
  {
    id: 1,
    title: "Step One",
    text: "Connect your crypto wallet.",
  },
  {
    id: 2,
    title: "Step Two",
    text: "Sign in with Discord.",
  },
  {
    id: 3,
    title: "Step Three",
    text: "Sign in with Telegram.",
  },
];
const secondRow = [
  {
    id: 1,
    title: "Step Four",
    text: "Sign the auth message using your crypto wallet. This costs no gas.",
  },
  // {
  //   id: 1,
  //   title: "Step Four",
  //   text: "Start margin trading on Mainnet. You must margin trade; spot does not count.",
  // },
  // {
  //   id: 2,
  //   title: "Step Five",
  //   text: "Run /leaderboard and /me commands in @voodootradeofficial Telegram to check your stats.",
  // },
  // {
  //   id: 3,
  //   title: "Step Six",
  //   text: `Traders are judged by "realized PnL". You must close your position for PnL to count.`,
  // },
];

interface AirdropPageProps {
  connectWallet: any;
}

const COMPETITION_START = 1700463194 * 1000;
// const COMPETITION_END = 1701374399 * 1000;

export default function AirdropPage({ connectWallet }: AirdropPageProps) {
  const { account, library } = useWeb3React();
  const location = useLocation();
  const history = useHistory();

  const discordAccessToken = useDiscordAccessToken(location.search);

  const botName = "voodoo_trade_bot";
  const linkUrl = "https://api.voodoo.trade/airdrop/user";

  const [refresh, toggleRefresh] = useToggle();
  const linkedUser = useLinkedUser(linkUrl, account, refresh);

  const [telegramUser, setTelegramUser] = useState<TelegramUser | undefined>();

  // Redirects to Discord Oauth page. Discord returns the user back to https://app.voodoo.trade
  async function handleDiscordLogin() {
    const DISCORD_CLIENT_ID = "1184120678320963636";
    const REDIRECT_URI = "https%3A%2F%2Fapp.voodoo.trade";
    const url = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=token&scope=identify`;

    window.location.href = url;
  }

  function handleTelegramLogin(telegramUser: TelegramUser) {
    setTelegramUser(telegramUser);
  }

  async function handleSignMessage() {
    try {
      if (account == null) {
        throw Error("No wallet connected");
      }

      if (library == null) {
        throw Error("No library");
      }

      const signer: Signer | null | undefined = library.getSigner(account);
      if (signer == null) {
        throw Error("No signer");
      }

      if (discordAccessToken === undefined) {
        throw Error("No discordAccessToken");
      }

      if (telegramUser === undefined) {
        throw Error("No telegramUser");
      }

      const linkedData: LinkedData = {
        discordAccessToken,
        telegramUser,
      };

      const walletSignature = await signer.signMessage(JSON.stringify(linkedData));

      const payload: LinkUserPayload = {
        account,
        walletSignature,
        linkedData,
      };

      console.log("payload", payload);

      const resp = await linkUser(linkUrl, payload);
      if (resp.ok) {
        toggleRefresh();
        helperToast.success(t`Telegram linked!`);
      } else {
        throw Error(await resp.text());
      }
    } catch (error) {
      helperToast.error(t`Linked failed: ${(error as Error).message}`);
    }
  }

  async function handleTradeNowButton() {
    history.push("/trade");
  }

  return (
    <SEO title={getPageTitle("Telegram")}>
      <div className="TelegramPage__wrapper">
        <div className="TelegramPage">
          <div className="topBlock__wrapper">
            <div className="topBlock__left">
              <div className="topBlock__left_title">Voodoo Airdrop</div>
              <div className="topBlock__left_subtitle">
                Take part in the largest airdrop on Base with <span>$250k in rewards</span>
              </div>

              {account == null ? (
                <div className="button__wrapper">
                  <div className="step">Step 1</div>
                  <button className="StakeV2-button-solid App-card-option" onClick={connectWallet}>
                    Connect Wallet
                  </button>
                </div>
              ) : linkedUser !== undefined ? (
                <div className="Telegram-linked-container">
                  <div className="Telegram-linked-text-container">
                    <img className="Telegram-verified-icon" src={verifiedIcon} alt="Success" />
                    <p className="Telegram-linked-text">You are registered</p>
                  </div>
                  <button className="StakeV2-button-solid App-card-option" onClick={handleTradeNowButton}>
                    Trade now
                  </button>
                </div>
              ) : discordAccessToken === undefined ? (
                <div className="button__wrapper">
                  <div className="step">Step 2</div>
                  <DiscordLoginButton onClick={handleDiscordLogin} />
                </div>
              ) : telegramUser === undefined ? (
                <div className="button__wrapper">
                  <div className="step">Step 3</div>
                  <TelegramLoginButton botName={botName} usePic={true} dataOnauth={handleTelegramLogin} />
                </div>
              ) : (
                <div className="button__wrapper">
                  <div className="step">Step 4</div>
                  <button className="StakeV2-button-solid App-card-option" onClick={handleSignMessage}>
                    Sign message
                  </button>
                </div>
              )}
            </div>
            <div className="topBlock__right">
              <img src={VoodooGirlImage} alt="VoodooGirlImage" className="topBlock__right_image" />
            </div>
          </div>
          <AirdropTable airdrop1={linkedUser?.airdrop1} />
          <div className="bottomBlock__wrapper">
            <div className="bottomBlock__title">How To Participate</div>
            <div className="cards">
              <div className="cards__row">
                {firstRow?.map((card) => {
                  const { id, title, text } = card;
                  return <Card key={id} title={title} text={text} />;
                })}
              </div>
              <div className="cards__row">
                {secondRow?.map((card) => {
                  const { id, title, text } = card;
                  return <Card key={id} title={title} text={text} />;
                })}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </SEO>
  );
}
