import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import Footer from "components/Footer/Footer";
import TradersSearchImage from "img/TradersSearchImage.svg";
import ClearButtonImage from "img/clearButtonImage.svg";
import esVMXTokenImage from "img/esVMXToken.png";

import starsImg from "img/Stars.svg";
import potImg from "img/Pot.svg";
import skeletonImg from "img/Skeleton.svg";

import "./EsGmxBalanceViewer.css";
import { useBalanceOf } from "domain/gmxHooks";
import { BigNumber } from "ethers";

export default function EsGmxBalanceViewer() {
  const { account } = useWeb3React();

  const [searchAddress, setSearchAddress] = useState<string | null>(null);
  const [searchField, setSearchField] = useState<string | null>(null);

  const balance = useBalanceOf("ES_GMX", searchAddress);
  const TEN_POW_18 = BigNumber.from(10).pow(18);
  const balanceFormatted = balance?.div(TEN_POW_18).toNumber().toLocaleString();

  useEffect(() => {
    if (account !== undefined) {
      setSearchAddress(account);
    } else {
      setSearchAddress(null);
    }
  }, [account]);

  const searchOnClick = () => {
    setSearchAddress(searchField === "" ? null : searchField);
  };

  const clearOnClick = () => {
    setSearchField(null);
  };

  const handleSearchField = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchField(value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchOnClick();
    }
  };

  return (
    <SEO title={getPageTitle("EsVMX")}>
      <div className="background__wrapper">
        <div className="backgroundStars">
          <img src={starsImg} alt="stars" className="backgroundStars__image" />
        </div>
        <div className="backgroundPot">
          <img src={potImg} alt="pot" className="backgroundPot__image" />
        </div>
        <div className="backgroundSkeleton">
          <img src={skeletonImg} alt="pot" className="backgroundSkeleton__image" />
        </div>
      </div>
      <div className="Leaderboard page-layout z-index-100">
        <div className="EsGmxBalanceViewer-container default-container">
          {/* Title */}
          <div className="EsGmxBalanceViewer-title-block-wrapper">
            <div className="EsGmxBalanceViewer-title-wrapper">
              <div className="EsGmxBalanceViewer-title">Check EsVMX</div>
            </div>
            <div className="EsGmxBalanceViewer-title-search-block-wrapper">
              {account != null && (
                <button
                  className="EsGmxBalanceViewer-button-outline"
                  onClick={() => {
                    if (account) {
                      setSearchAddress(account);
                    }
                  }}
                >
                  my balance
                </button>
              )}

              <div className="EsGmxBalanceViewer-title-search-wrapper">
                <img
                  src={TradersSearchImage}
                  alt="TradersSearchImage"
                  className="EsGmxBalanceViewer-title-search-image"
                  onClick={searchOnClick}
                />
                <img
                  src={ClearButtonImage}
                  alt="ClearButtonImage"
                  className="EsGmxBalanceViewer-title-clear-image"
                  onClick={clearOnClick}
                />
                <input
                  type="text"
                  className="EsGmxBalanceViewer-title-search"
                  placeholder="Enter or paste address"
                  value={searchField || ""}
                  onChange={handleSearchField}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Stats-container">
          {balanceFormatted !== undefined ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={esVMXTokenImage} height={70} />
              <p className="Balance-text">{balanceFormatted} esVMX</p>
            </div>
          ) : (
            <p className="Connect-request-text">Connnect wallet or search for an address</p>
          )}
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
