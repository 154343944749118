import { useEffect, useRef, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { toJpeg } from "html-to-image";
import cx from "classnames";
import { BiCopy } from "react-icons/bi";
import { RiFileDownloadLine } from "react-icons/ri";
import { FiTwitter } from "react-icons/fi";
import { useCopyToClipboard, useMedia } from "react-use";
import Modal from "../Modal/Modal";
import VoodooLogo from "img/VMXLogo.svg";
import "./PositionShare.css";
import { QRCodeSVG } from "qrcode.react";
import { getHomeUrl, getTwitterIntentURL, USD_DECIMALS } from "lib/legacy";
import { AffiliateCode, useAffiliateCode } from "domain/referrals";
import SpinningLoader from "../Common/SpinningLoader";
import useLoadImage from "lib/useLoadImage";
import shareBgImg from "img/position-share-bg.png";
import { helperToast } from "lib/helperToast";
import { formatAmount } from "lib/numbers";
import downloadImage from "lib/downloadImage";

import ImageSkill from "img/image-skill.svg";
import { getServerBaseUrl } from "config/backend";

const config = { quality: 0.95, canvasWidth: 518, canvasHeight: 292, type: "image/jpeg" };

interface UploadResponse {
  image: string;
  version: number;
  id: string;
}

function getShareURL(chainId: number, imageInfo: UploadResponse | undefined, ref: AffiliateCode) {
  const UPLOAD_SHARE = getServerBaseUrl(chainId) + "/share";

  if (!imageInfo) return;
  let url = `${UPLOAD_SHARE}?id=${imageInfo.id}`;
  if (ref.success && ref.code) {
    url = url + `&ref=${ref.code}`;
  }
  return url;
}

function PositionShare({ setIsPositionShareModalOpen, isPositionShareModalOpen, positionToShare, account, chainId }) {
  const affiliateCode = useAffiliateCode(chainId, account);
  const [uploadedImageInfo, setUploadedImageInfo] = useState<UploadResponse>();
  const [uploadedImageError, setUploadedImageError] = useState<string>();
  const [, copyToClipboard] = useCopyToClipboard();
  const sharePositionBgImg = useLoadImage(shareBgImg);
  const positionRef = useRef();

  const UPLOAD_URL = getServerBaseUrl(chainId) + "/upload";

  // TODO update, competition has ended
  const tweetLink = getTwitterIntentURL(
    `I'm competing for $2000 of rewards in the @Tradevoodoo Incentivised Testnet.\n\nFull details to join and earn rewards are here:`,
    getShareURL(chainId, uploadedImageInfo, affiliateCode),
  );

  useEffect(() => {
    (async function () {
      const element = positionRef.current;
      if (element && affiliateCode.success && sharePositionBgImg && positionToShare) {
        // We have to call the toJpeg function multiple times to make sure the canvas renders all the elements like background image
        // @refer https://github.com/tsayen/dom-to-image/issues/343#issuecomment-652831863
        const image = await toJpeg(element, config)
          .then(() => toJpeg(element, config))
          .then(() => toJpeg(element, config));
        try {
          const imageInfo: UploadResponse = await fetch(UPLOAD_URL, { method: "POST", body: image }).then((res) =>
            res.json()
          );
          setUploadedImageInfo(imageInfo);
        } catch {
          setUploadedImageInfo(undefined);
          setUploadedImageError(t`Image generation error, please refresh and try again.`);
        }
      }
    })();
  }, [affiliateCode, sharePositionBgImg, positionToShare]);

  async function handleDownload() {
    const element = positionRef.current;
    if (!element) return;
    const imgBlob = await toJpeg(element, config)
      .then(() => toJpeg(element, config))
      .then(() => toJpeg(element, config));
    downloadImage(imgBlob, "share.jpeg");
  }

  function handleCopy() {
    if (!uploadedImageInfo) return;
    const url = getShareURL(chainId, uploadedImageInfo, affiliateCode);

    if (url !== undefined) {
      copyToClipboard(url);
      helperToast.success(t`Link copied to clipboard.`);
    } else {
      helperToast.error(t`Failed to generate URL.`);
    }
  }
  return (
    <Modal
      className="position-share-modal"
      isVisible={isPositionShareModalOpen}
      setIsVisible={setIsPositionShareModalOpen}
      label={t`Share Position`}
    >
      <PositionShareCard
        userAffiliateCode={affiliateCode}
        positionRef={positionRef}
        position={positionToShare}
        uploadedImageInfo={uploadedImageInfo}
        uploadedImageError={uploadedImageError}
      />
      {uploadedImageError && <span className="error">{uploadedImageError}</span>}

      <div className="actions">
        <button disabled={!uploadedImageInfo} className="mr-base App-button-option" onClick={handleCopy}>
          <BiCopy className="icon" />
          <Trans>Copy</Trans>
        </button>
        <button disabled={!uploadedImageInfo} className="mr-base App-button-option" onClick={handleDownload}>
          <RiFileDownloadLine className="icon" />
          <Trans>Download</Trans>
        </button>
        <div className={cx("tweet-link-container", { disabled: !uploadedImageInfo })}>
          <a
            target="_blank"
            className={cx("tweet-link App-button-option", { disabled: !uploadedImageInfo })}
            rel="noreferrer"
            href={tweetLink}
          >
            <FiTwitter className="icon" />
            <Trans>Tweet</Trans>
          </a>
        </div>
      </div>
    </Modal>
  );
}

function PositionShareCard({ positionRef, position, userAffiliateCode, uploadedImageInfo, uploadedImageError }) {
  const isMobile = useMedia("(max-width: 400px)");
  const { code, success } = userAffiliateCode;
  const { deltaAfterFeesPercentageStr, isLong, leverage, indexToken, averagePrice, markPrice } = position;

  const homeURL = getHomeUrl();
  return (
    <div className="relative">
      <div ref={positionRef} className="position-share">
        <img className="logo" src={VoodooLogo} alt="Voodoo Logo" />

        <ul className="info">
          <li className="side">{isLong ? "Long" : "Short"}</li>
          <li className="PositionShare-info-row">{formatAmount(leverage, 4, 2, true)}x&nbsp;</li>
          <li className="PositionShare-info-row">{indexToken.symbol} / USD</li>
        </ul>
        <h3 className="pnl">{deltaAfterFeesPercentageStr}</h3>
        <div className="prices">
          <div>
            <p>Entry Price</p>
            <p className="price">${formatAmount(averagePrice, USD_DECIMALS, 4, true)}</p>
          </div>
          <div>
            <p>Index Price</p>
            <p className="price">${formatAmount(markPrice, USD_DECIMALS, 4, true)}</p>
          </div>
        </div>
        <div className="referral-code-wrapper">
          <div className="referral-code-qrCode">
            <QRCodeSVG size={isMobile ? 32 : 42} value={success && code ? `${homeURL}/#/?ref=${code}` : `${homeURL}`} />
          </div>
          <div className="referral-code">
            <div className="referral-code-info">
              {success && code ? (
                <>
                  <p className="label">Referral Code:</p>
                  <p className="code">{code}</p>
                </>
              ) : (
                <p className="code">voodoo.trade</p>
              )}
            </div>
          </div>
        </div>

        <div className="position-share-bgImage-wrapper">
          <img src={ImageSkill} alt="ImageSkill" className="position-share-bgImage" />
        </div>
        <div className="position-share-bgImage-circle"></div>
      </div>

      {!uploadedImageInfo && !uploadedImageError && (
        <div className="image-overlay-wrapper">
          <div className="image-overlay">
            <SpinningLoader />
            <p className="loading-text">
              <Trans>Generating shareable image...</Trans>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PositionShare;
