import React from "react";

interface CustomTooltipProps {
  active?: any;
  payload?: any;
}

export default function CustomTooltip({ active, payload }: CustomTooltipProps) {
  if (active && payload && payload.length) {
    return (
      <div className="stats-label">
        <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
        {payload[0].value}% {payload[0].name}
      </div>
    );
  }

  return null;
}
