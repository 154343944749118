import React from "react";

interface ArrowUpIconProps {
  color?: string;
}

export const ArrowUpIcon = (props: ArrowUpIconProps) => {
  const { color = "#fefdff" } = props;
  return (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.83337 6.00004L6.00004 1.83337L10.1667 6.00004"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
