import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import { useChainId } from "lib/chains";
import { contractFetcher } from "lib/contracts";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import useSWR from "swr";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import { getContract } from "config/contracts";
import { BigZero } from "lib/numbers";

export interface PlatformTokens {
  lpToken: BigNumber;
  gmx: BigNumber;
  esGmx: BigNumber;
  glp: BigNumber;
}
/**
 * Return lpToken GMX, esGMX and GLP balances for a wallet and total supplies
 *
 * @param account Lookup balances for this wallet. If no wallet is provided
 * a random account with no balances is used
 * @returns
 */
export function useBalancesAndSupplies(): {
  balanceData: PlatformTokens | undefined;
  supplyData: PlatformTokens | undefined;
} {
  const { active, account } = useWeb3React();
  const { chainId } = useChainId();

  const readerAddress = getContract(chainId, "Reader");

  const gmxAddress = getContract(chainId, "GMX");
  const esGmxAddress = getContract(chainId, "ES_GMX");

  const stakedGlpTracker = getContract(chainId, "StakedGlpTracker");
  const lpTokenAddress = getContract(chainId, "lpToken");

  const walletTokens = [gmxAddress, esGmxAddress, stakedGlpTracker, lpTokenAddress];

  const { data: balancesAndSupplies } = useSWR<BigNumber[]>(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account ?? PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [walletTokens]),
    }
  );

  if (balancesAndSupplies === undefined || balancesAndSupplies.length < 8) {
    return {
      balanceData: undefined,
      supplyData: undefined,
    };
  }

  const balanceData = {
    gmx: balancesAndSupplies[0],
    esGmx: balancesAndSupplies[2],
    glp: balancesAndSupplies[4],
    lpToken: balancesAndSupplies[6],
  };

  const supplyData = {
    gmx: balancesAndSupplies[1],
    esGmx: balancesAndSupplies[3],
    glp: balancesAndSupplies[5],
    lpToken: balancesAndSupplies[7],
  };

  return { balanceData, supplyData };
}

export interface TrackerState {
  // Tokens claimable by user. Other stats are global
  claimable: BigNumber;
  tokensPerInterval: BigNumber;
  averageStakedAmounts: BigNumber;
  cumulativeRewards: BigNumber;
  totalSupply: BigNumber;
}

export interface TrackerStates {
  stakedLpTokenTracker: TrackerState;
  bonusLpTokenTracker: TrackerState;
  feeLpTokenTracker: TrackerState;
  stakedEsGmxTracker: TrackerState;
  feeEsGmxTracker: TrackerState;
  stakedGlpTracker: TrackerState;
  feeGlpTracker: TrackerState;
}

/**
 * Get claimable reward, tokens per interval, average staking amounts, cumulative rewards
 * and total supplies of each tracker
 *
 * @returns
 */
export function useTrackerData(): TrackerStates | undefined {
  const { active, account } = useWeb3React();
  const { chainId } = useChainId();

  const rewardReaderAddress = getContract(chainId, "RewardReader");

  const stakedLpTokenTracker = getContract(chainId, "StakedLpTokenTracker");
  const bonusLpTokenTracker = getContract(chainId, "BonusLpTokenTracker");
  const feeLpTokenTracker = getContract(chainId, "FeeLpTokenTracker");

  const stakedEsGmxTracker = getContract(chainId, "StakedEsGmxTracker");
  const feeEsGmxTracker = getContract(chainId, "FeeEsGmxTracker");

  const stakedGlpTracker = getContract(chainId, "StakedGlpTracker");
  const feeGlpTracker = getContract(chainId, "FeeGlpTracker");

  const { data: stakingInfo } = useSWR<BigNumber[]>(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account ?? PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [
        [
          stakedLpTokenTracker,
          bonusLpTokenTracker,
          feeLpTokenTracker,

          stakedEsGmxTracker,
          feeEsGmxTracker,

          stakedGlpTracker,
          feeGlpTracker,
        ],
      ]),
    }
  );

  if (!stakingInfo || stakingInfo.length < 35) {
    return undefined;
  }

  return {
    stakedLpTokenTracker: {
      claimable: stakingInfo[0],
      tokensPerInterval: stakingInfo[1],
      averageStakedAmounts: stakingInfo[2],
      cumulativeRewards: stakingInfo[3],
      totalSupply: stakingInfo[4],
    },
    bonusLpTokenTracker: {
      claimable: stakingInfo[5],
      tokensPerInterval: stakingInfo[6],
      averageStakedAmounts: stakingInfo[7],
      cumulativeRewards: stakingInfo[8],
      totalSupply: stakingInfo[9],
    },
    feeLpTokenTracker: {
      claimable: stakingInfo[10],
      tokensPerInterval: stakingInfo[11],
      averageStakedAmounts: stakingInfo[12],
      cumulativeRewards: stakingInfo[13],
      totalSupply: stakingInfo[14],
    },
    stakedEsGmxTracker: {
      claimable: stakingInfo[15],
      tokensPerInterval: stakingInfo[16],
      averageStakedAmounts: stakingInfo[17],
      cumulativeRewards: stakingInfo[18],
      totalSupply: stakingInfo[19],
    },
    feeEsGmxTracker: {
      claimable: stakingInfo[20],
      tokensPerInterval: stakingInfo[21],
      averageStakedAmounts: stakingInfo[22],
      cumulativeRewards: stakingInfo[23],
      totalSupply: stakingInfo[24],
    },
    stakedGlpTracker: {
      claimable: stakingInfo[25],
      tokensPerInterval: stakingInfo[26],
      averageStakedAmounts: stakingInfo[27],
      cumulativeRewards: stakingInfo[28],
      totalSupply: stakingInfo[29],
    },
    feeGlpTracker: {
      claimable: stakingInfo[30],
      tokensPerInterval: stakingInfo[31],
      averageStakedAmounts: stakingInfo[32],
      cumulativeRewards: stakingInfo[33],
      totalSupply: stakingInfo[34],
    },
  };
}

export interface TrackerStakesForUser {
  // LP tokens staked. Equals stake in stakedLpTokenTracker and bonusLpTokenTracker.
  lpToken: BigNumber;

  // Bonus tokens staked in FeeLpTokenTracker
  bonusLpToken: BigNumber;

  // Total tokens staked in FeeLpTokenTracker, i.e. sum of stakedLpToken and stakedBonusLpToken
  bonusPlusLpToken: BigNumber;

  // Equals stake in stakedEsGmxTracker and feeEsGmxTracker
  esGmx: BigNumber;

  // Equals stake in stakedGlpTracker and feeGlpTracker
  glp: BigNumber;
}

/**
 * Read the token balances for the user in each reward tracker
 *
 */
export function useTrackerStakesForUser(): TrackerStakesForUser | undefined {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const rewardReaderAddress = getContract(chainId, "RewardReader");

  const lpToken = getContract(chainId, "lpToken");
  const bnLpToken = getContract(chainId, "BN_LP_TOKEN");
  const esGmx = getContract(chainId, "ES_GMX");
  const feeGlpTracker = getContract(chainId, "FeeGlpTracker");

  const stakedLpTokenTracker = getContract(chainId, "StakedLpTokenTracker");
  const feeLpTokenTracker = getContract(chainId, "FeeLpTokenTracker");
  const stakedEsGmxTracker = getContract(chainId, "StakedEsGmxTracker");
  const stakedGlpTracker = getContract(chainId, "StakedGlpTracker");

  const depositTokens = [lpToken, bnLpToken, esGmx, feeGlpTracker];

  const rewardTrackersForDepositBalances = [
    stakedLpTokenTracker,
    feeLpTokenTracker,
    stakedEsGmxTracker,
    stakedGlpTracker,
  ];

  // Get tokens staked in each reward tracker
  const { data: depositBalances } = useSWR<BigNumber[]>(
    account != null
      ? [`StakeV2:depositBalances:${active}`, chainId, rewardReaderAddress, "getDepositBalances", account]
      : null,
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  if (account == null || !depositBalances || depositBalances.length < 4) {
    return {
      lpToken: BigZero,
      bonusLpToken: BigZero,
      bonusPlusLpToken: BigZero,
      esGmx: BigZero,
      glp: BigZero,
    };
  }

  return {
    lpToken: depositBalances[0],
    bonusLpToken: depositBalances[1],
    bonusPlusLpToken: depositBalances[0].add(depositBalances[1]),
    esGmx: depositBalances[2],
    glp: depositBalances[3],
  };
}

export interface VestingInfo {
  // The amount of tokens reserved for 'account'
  pairAmount: BigNumber;

  // The amount vested to 'account'
  vestedAmount: BigNumber;

  // The amount of tokens escrowed for vesting
  escrowedBalance: BigNumber;

  // The vested amount claimed
  claimedAmount: BigNumber;

  // The vested amount claimable
  claimable: BigNumber;

  // The max amount vestable based on 'pairAmount' reserved
  maxVestableAmount: BigNumber;

  // The average staked based on past withdrawals
  averageStakedAmount: BigNumber;

  // Sum of claimedAmounts and claimable
  claimSum: BigNumber;
}

export interface UserVestingInfo {
  lpTokenVester: VestingInfo;
  esGmxVester: VestingInfo;
  glpVester: VestingInfo;
}

/**
 * Read state of lpToken, esGMX and GLP vesters for the user
 *
 * @returns
 */
export function useUserVestingInfo(): UserVestingInfo | undefined {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const readerAddress = getContract(chainId, "Reader");

  const lpTokenVester = getContract(chainId, "LpTokenVester");
  const esGmxVesterAddress = getContract(chainId, "EsGmxVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");
  const vesterAddresses = [lpTokenVester, esGmxVesterAddress, glpVesterAddress];

  const { data: vestingInfo } = useSWR<BigNumber[]>(
    account != null ? [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account] : null,
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  if (account == null || !vestingInfo || vestingInfo.length < 21) {
    return {
      lpTokenVester: {
        pairAmount: BigZero,
        vestedAmount: BigZero,
        escrowedBalance: BigZero,
        claimedAmount: BigZero,
        claimable: BigZero,
        maxVestableAmount: BigZero,
        averageStakedAmount: BigZero,
        claimSum: BigZero,
      },
      esGmxVester: {
        pairAmount: BigZero,
        vestedAmount: BigZero,
        escrowedBalance: BigZero,
        claimedAmount: BigZero,
        claimable: BigZero,
        maxVestableAmount: BigZero,
        averageStakedAmount: BigZero,
        claimSum: BigZero,
      },
      glpVester: {
        pairAmount: BigZero,
        vestedAmount: BigZero,
        escrowedBalance: BigZero,
        claimedAmount: BigZero,
        claimable: BigZero,
        maxVestableAmount: BigZero,
        averageStakedAmount: BigZero,
        claimSum: BigZero,
      },
    };
  }

  return {
    lpTokenVester: {
      pairAmount: vestingInfo[0],
      vestedAmount: vestingInfo[1],
      escrowedBalance: vestingInfo[2],
      claimedAmount: vestingInfo[3],
      claimable: vestingInfo[4],
      maxVestableAmount: vestingInfo[5],
      averageStakedAmount: vestingInfo[6],
      claimSum: vestingInfo[3].add(vestingInfo[4]),
    },
    esGmxVester: {
      pairAmount: vestingInfo[7],
      vestedAmount: vestingInfo[8],
      escrowedBalance: vestingInfo[9],
      claimedAmount: vestingInfo[10],
      claimable: vestingInfo[11],
      maxVestableAmount: vestingInfo[12],
      averageStakedAmount: vestingInfo[13],
      claimSum: vestingInfo[10].add(vestingInfo[11]),
    },
    glpVester: {
      pairAmount: vestingInfo[14],
      vestedAmount: vestingInfo[15],
      escrowedBalance: vestingInfo[16],
      claimedAmount: vestingInfo[17],
      claimable: vestingInfo[18],
      maxVestableAmount: vestingInfo[19],
      averageStakedAmount: vestingInfo[20],
      claimSum: vestingInfo[17].add(vestingInfo[18]),
    },
  };
}

export interface TotalRewards {
  totalEsGmxRewards: BigNumber | undefined;
  totalNativeTokenRewards: BigNumber | undefined;
  totalVesterRewards: BigNumber | undefined;
}

/**
 * Get total rewards accruring from trackers and vesters
 *
 */
export function getTotalRewards(
  rewardTrackerData: TrackerStates | undefined,
  vestingData: UserVestingInfo | undefined
): TotalRewards {
  const totalEsGmxRewards =
    rewardTrackerData !== undefined
      ? rewardTrackerData.stakedLpTokenTracker.claimable
          .add(rewardTrackerData.stakedEsGmxTracker.claimable)
          .add(rewardTrackerData.stakedGlpTracker.claimable)
      : undefined;

  const totalNativeTokenRewards =
    rewardTrackerData !== undefined
      ? rewardTrackerData.feeLpTokenTracker.claimable
          .add(rewardTrackerData.feeEsGmxTracker.claimable)
          .add(rewardTrackerData.feeGlpTracker.claimable)
      : undefined;

  const totalVesterRewards =
    vestingData !== undefined
      ? vestingData.lpTokenVester.claimable.add(vestingData.esGmxVester.claimable).add(vestingData.glpVester.claimable)
      : undefined;

  return {
    totalEsGmxRewards,
    totalNativeTokenRewards,
    totalVesterRewards,
  };
}
