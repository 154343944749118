import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import { isHomeSite } from "lib/legacy";

import { useWeb3React } from "@web3-react/core";

import { HeaderLink } from "../Header/HeaderLink";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import "./TokenCard.css";

import VLPTokenImage from "img/VLPToken.png";
import VMXTokenImage from "img/VMXToken.png";
import { useAprData } from "domain/gmxHooks";
import { formatAmount } from "lib/numbers";

interface TokenCardProps {
  showRedirectModal?: (to: string) => void;
  redirectPopupTimestamp?: number;
}

export default function TokenCard({ showRedirectModal, redirectPopupTimestamp }: TokenCardProps) {
  const isHome = isHomeSite();
  const { chainId } = useChainId();
  const { active } = useWeb3React();

  const aprData = useAprData();
  const gmxApr = aprData?.lpTokenApr.total; // GMX APR is equivalent to lpToken APR
  const glpApr = aprData?.glpApr.total;

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    if (isHome && showRedirectModal) {
      return (
        <HeaderLink
          to={to}
          className={className}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {children}
        </HeaderLink>
      );
    }

    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  return (
    <div className="Home-token-card-options">
      <div className="Token-card">
        <div className="Token-card-option-icon">
          <img className="Token-card-option-icon-image" src={VMXTokenImage} alt="VMXTokenImage" /> VMX
          <div className="Token-card-apr">
            {formatAmount(gmxApr, 2, 2, true)}%<div className="Token-card-apr-label"> APR</div>
          </div>
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            VMX is the utility/governance token & accrues 30% of the platform's generated fees.
          </div>

          <div className="Home-token-card-option-action">
            <div className="Token-card-button-section">
              <ExternalLink href="https://pancakeswap.finance/" className="Token-card-button-solid">
                <Trans>Buy VMX</Trans>
              </ExternalLink>
              <ExternalLink
                href="https://voodootrade.gitbook.io/voodoo-trade/tokenomics"
                className="Token-card-button-outline"
              >
                <Trans>Read more</Trans>
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>

      <div className="Token-card">
        <div className="Token-card-option-icon">
          <img src={VLPTokenImage} alt="VLPTokenImage" className="Token-card-option-icon-image" /> VLP
          <div className="Token-card-apr">
            {formatAmount(glpApr, 2, 2, true)}%<div className="Token-card-apr-label"> APR</div>
          </div>
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            VLP is the margin trading liquidity provider token & accrues 50% of the platform's generated fees.
          </div>
          <div className="Home-token-card-option-action">
            <div className="Token-card-button-section">
              <BuyLink to="/buy_vlp" className="Token-card-button-solid" network={chainId}>
                <Trans>Buy VLP</Trans>
              </BuyLink>
              <ExternalLink
                href="https://voodootrade.gitbook.io/voodoo-trade/vlp-liquidity-provision"
                className="Token-card-button-outline"
              >
                <Trans>Read more</Trans>
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
