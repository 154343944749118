import { getChainlinkChartPricesFromGraph, getFastPrices } from "domain/prices";
import { Bar } from "charting_library";

// ok- prices fetched for FTM (MIM)

/**
 * Fetches candle chart prices for a token
 *
 * Reads from stats server, falling back to chainlink
 *
 * @param chainId The chain
 * @param symbol Token symbol
 * @param period Candle period
 * @returns
 */
export async function getTokenChartPrice(chainId: number, symbol: string, period: string): Promise<Bar[] | undefined> {
  let bars: Bar[] | undefined;
  try {
    bars = await getFastPrices(chainId, symbol, period);
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex, "Switching to graph chainlink data");
    try {
      bars = await getChainlinkChartPricesFromGraph(symbol, period);
    } catch (ex2) {
      // eslint-disable-next-line no-console
      console.warn("getChainlinkChartPricesFromGraph failed", ex2);
      bars = [];
    }
  }
  return bars;
}
