import { Trans, t } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Modal from "components/Modal/Modal";
import { BigNumber, ethers } from "ethers";
import { callContract } from "lib/contracts";
import { formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { useState } from "react";

import RewardRouter from "abis/RewardRouterV3.json";
import { BASIS_POINTS_DIVISOR } from "lib/legacy";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import { TrackerStakesForUser } from "domain/readerHooks";

interface UnstakeModalProps {
  tokenToUnstake: "lpToken" | "esGmx" | undefined;
  setTokenToUnstake: React.Dispatch<React.SetStateAction<"lpToken" | "esGmx" | undefined>>;
  reservedLpToken: BigNumber | undefined;
  reservedEsGmx: BigNumber | undefined;
  claimableBonusLpToken: BigNumber | undefined;
  userStakes: TrackerStakesForUser | undefined;
  setPendingTxns: any;
}
export function UnstakeModal({
  tokenToUnstake,
  setTokenToUnstake,
  reservedLpToken,
  reservedEsGmx,
  claimableBonusLpToken,
  userStakes,
  setPendingTxns,
}: UnstakeModalProps) {
  const { library } = useWeb3React();
  const { chainId } = useChainId();

  const [unstakeValue, setUnstakeValue] = useState<string>("");
  const [isUnstaking, setIsUnstaking] = useState(false);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const isVisible = tokenToUnstake !== undefined;
  function setIsVisible(visible: boolean) {
    if (!visible) {
      setTokenToUnstake(undefined);
    }
  }

  let title = "";
  let unstakingTokenSymbol: string | undefined;
  let unstakeMethodName: string | undefined;
  let unstakeableAmount: BigNumber | undefined;

  switch (tokenToUnstake) {
    case "lpToken":
      title = "Unstake VMX-FTM LP";
      unstakingTokenSymbol = "VMX-FTM-LP";
      unstakeMethodName = "unstakeLpToken";

      unstakeableAmount =
        userStakes !== undefined &&
        reservedLpToken !== undefined &&
        claimableBonusLpToken !== undefined &&
        (!userStakes.lpToken.eq(0) || !userStakes.bonusLpToken.eq(0) || !claimableBonusLpToken.eq(0))
          ? userStakes.lpToken
              .mul(userStakes.lpToken.add(userStakes.bonusLpToken).sub(reservedLpToken))
              .div(userStakes.lpToken.add(userStakes.bonusLpToken).add(claimableBonusLpToken))
          : undefined;

      break;
    case "esGmx":
      title = "Unstake esVMX";
      unstakingTokenSymbol = "esVMX";
      unstakeMethodName = "unstakeEsGmx";

      // Reduction of lpToken equation when bonus is not involved
      unstakeableAmount =
        userStakes !== undefined && reservedEsGmx !== undefined ? userStakes.esGmx.sub(reservedEsGmx) : undefined;
  }

  let parsedUnstakeValue = parseValue(unstakeValue, 18);

  const burnAmount =
    userStakes !== undefined &&
    !userStakes.lpToken.eq(0) &&
    claimableBonusLpToken !== undefined &&
    parsedUnstakeValue !== undefined
      ? userStakes.bonusLpToken.add(claimableBonusLpToken).mul(parsedUnstakeValue).div(userStakes.lpToken)
      : undefined;

  const rewardReductionBasisPoints =
    burnAmount !== undefined && userStakes !== undefined && !userStakes.lpToken.eq(0)
      ? burnAmount.mul(BASIS_POINTS_DIVISOR).div(userStakes.lpToken)
      : undefined;

  const getError = () => {
    if (!parsedUnstakeValue) {
      return t`Enter an amount`;
    }
    if (unstakeableAmount === undefined) {
      return t`No max amount`;
    }
    if (parsedUnstakeValue.gt(unstakeableAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (unstakeMethodName === undefined) {
      throw Error("unstakeMethodName not provided");
    }
    setIsUnstaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [parsedUnstakeValue], {
      sentMsg: t`Unstake submitted!`,
      failMsg: t`Unstake failed.`,
      successMsg: t`Unstake completed!`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return t`Unstaking...`;
    }
    return t`Unstake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Unstake</Trans>
              </div>
            </div>
            <div
              className="muted align-right clickable"
              onClick={() => setUnstakeValue(formatAmountFree(unstakeableAmount, 18, 4))}
            >
              {/* <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}> */}
              <Trans>Max: {formatAmount(unstakeableAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={unstakeValue}
                onChange={(e) => setUnstakeValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
          </div>
        </div>

        {tokenToUnstake === "lpToken" ? (
          <>
            {reservedLpToken && reservedLpToken.gt(0) && (
              <div className="Modal-note">
                You have {formatAmount(reservedLpToken, 18, 2, true)} tokens reserved for vesting.
              </div>
            )}
            {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
              <div className="Modal-note">
                <Trans>
                  Unstaking will burn&nbsp;
                  <ExternalLink href="https://voodootrade.gitbook.io/voodoo-trade/rewards">
                    {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
                  </ExternalLink>
                  .&nbsp;
                  <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
                </Trans>
              </div>
            )}
          </>
        ) : (
          <>
            {reservedEsGmx && reservedEsGmx.gt(0) && (
              <div className="Modal-note">
                You have {formatAmount(reservedEsGmx, 18, 2, true)} tokens reserved for vesting.
              </div>
            )}
          </>
        )}

        {/* {reservedLpToken && reservedLpToken.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedLpToken, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        {burnAmount && burnAmount.gt(0) && rewardReductionBasisPoints && rewardReductionBasisPoints.gt(0) && (
          <div className="Modal-note">
            <Trans>
              Unstaking will burn&nbsp;
              <ExternalLink href="https://voodootrade.gitbook.io/voodoo-trade/rewards">
                {formatAmount(burnAmount, 18, 4, true)} Multiplier Points
              </ExternalLink>
              .&nbsp;
              <span>Boost Percentage: -{formatAmount(rewardReductionBasisPoints, 2, 2)}%.</span>
            </Trans>
          </div>
        )} */}

        <div className="Exchange-swap-button-container">
          <button
            className="StakeV2-button-solid Exchange-swap-button"
            onClick={onClickPrimary}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
