import { ReactNode } from "react";
import Tooltip from "../Tooltip/Tooltip";

interface InfoCardProps {
  label: string;
  data: ReactNode;
  tooltipText?: ReactNode;
  toolTipPosition?: string;
}

function InfoCard({ label, data, tooltipText, toolTipPosition = "left-bottom" }: InfoCardProps) {
  return (
    <div className="info-card">
      <div className="card-details">
        <h3 className="label">
          {tooltipText ? (
            <Tooltip
              handle={label}
              position={toolTipPosition}
              renderContent={() => <div className="Exchange-info-tooltip">{tooltipText}</div>}
            />
          ) : (
            label
          )}
        </h3>
        <div className="data">{data}</div>
      </div>
    </div>
  );
}

export default InfoCard;
