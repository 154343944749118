import React from "react";
import { FiX } from "react-icons/fi";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

import { HeaderLink } from "./HeaderLink";
import "./Header.css";
import { isHomeSite } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import logoImg from "img/logo_GMX.svg";
import VoodooLogoImage from "img/voodooLogo.svg";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <Link className="App-header-link-main" to="/">
            <img src={VoodooLogoImage} alt="VoodooLogoImage" />
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      {small && (
        <div className="App-header-link-container header__link_wrapper">
          <HeaderLink
            to="/trade"
            redirectPopupTimestamp={redirectPopupTimestamp}
            showRedirectModal={showRedirectModal}
            className="header__link"
          >
            <Trans>Trade</Trans>
          </HeaderLink>
        </div>
      )}
      <div className="App-header-link-container header__link_wrapper">
        <HeaderLink
          to="/airdrop"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="header__link"
        >
          <Trans>Airdrop</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container header__link_wrapper">
        <HeaderLink
          to="/dashboard"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="header__link"
        >
          <Trans>Dashboard</Trans>
        </HeaderLink>
      </div>
      {/* <div className="App-header-link-container header__link_wrapper">
        <HeaderLink
          to="/esvmx"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="header__link"
        >
          <Trans>esVMX</Trans>
        </HeaderLink>
      </div> */}
      <div className="App-header-link-container header__link_wrapper">
        <HeaderLink
          to="/earn"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="header__link"
        >
          <Trans>Earn</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container header__link_wrapper">
        <HeaderLink
          to="/buy"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="header__link"
        >
          <Trans>Buy</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container header__link_wrapper">
        <HeaderLink
          to="/referrals"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="header__link"
        >
          <Trans>Referrals</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container header__link_wrapper">
        <ExternalLink href="https://stats.voodoo.trade" className="header__link">
          <Trans>Stats</Trans>
        </ExternalLink>
      </div>
      <div className="App-header-link-container header__link_wrapper">
        <HeaderLink
          to="/ecosystem"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="header__link"
        >
          <Trans>Ecosystem</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container header__link_wrapper">
        <HeaderLink
          to="/leaderboard"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
          className="header__link"
        >
          <Trans>Leaderboard</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container header__link_wrapper">
        <ExternalLink href="https://voodootrade.gitbook.io/voodoo-trade/" className="header__link">
          <Trans>About</Trans>
        </ExternalLink>
      </div>
      {small && !isHomeSite() && (
        <div className="App-header-link-container header__link_wrapper">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={openSettings} className="header__link">
            <Trans>Settings</Trans>
          </a>
        </div>
      )}
    </div>
  );
}
