import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import { ARBITRUM, AVALANCHE, BASE, BASE_TESTNET, FANTOM, FANTOM_TESTNET } from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink); // On Ethereum network

export const arbitrumGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].stats);

export const arbitrumReferralsGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].referrals);
export const nissohGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].nissohVault);

export const avalancheGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE].stats);
export const avalancheReferralsGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE].referrals);

export const fantomTestnetGraphClient = createClient(SUBGRAPH_URLS[FANTOM_TESTNET].stats);
export const fantomTestnetReferralsGraphClient = createClient(SUBGRAPH_URLS[FANTOM_TESTNET].referrals);
export const fantomTestnetPricesClient = createClient(SUBGRAPH_URLS[FANTOM_TESTNET].prices);

export const fantomGraphClient = createClient(SUBGRAPH_URLS[FANTOM].stats);
export const fantomReferralsGraphClient = createClient(SUBGRAPH_URLS[FANTOM].referrals);
export const fantomPricesClient = createClient(SUBGRAPH_URLS[FANTOM].prices);

export const baseGraphClient = createClient(SUBGRAPH_URLS[BASE].stats);
export const baseReferralsGraphClient = createClient(SUBGRAPH_URLS[BASE].referrals);
export const basePricesClient = createClient(SUBGRAPH_URLS[BASE].prices);

export const baseTestnetGraphClient = createClient(SUBGRAPH_URLS[BASE_TESTNET].stats);
export const baseTestnetReferralsGraphClient = createClient(SUBGRAPH_URLS[BASE_TESTNET].referrals);
export const baseTestnetPricesClient = createClient(SUBGRAPH_URLS[BASE_TESTNET].prices);

export function getGmxStatsClient(chainId: number) {
  if (chainId === ARBITRUM) {
    return arbitrumGraphClient;
  } else if (chainId === AVALANCHE) {
    return avalancheGraphClient;
  } else if (chainId === FANTOM_TESTNET) {
    return fantomTestnetGraphClient;
  } else if (chainId === FANTOM) {
    return fantomGraphClient;
  } else if (chainId === BASE) {
    return baseGraphClient;
  } else if (chainId === BASE_TESTNET) {
    return baseTestnetGraphClient;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}

export function getGmxPricesClient(chainId: number) {
  if (chainId === FANTOM_TESTNET) {
    return fantomTestnetPricesClient;
  } else if (chainId === FANTOM) {
    return fantomPricesClient;
  } else if (chainId === BASE) {
    return basePricesClient;
  } else if (chainId === BASE_TESTNET) {
    return baseTestnetPricesClient;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}
