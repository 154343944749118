import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import GlpSwap from "components/Glp/GlpSwap";
import Footer from "components/Footer/Footer";
import "./BuyGlp.css";

import { Trans } from "@lingui/macro";
import { getNativeToken } from "config/tokens";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";

import starsImg from "img/Stars.svg";
import potImg from "img/Pot.svg";
import skeletonImg from "img/Skeleton.svg";

export default function BuyGlp(props) {
  const { chainId } = useChainId();
  const history = useHistory();
  const [isBuying, setIsBuying] = useState(true);
  const nativeTokenSymbol = getNativeToken(chainId).symbol;

  useEffect(() => {
    const hash = history.location.hash.replace("#", "");
    const buying = hash === "redeem" ? false : true;
    setIsBuying(buying);
  }, [history.location.hash]);

  return (
    <SEO title={getPageTitle("Buy VLP")}>
      <div className="background__wrapper">
        <div className="backgroundStars">
          <img src={starsImg} alt="stars" className="backgroundStars__image" />
        </div>
        <div className="backgroundPot">
          <img src={potImg} alt="pot" className="backgroundPot__image" />
        </div>
        <div className="backgroundSkeleton">
          <img src={skeletonImg} alt="pot" className="backgroundSkeleton__image" />
        </div>
      </div>
      <div className="default-container BuyGlp-page-layout z-index-100">
        <div className="BuyGlp-section-title-block">
          {/* <div className="BuyGlp-section-title-icon">
            <img src={buyGLPIcon} alt={t`Buy GLP Icon`} />
          </div> */}
          <div className="BuyGlp-section-title-content">
            <div className="BuyGlp-Page-title">
              <Trans>Buy / Sell </Trans>
              <div className="BuyGlp-Page-title-part">VLP</div>
            </div>
            <div className="BuyGlp-Page-description">
              Purchase{" "}
              <ExternalLink href="https://voodootrade.gitbook.io/voodoo-trade/vlp-liquidity-provision">
                VLP tokens
              </ExternalLink>{" "}
              to earn {nativeTokenSymbol} fees from swaps and leverage trading.
            </div>
          </div>
        </div>
        <GlpSwap {...props} isBuying={isBuying} setIsBuying={setIsBuying} />
      </div>
      <Footer />
    </SEO>
  );
}
