import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import Tooltip from "components/Tooltip/Tooltip";
import { VestingInfo } from "domain/readerHooks";
import { BigNumber } from "ethers";
import { formatAmount } from "lib/numbers";
import React from "react";

interface VesterCardProps {
  name: string;
  icon: string;
  userStake: BigNumber | undefined;
  vestingInfo: VestingInfo | undefined;
  depositCallback: () => void;
  withdrawCallback: () => void;
  connectWallet: () => any;
}

export default function VesterCard({
  name,
  icon,
  userStake,
  vestingInfo,
  depositCallback,
  withdrawCallback,
  connectWallet,
}: VesterCardProps) {
  const { active } = useWeb3React();

  return (
    <div className="StakeV2-Vest-card">
      <div className="StakeV2-Vest-card-wrapper">
        <div className="StakeV2-Vest-card-title-block">
          <div className="StakeV2-Vest-card-title-wrapper">
            <div className="StakeV2-Vest-card-title-image-wrapper">
              <img className="StakeV2-Vest-card-title-image" src={icon} alt={name} />
            </div>
            <div className="StakeV2-Vest-card-title">{name} Vester</div>
          </div>
        </div>
        <div className="StakeV2-Vest-card-value-block">
          <div className="StakeV2-Vest-card-value-rows-wrapper">
            <div className="StakeV2-Vest-card-value-row">
              <div className="StakeV2-Vest-card-value-title">Staked Tokens</div>
              <div className="StakeV2-Vest-card-value-value">{formatAmount(userStake, 18, 2, true)}</div>
            </div>
            <div className="StakeV2-Vest-card-value-row">
              <div className="StakeV2-Vest-card-value-title">Reserved for Vesting</div>
              <div className="StakeV2-Vest-card-value-value">
                {formatAmount(vestingInfo?.pairAmount, 18, 2, true)} / {formatAmount(userStake, 18, 2, true)}
              </div>
            </div>
          </div>
          <div className="StakeV2-Vest-card-value-rows-wrapper">
            <div className="StakeV2-Vest-card-value-row">
              <div className="StakeV2-Vest-card-value-title">
                <Trans>Vesting Status</Trans>
              </div>
              <div className="StakeV2-Vest-card-value-value">
                <Tooltip
                  handle={`${formatAmount(vestingInfo?.claimSum, 18, 4, true)} / ${formatAmount(
                    vestingInfo?.vestedAmount,
                    18,
                    4,
                    true
                  )}`}
                  position="right-bottom"
                  renderContent={() => {
                    return (
                      <div className="StakeV2-Holdings-card-tooltip">
                        {/* <Trans> */}
                        {formatAmount(vestingInfo?.claimSum, 18, 4, true)} tokens have been converted to VMX from the{" "}
                        {formatAmount(vestingInfo?.vestedAmount, 18, 4, true)} {name} deposited for vesting.
                        {/* </Trans> */}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <div className="StakeV2-Vest-card-value-row">
              <div className="StakeV2-Vest-card-value-title">Claimable</div>
              <div className="StakeV2-Vest-card-value-value">
                <Tooltip
                  handle={`${formatAmount(vestingInfo?.claimable, 18, 4, true)} VMX`}
                  position="right-bottom"
                  renderContent={() => (
                    <div className="StakeV2-Holdings-card-tooltip">
                      {/* <Trans> */}
                      {formatAmount(vestingInfo?.claimable, 18, 4, true)} VMX tokens can be claimed, use the options
                      under the Total Rewards section to claim them.
                      {/* </Trans> */}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="StakeV2-Vest-buttons-wrapper">
        {active ? (
          <>
            <button disabled={true} className="StakeV2-button-outline App-card-option" onClick={depositCallback}>
              <Trans>Deposits Disabled</Trans>
            </button>
            <button className="StakeV2-button-outline App-card-option" onClick={withdrawCallback}>
              <Trans>Withdraw</Trans>
            </button>
          </>
        ) : (
          <button className="StakeV2-button-outline App-card-option" type="submit" onClick={connectWallet}>
            <Trans>Connect Wallet</Trans>
          </button>
        )}
      </div>
    </div>
  );
}
