import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";
import { BigNumber, ethers } from "ethers";
import { useChainId } from "lib/chains";
import Reader from '../../abis/ReaderV2.json'
import { useEffect, useState } from "react";
import { useJsonRpcProvider } from "lib/rpc";

const POLL_INTERVAL = 3000

/**
 * Polls for user positions every `POLL_INTERVAL`
 * @param collateralTokens
 * @param indexTokens
 * @param isLong
 * @returns
 */
export default function usePositionData(
	collateralTokens: string[],
	indexTokens: string[],
	isLong: boolean[]
) {
	const { account } = useWeb3React()
	const { chainId } = useChainId()
	const { provider } = useJsonRpcProvider(chainId)

	const readerAddress = getContract(chainId, "Reader")
	const vaultAddress = getContract(chainId, "Vault")

	const [positions, setPositions] = useState<BigNumber[]>()
	const [error, setError] = useState<Error>()

	useEffect(() => {
		async function getPositions() {
			if (account != null && collateralTokens.length > 0 && provider !== undefined) {
				try {
					// Metamask's injected provider is slow. Use the RPC
					const reader = new ethers.Contract(readerAddress, Reader.abi, provider)
					const fetchedPositions = await reader.getPositions(
						vaultAddress,
						account,
						collateralTokens,
						indexTokens,
						isLong
					)
					setPositions(fetchedPositions)

				} catch (fetchError) {
					console.log('fetch positions failed', fetchError)
					setError(fetchError)
				}
			}
		}

		const intervalId = setInterval(() => {
      getPositions()
    }, POLL_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
	}, [account, collateralTokens, indexTokens, isLong])
	return { positions, error }
}