import React from "react";
import { Menu } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import cx from "classnames";
import "./ChartTokenSelector.css";
import { LONG, SHORT, SWAP } from "lib/legacy";
import { getTokens, getWhitelistedTokens } from "config/tokens";
import { ChartTokenSelectorValues } from "./ChartTokenSelectorValues";

import NetworkCheckImage from "img/NetworkCheckImage.svg";

export default function ChartTokenSelector(props) {
  const { chainId, selectedToken, onSelectToken, swapOption } = props;

  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;

  let options = getTokens(chainId);
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);
  const shortableTokens = indexTokens.filter((token) => token.isShortable);

  if (isLong) {
    options = indexTokens;
  }
  if (isShort) {
    options = shortableTokens;
  }

  const onSelect = async (token) => {
    onSelectToken(token);
  };

  var value = selectedToken;

  return (
    <Menu>
      <Menu.Button as="div" disabled={isSwap}>
        <button className={cx("App-cta small transparent chart-token-selector", { "default-cursor": isSwap })}>
          <ChartTokenSelectorValues symbol={value.symbol} />
        </button>
      </Menu.Button>
      <div className="chart-token-menu">
        <Menu.Items as="div" className="menu-items chart-token-menu-items">
          {options.map((option, index) => {
            return (
              <Menu.Item key={index}>
                <div
                  className="menu-item chart-token-selector-wrapper"
                  onClick={() => {
                    onSelect(option);
                  }}
                >
                  <div className="chart-token-selector-tokens-wrapper">
                    <div className="chertTokenSelectorValues__images">
                      <img
                        className="chertTokenSelectorValues__image chartTokenSelectorValues__image_move"
                        src={option.imageUrl}
                        alt="firstTokenImage"
                      />
                    </div>
                    <span style={{ marginLeft: 0 }} className="token-label">
                      {option.symbol} / USD
                    </span>
                  </div>

                  <div className="network-dropdown-menu-item-img">
                    {value.symbol === option.symbol && (
                      <img src={NetworkCheckImage} alt="NetworkCheckImage" className="network-dropdown-check-image" />
                    )}
                  </div>
                </div>
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </div>
    </Menu>
  );
}
