import { getNativeToken, getTokens, isChartAvailableForToken } from "config/tokens";
import { SUPPORTED_RESOLUTIONS } from "config/tradingview";
import { timezoneOffset } from "domain/prices";
import { useChainId } from "lib/chains";
import { useEffect, useMemo, useRef } from "react";
import { TVDataProvider } from "./TVDataProvider";
import { HistoryCallback, PeriodParams, ResolutionString, SubscribeBarsCallback } from "charting_library";
import { SymbolInfo } from "./types";

const configurationData = {
  supported_resolutions: Object.keys(SUPPORTED_RESOLUTIONS),
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: true,
  reset_cache_timeout: 100,
};

// Returns a data feed object with functions to read and subscribe to price bars
export default function useTVDatafeed() {
  const { chainId } = useChainId();
  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();
  const resetCacheRef = useRef<() => void | undefined>();
  const activeTicker = useRef<string | undefined>();
  const tvRequests = useRef<TVDataProvider>();

  useEffect(() => {
    tvRequests.current = new TVDataProvider();
  }, []);

  return useMemo(() => {
    return {
      resetCache: resetCacheRef,
      datafeed: {
        onReady: (callback) => {
          setTimeout(() => callback(configurationData));
        },
        resolveSymbol(symbolName, onSymbolResolvedCallback) {
          if (!isChartAvailableForToken(chainId, symbolName)) {
            // problem here- flipping ETH for FTM
            symbolName = getNativeToken(chainId).symbol;
          }

          const stableTokens = getTokens(chainId)
            .filter((t) => t.isStable)
            .map((t) => t.symbol);
          const symbolInfo = {
            name: symbolName,
            type: "crypto",
            description: symbolName + " / USD",
            ticker: symbolName,
            session: "24x7",
            minmov: 1,
            // TradingView chart decimals
            pricescale: 100,
            timezone: "Etc/UTC",
            has_intraday: true,
            has_daily: true,
            currency_code: "USD",
            visible_plots_set: "ohlc",
            data_status: "streaming",
            isStable: stableTokens.includes(symbolName),
          };
          setTimeout(() => onSymbolResolvedCallback(symbolInfo));
        },

        // Called once in start
        async getBars(
          symbolInfo: SymbolInfo,
          resolution: ResolutionString,
          periodParams: PeriodParams,
          onHistoryCallback: HistoryCallback,
          onErrorCallback: (error: string) => void
        ) {
          const { from, to, countBack } = periodParams;
          const toWithOffset = (to + timezoneOffset) * 1000;
          const fromWithOffset = (from + timezoneOffset) * 1000;

          if (!SUPPORTED_RESOLUTIONS[resolution]) {
            return onErrorCallback("[getBars] Invalid resolution");
          }
          const { ticker, isStable } = symbolInfo;
          if (activeTicker.current !== ticker) {
            activeTicker.current = ticker;
          }

          try {
            const bars =
              (await tvRequests.current?.getHistoryBars(chainId, ticker ?? "5m", resolution, isStable, countBack)) ??
              [];
            const filteredBars = bars.filter((bar) => bar.time >= fromWithOffset && bar.time <= toWithOffset);
            onHistoryCallback(filteredBars, { noData: filteredBars.length === 0 });
          } catch {
            onErrorCallback("Unable to load historical bar data");
          }
        },

        // Polled every 3 seconds
        async subscribeBars(
          symbolInfo: SymbolInfo,
          resolution: ResolutionString,
          onRealtimeCallback: SubscribeBarsCallback,
          _subscribeUID,
          onResetCacheNeededCallback: () => void
        ) {
          const { ticker, isStable } = symbolInfo;
          intervalRef.current && clearInterval(intervalRef.current);
          resetCacheRef.current = onResetCacheNeededCallback;

          if (!isStable) {
            intervalRef.current = setInterval(async function () {
              await tvRequests.current?.getHistoryBars(chainId, ticker ?? "5m", resolution, isStable, 0);
              const liveBar = tvRequests.current?.lastBar;

              if (liveBar != null) {
                onRealtimeCallback(liveBar);
              }
            }, 3000);
          }
        },
        unsubscribeBars: () => {
          intervalRef.current && clearInterval(intervalRef.current);
        },
      },
    };
  }, [chainId]);
}
