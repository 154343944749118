import React from "react";

import SwapYellowRectangleImage from "img/swapYellowRectangle.svg";

export const ReferralLogo = (props) => {
  const { title, imageUrl } = props;

  return (
    <div className="referralLogo">
      <img className="Referrals-yellow-rectangle-image" src={SwapYellowRectangleImage} alt="SwapYellowRectangleImage" />
      <img className="referralLogo__image" src={imageUrl} alt={title} />
      <p className="referralLogo__title">{title}</p>
    </div>
  );
};
