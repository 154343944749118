import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";
import { BigNumber, ethers } from "ethers";
import UniswapV2 from "abis/UniswapV2.json";
import Token from "abis/Token.json";
import ReaderV2 from "abis/ReaderV2.json";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import { getProvider } from "lib/rpc";
import { useChainId } from "lib/chains";
import { BASIS_POINTS_DIVISOR, GLP_DECIMALS, SECONDS_PER_YEAR } from "lib/legacy";
import { BigZero, expandDecimals } from "lib/numbers";
import { useGlpAumUsdE30 } from "./glpHooks";
import { TrackerStates, useTrackerData } from "./readerHooks";
import { useNativeTokenPriceE30 } from "./prices";

/**
 * Get the GMX supply excluding tokens in vesters
 * @returns
 */
export function useGmxSupply(): BigNumber | undefined {
  const { chainId } = useChainId();

  const excludedAddresses = [
    getContract(chainId, "LpTokenVester"),
    getContract(chainId, "EsGmxVester"),
    getContract(chainId, "GlpVester"),
  ];
  return useTokenSupply("GMX", excludedAddresses);
}

export function useTokenSupply(
  tokenName: "GMX" | "ES_GMX" | "lpToken" | "GLP" | "StakedLpTokenTracker",
  excludeAccounts: string[] = []
): BigNumber | undefined {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();
  const token = getContract(chainId, tokenName);
  const readerAddress = getContract(chainId, "Reader");

  const { data } = useSWR<BigNumber>(
    [
      `${active}:${token}:getTokenSupply:exclude:${excludeAccounts?.toString()}`,
      chainId,
      readerAddress,
      "getTokenSupply",
      token,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [excludeAccounts]),
    }
  );

  return data;
}

/**
 * Get the circulating supply of EsGMX, excluding reward distributor balances
 *
 * @returns
 */
export function useCirculatingEsGmxSupply() {
  const { chainId } = useChainId();

  const stakedLpTokenDistributor = getContract(chainId, "StakedLpTokenDistributor");
  const stakedEsGmxDistributor = getContract(chainId, "StakedEsGmxDistributor");
  const stakedGlpDistributor = getContract(chainId, "StakedGlpDistributor");
  const excludedEsGmxAccounts = [stakedLpTokenDistributor, stakedEsGmxDistributor, stakedGlpDistributor];

  return useTokenSupply("ES_GMX", excludedEsGmxAccounts);
}

export function useBalanceOf(
  tokenName: "GMX" | "lpToken" | "ES_GMX",
  account: string | null | undefined
): BigNumber | undefined {
  const { library, active } = useWeb3React();
  const { chainId } = useChainId();
  const tokenAddress = getContract(chainId, tokenName);

  const { data } = useSWR<BigNumber>(
    account != null ? [`${tokenAddress}:useBalanceOf:${active}`, chainId, tokenAddress, "balanceOf", account] : null,
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  return data;
}

// Get the amount of VMX-FTM LP staked in StakedLpTokenTracker
export function useStakedLpTokenSupply(): BigNumber | undefined {
  const { chainId } = useChainId();
  const stakedLpTokenTracker = getContract(chainId, "StakedLpTokenTracker");
  return useBalanceOf("lpToken", stakedLpTokenTracker);
}

export function useTeamGmxSupply(): BigNumber | undefined {
  const { chainId } = useChainId();

  // TODO add other wallets, account for mainnet
  const TEAM_WALLETS = ["0x9cBa37df627CdAa6548E1a837F82773D68E593D0"];
  const gmx = getContract(chainId, "GMX");

  const provider = getProvider(undefined, chainId);
  const gmxContract = new ethers.Contract(gmx, Token.abi, provider);

  const { data } = useSWR<BigNumber>(TEAM_WALLETS, async () => {
    const balances = await Promise.all(
      TEAM_WALLETS.map((wallet) => gmxContract!.balanceOf(wallet) as Promise<BigNumber>)
    );
    const total = balances.reduce((prev, current) => {
      return prev.add(current);
    }, BigNumber.from(0));

    return total;
  });

  return data;
}

interface PoolReserves {
  gmxReserve: BigNumber | undefined;
  nativeTokenReserve: BigNumber | undefined;
}

// Read GMX and ETH reserves from Pancakeswap liquidity pool
export function usePoolReserves(): PoolReserves {
  const { active } = useWeb3React();
  const { chainId } = useChainId();

  const lpToken = getContract(chainId, "lpToken");
  const gmx = getContract(chainId, "GMX");
  const nativeToken = getContract(chainId, "NATIVE_TOKEN");

  const { data: liquidityPool } = useSWR<{ _reserve0: BigNumber; _reserve1: BigNumber }>(
    [`GMX:poolReserves:${active}`, chainId, lpToken, "getReserves"],
    {
      fetcher: contractFetcher(undefined, UniswapV2),
    }
  );

  if (gmx === undefined || nativeToken === undefined || liquidityPool === undefined) {
    return {
      gmxReserve: undefined,
      nativeTokenReserve: undefined,
    };
  }

  const reserve0 = liquidityPool._reserve0;
  const reserve1 = liquidityPool._reserve1;

  const [gmxReserve, nativeTokenReserve] = gmx < nativeToken ? [reserve0, reserve1] : [reserve1, reserve0];

  return {
    gmxReserve,
    nativeTokenReserve,
  };
}

/**
 * Get the prices and supplies of native token and GMX in the liquidity pool
 */
export function useLiquidityPoolInfo() {
  const nativeTokenPriceE30 = useNativeTokenPriceE30();
  const { gmxReserve, nativeTokenReserve } = usePoolReserves();
  const lpTokenSupply = useTokenSupply("lpToken");

  const gmxPriceE30 = getGmxPriceE30(gmxReserve, nativeTokenReserve, nativeTokenPriceE30);

  if (
    nativeTokenPriceE30 === undefined ||
    gmxReserve === undefined ||
    nativeTokenReserve === undefined ||
    lpTokenSupply === undefined ||
    gmxPriceE30 === undefined
  ) {
    return {
      gmxReserve: undefined,
      nativeTokenReserve: undefined,
      lpTokenSupply: undefined,
      gmxPriceE30: undefined,
      nativeTokenPriceE30: undefined,
      lpTokenPriceE30: undefined,
      poolTvlE30: undefined,
    };
  }

  const poolTvlE30 = gmxReserve.mul(gmxPriceE30).add(nativeTokenReserve.mul(nativeTokenPriceE30));

  const lpTokenPriceE30 = poolTvlE30.div(lpTokenSupply);

  return {
    gmxReserve,
    nativeTokenReserve,
    lpTokenSupply,
    gmxPriceE30,
    nativeTokenPriceE30,
    lpTokenPriceE30,
    poolTvlE30,
  };
}

export interface LpTokenApr {
  esGmx: BigNumber;
  nativeToken: BigNumber;
  total: BigNumber;
  boost: BigNumber;
  nativeTokenWithBoost: BigNumber;
  totalWithBoost: BigNumber;
}

export interface EsGmxApr {
  esGmx: BigNumber;
  nativeToken: BigNumber;
  total: BigNumber;
}

export interface GlpApr extends EsGmxApr {}

export interface AprData {
  // APR on staking lpToken. Equivalent to APR on GMX
  lpTokenApr: LpTokenApr;

  // APR on staking esGmx
  esGmxApr: EsGmxApr;

  // APR on staking GLP
  glpApr: GlpApr;
}

/**
 * Calculate GMX and GLP APRs accuring from esGMX and FTM emissions.
 *
 * TODO include spooky fee into APR. Currently fee growth is not considered.
 *
 * @param esGmxPriceE30
 * @param nativeTokenPriceE30
 * @param glpPriceE30
 * @param stakedLpTokenTrackerTokensPerInterval
 * @param feeLpTokenTrackerTokensPerInterval
 * @param stakedGlpTrackerTokensPerInterval
 * @param feeGlpTrackerTokensPerInterval
 * @param totalStakedLpToken
 * @param totalStakedGlp
 * @returns
 */
export function calculateAPRs(
  esGmxPriceE30: BigNumber | undefined,
  nativeTokenPriceE30: BigNumber | undefined,
  lpTokenPriceE30: BigNumber | undefined,
  glpPriceE30: BigNumber | undefined,
  rewardTrackerData: TrackerStates | undefined,
  boostBasisPoints?: BigNumber
): AprData | undefined {
  // APR = value of rewards over year / value of principal
  // value of rewards over year = emission per second * seconds in a year * priceR * (principal / total stake)
  // value of principal = principal * priceP
  // APR = emission per second * seconds in a year * priceR / (priceP * total stake)
  if (
    esGmxPriceE30 === undefined ||
    nativeTokenPriceE30 === undefined ||
    lpTokenPriceE30 === undefined ||
    glpPriceE30 === undefined ||
    rewardTrackerData === undefined
  ) {
    return undefined;
  }

  const totalStakedLpToken = rewardTrackerData.stakedLpTokenTracker.totalSupply;
  const totalStakedEsGmx = rewardTrackerData.stakedEsGmxTracker.totalSupply;
  const totalStakedGlp = rewardTrackerData.stakedGlpTracker.totalSupply;

  // 1. lpToken
  // GMX APR is assumed to be the same as VMX-FTM LP
  // 1 dollar of GMX is equivalent to 1 dollar of lpToken
  const lpTokenApr = {
    esGmx:
      !totalStakedLpToken.eq(0) && !lpTokenPriceE30.eq(0)
        ? rewardTrackerData.stakedLpTokenTracker.tokensPerInterval
            .mul(esGmxPriceE30)
            .mul(SECONDS_PER_YEAR * BASIS_POINTS_DIVISOR)
            .div(totalStakedLpToken)
            .div(lpTokenPriceE30)
        : BigZero,

    nativeToken:
      !totalStakedLpToken.eq(0) && !lpTokenPriceE30.eq(0)
        ? rewardTrackerData.feeLpTokenTracker.tokensPerInterval
            .mul(nativeTokenPriceE30)
            .mul(SECONDS_PER_YEAR * BASIS_POINTS_DIVISOR)
            .div(totalStakedLpToken)
            .div(lpTokenPriceE30)
        : BigZero,

    total: BigZero,

    // boost bps * native token APR
    boost: BigZero,

    nativeTokenWithBoost: BigZero,

    totalWithBoost: BigZero,
  };
  lpTokenApr.total = lpTokenApr.esGmx.add(lpTokenApr.nativeToken);

  lpTokenApr.boost =
    boostBasisPoints !== undefined ? lpTokenApr.nativeToken.mul(boostBasisPoints).div(BASIS_POINTS_DIVISOR) : BigZero;

  lpTokenApr.nativeTokenWithBoost = lpTokenApr.nativeToken.add(lpTokenApr.boost);
  lpTokenApr.totalWithBoost = lpTokenApr.total.add(lpTokenApr.boost);

  // 2. esGmx
  const esGmxApr = {
    esGmx:
      !totalStakedEsGmx.eq(0) && !esGmxPriceE30.eq(0)
        ? rewardTrackerData.stakedEsGmxTracker.tokensPerInterval
            .mul(esGmxPriceE30)
            .mul(SECONDS_PER_YEAR * BASIS_POINTS_DIVISOR)
            .div(totalStakedEsGmx)
            .div(esGmxPriceE30)
        : BigZero,

    nativeToken:
      !totalStakedEsGmx.eq(0) && !esGmxPriceE30.eq(0)
        ? rewardTrackerData.feeEsGmxTracker.tokensPerInterval
            .mul(nativeTokenPriceE30)
            .mul(SECONDS_PER_YEAR * BASIS_POINTS_DIVISOR)
            .div(totalStakedEsGmx)
            .div(esGmxPriceE30)
        : BigZero,

    total: BigZero,
  };
  esGmxApr.total = esGmxApr.esGmx.add(esGmxApr.nativeToken);

  // 3. glp

  const glpApr = {
    esGmx:
      !totalStakedGlp.eq(0) && !glpPriceE30.eq(0)
        ? rewardTrackerData.stakedGlpTracker.tokensPerInterval
            .mul(esGmxPriceE30)
            .mul(SECONDS_PER_YEAR * BASIS_POINTS_DIVISOR)
            .div(totalStakedGlp)
            .div(glpPriceE30)
        : BigZero,

    nativeToken:
      !totalStakedGlp.eq(0) && !glpPriceE30.eq(0)
        ? rewardTrackerData.feeGlpTracker.tokensPerInterval
            .mul(nativeTokenPriceE30)
            .mul(SECONDS_PER_YEAR * BASIS_POINTS_DIVISOR)
            .div(totalStakedGlp)
            .div(glpPriceE30)
        : BigZero,

    total: BigZero,
  };
  glpApr.total = glpApr.esGmx.add(glpApr.nativeToken);

  return { lpTokenApr, esGmxApr, glpApr };
}

/**
 * Returns the APR on lpToken, esGmx and GLP
 *
 * The APR is calculated based on esGMX and native token emissions. Fees and bonus tracker
 * is not considered.
 *
 * Note: The APR on GMX is considered equivalent to GMX-FTM APR. I.e. $100 worth of GMX is equivalent
 * to $100 worth LP token
 *
 * @returns
 */
export function useAprData(): AprData | undefined {
  const { nativeTokenPriceE30, gmxPriceE30, lpTokenPriceE30 } = useLiquidityPoolInfo();
  const rewardTrackerData = useTrackerData();
  const glpAumUsdE30 = useGlpAumUsdE30();

  const glpPriceE30 =
    glpAumUsdE30 !== undefined &&
    rewardTrackerData !== undefined &&
    !rewardTrackerData.stakedGlpTracker.totalSupply.eq(0)
      ? glpAumUsdE30.mul(expandDecimals(1, GLP_DECIMALS)).div(rewardTrackerData.stakedGlpTracker.totalSupply)
      : undefined;

  return calculateAPRs(gmxPriceE30, nativeTokenPriceE30, lpTokenPriceE30, glpPriceE30, rewardTrackerData);
}

/**
 * Get the price of GMX
 *
 * Prefer useLiquidityPoolInfo() for more data
 *
 * @returns
 */
export function useGmxPriceE30() {
  const nativeTokenPriceE30 = useNativeTokenPriceE30();
  const reserves = usePoolReserves();

  return getGmxPriceE30(reserves?.gmxReserve, reserves?.nativeTokenReserve, nativeTokenPriceE30);
}

export function getGmxPriceE30(gmxReserve?: BigNumber, ftmReserve?: BigNumber, ftmPriceE30?: BigNumber) {
  if (gmxReserve === undefined || gmxReserve.isZero() || ftmReserve === undefined || ftmPriceE30 === undefined) {
    return undefined;
  }
  return ftmReserve.mul(ftmPriceE30).div(gmxReserve);
}
