import React, { Fragment } from "react";

import cx from "classnames";

import style from "./SelectOrder.module.css";

import SelectOrderArrowImage from "img/selectOrderArrowImage.svg";

import { Menu } from "@headlessui/react";
import { Trans } from "@lingui/macro";

export default function SelectOrder(props) {
  const { options, optionLabels, option, onChange } = props;

  return (
    <Menu>
      <Menu.Button as="div">
        <button className={style.ListboxButton}>
          <span className="user-address">{optionLabels[option]}</span>
          <img className={style.ListboxArrowImage} src={SelectOrderArrowImage} alt="SelectOrderArrowImage" />
        </button>
      </Menu.Button>
      <div>
        <Menu.Items as="div" className={style.menuItems}>
          {options.map((opt) => {
            return (
              <Menu.Item key={opt}>
                <div
                  className={style.menuItem}
                  onClick={() => {
                    onChange(opt);
                  }}
                >
                  <p>{optionLabels[opt]}</p>
                </div>
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </div>
    </Menu>
  );
}
