import { PlatformTokens, TrackerStates } from "domain/readerHooks";
import { BigNumber } from "ethers";
import { expandDecimals } from "lib/numbers";

const DECIMALS_18 = expandDecimals(1, 18);

export function getValueUsd(supply: BigNumber | undefined, price: BigNumber | undefined) {
  // Assuming supply token has 18 decimal places. We want to find the price of
  // 1 GMX, not 0.0000...1 of GMX
  return supply !== undefined && price !== undefined ? supply.mul(price).div(DECIMALS_18) : undefined;
}

export default function getBalancesUsdE30(
  balanceData: PlatformTokens | undefined,
  lpTokenPriceE30: BigNumber | undefined,
  gmxPriceE30: BigNumber | undefined,
  glpPriceE30: BigNumber | undefined
) {
  return {
    lpToken: getValueUsd(balanceData?.lpToken, lpTokenPriceE30),
    gmx: getValueUsd(balanceData?.gmx, gmxPriceE30),
    esGmx: getValueUsd(balanceData?.esGmx, gmxPriceE30),
    glp: getValueUsd(balanceData?.glp, glpPriceE30),
  };
}

export interface ClaimableRewards {
  stakedLpTokenTracker: BigNumber | undefined;
  feeLpTokenTracker: BigNumber | undefined;
  lpTokenTrackerTotal: BigNumber | undefined;

  stakedEsGmxTracker: BigNumber | undefined;
  feeEsGmxTracker: BigNumber | undefined;
  esGmxTrackerTotal: BigNumber | undefined;

  stakedGlpTracker: BigNumber | undefined;
  feeGlpTracker: BigNumber | undefined;
  glpTrackerTotal: BigNumber | undefined;

  esGmxRewards: BigNumber | undefined;
  nativeTokenRewards: BigNumber | undefined;
  total: BigNumber | undefined;
}

export function getRewardsUsdE30(
  rewardTrackerData: TrackerStates | undefined,
  gmxPriceE30: BigNumber | undefined,
  nativeTokenPriceE30: BigNumber | undefined
): ClaimableRewards {
  const stakedLpTokenTracker = getValueUsd(rewardTrackerData?.stakedLpTokenTracker.claimable, gmxPriceE30);
  const feeLpTokenTracker = getValueUsd(rewardTrackerData?.feeLpTokenTracker.claimable, nativeTokenPriceE30);
  const lpTokenTrackerTotal =
    stakedLpTokenTracker !== undefined && feeLpTokenTracker !== undefined
      ? stakedLpTokenTracker.add(feeLpTokenTracker)
      : undefined;

  const stakedEsGmxTracker = getValueUsd(rewardTrackerData?.stakedEsGmxTracker.claimable, gmxPriceE30);
  const feeEsGmxTracker = getValueUsd(rewardTrackerData?.feeEsGmxTracker.claimable, nativeTokenPriceE30);
  const esGmxTrackerTotal =
    stakedEsGmxTracker !== undefined && feeEsGmxTracker !== undefined
      ? stakedEsGmxTracker.add(feeEsGmxTracker)
      : undefined;

  const stakedGlpTracker = getValueUsd(rewardTrackerData?.stakedGlpTracker.claimable, gmxPriceE30);
  const feeGlpTracker = getValueUsd(rewardTrackerData?.feeGlpTracker.claimable, nativeTokenPriceE30);
  const glpTrackerTotal =
    stakedGlpTracker !== undefined && feeGlpTracker !== undefined ? stakedGlpTracker.add(feeGlpTracker) : undefined;

  const esGmxRewards =
    stakedLpTokenTracker !== undefined && stakedEsGmxTracker !== undefined && stakedGlpTracker !== undefined
      ? stakedLpTokenTracker.add(stakedEsGmxTracker).add(stakedGlpTracker)
      : undefined;

  const nativeTokenRewards =
    feeLpTokenTracker !== undefined && feeEsGmxTracker !== undefined && feeGlpTracker !== undefined
      ? feeLpTokenTracker.add(feeEsGmxTracker).add(feeGlpTracker)
      : undefined;

  const total =
    esGmxRewards !== undefined && nativeTokenRewards !== undefined ? esGmxRewards.add(nativeTokenRewards) : undefined;

  return {
    stakedLpTokenTracker,
    feeLpTokenTracker,
    lpTokenTrackerTotal,

    stakedEsGmxTracker,
    feeEsGmxTracker,
    esGmxTrackerTotal,

    stakedGlpTracker,
    feeGlpTracker,
    glpTrackerTotal,

    esGmxRewards,
    nativeTokenRewards,
    total,
  };
}
