import { ethers } from "ethers";
import { getContract } from "./contracts";
import { ARBITRUM, AVALANCHE, BASE, BASE_TESTNET, FANTOM, FANTOM_TESTNET } from "./chains";
import { Token } from "domain/tokens";
import { getIcon } from "./icons";

export const TOKENS: { [chainId: number]: Token[] } = {
  [ARBITRUM]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    {
      name: "Frax",
      symbol: "FRAX",
      decimals: 18,
      address: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      decimals: 18,
      address: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [AVALANCHE]: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      isWrapped: true,
      baseSymbol: "AVAX",
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Ethereum (WETH.e)",
      symbol: "ETH",
      address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Bitcoin (BTC.b)",
      symbol: "BTC",
      address: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Bitcoin (WBTC.e)",
      symbol: "WBTC",
      address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin (USDC.e)",
      symbol: "USDC.e",
      address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      address: "0x130966628846BFd36ff31a822705796e8cb8C18D",
      decimals: 18,
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [FANTOM_TESTNET]: [
    {
      name: "Test Fantom",
      symbol: "FTM",
      address: "0xB6d688F6aFE3f34DBC006259E91b7cfFDcdf3359",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/4001/small/Fantom_round.png?1604021818",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x5F8cd73416Bc7427d9598791d49a4BE1eDbBEc95",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin Multichain",
      symbol: "USDCM",
      decimals: 6,
      address: "0x0F893ca9083c395EE8A9B37eEA08B2A16c1355eD",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
    },
  ],
  [FANTOM]: [
    {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/4001/small/Fantom_round.png?16040218188",
    },
    {
      name: "Wrapped FTM",
      symbol: "WFTM",
      decimals: 18,
      address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      isWrapped: true,
      baseSymbol: "FTM",
      imageUrl: "https://assets.coingecko.com/coins/images/4001/small/Fantom_round.png?1604021818",
    },
    // Multichain USDC, TODO rename once we integrate native USDC
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
    },
  ],
  [BASE]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x4200000000000000000000000000000000000006",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
    },
  ],
  [BASE_TESTNET]: [
    {
      name: "Test ETH",
      symbol: "ETH",
      address: "0x4E3BF16aE7240dE48D8424b8604fD8EAB4Ffa10C",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xae126cC799c2715B724A559c9A5Dc436DBF5C23F",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
    },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [ARBITRUM]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(ARBITRUM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "esGMX",
      address: getContract(ARBITRUM, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [AVALANCHE]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(AVALANCHE, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "esGMX",
      address: getContract(AVALANCHE, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  // TODO change image URLs
  [FANTOM_TESTNET]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(FANTOM_TESTNET, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "GMX",
      address: getContract(FANTOM_TESTNET, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(FANTOM_TESTNET, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  // Unused code? Why not rename?
  [FANTOM]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(FANTOM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "GMX",
      address: getContract(FANTOM, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(FANTOM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [BASE]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(BASE, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "GMX",
      address: getContract(BASE, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(BASE, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],

  [BASE_TESTNET]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(BASE_TESTNET, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed GMX",
      symbol: "GMX",
      address: getContract(BASE_TESTNET, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(BASE_TESTNET, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [ARBITRUM]: {
    // arbitrum
    GMX: {
      name: "GMX",
      symbol: "GMX",
      decimals: 18,
      address: getContract(ARBITRUM, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    GLP: {
      name: "GMX LP",
      symbol: "GLP",
      decimals: 18,
      address: getContract(ARBITRUM, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [AVALANCHE]: {
    // avalanche
    GMX: {
      name: "GMX",
      symbol: "GMX",
      decimals: 18,
      address: getContract(AVALANCHE, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    GLP: {
      name: "GMX LP",
      symbol: "GLP",
      decimals: 18,
      address: getContract(AVALANCHE, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [FANTOM_TESTNET]: {
    VMX: {
      name: "VMX",
      symbol: "VMX",
      decimals: 18,
      address: getContract(FANTOM_TESTNET, "GMX"),
      imageUrl: "https://app.voodoo.trade" + getIcon(FANTOM_TESTNET, "gmx"),
    },
    VLP: {
      name: "Staked VMX LP",
      symbol: "sVLP",
      decimals: 18,
      address: getContract(FANTOM_TESTNET, "StakedGlp"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://app.voodoo.trade" + getIcon(FANTOM_TESTNET, "glp"),
    },
  },
  [FANTOM]: {
    VMX: {
      name: "VMX",
      symbol: "VMX",
      decimals: 18,
      address: getContract(FANTOM, "GMX"),
      imageUrl: "https://app.voodoo.trade" + getIcon(FANTOM, "gmx"),
    },
    VLP: {
      name: "Staked VMX LP",
      symbol: "sVLP",
      decimals: 18,
      address: getContract(FANTOM, "StakedGlp"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://app.voodoo.trade" + getIcon(FANTOM, "glp"),
    },
  },
  [BASE]: {
    VMX: {
      name: "VMX",
      symbol: "VMX",
      decimals: 18,
      address: getContract(BASE, "GMX"),
      imageUrl: "https://app.voodoo.trade" + getIcon(BASE, "gmx"),
    },
    VLP: {
      name: "Staked VMX LP",
      symbol: "sVLP",
      decimals: 18,
      address: getContract(BASE, "StakedGlp"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://app.voodoo.trade" + getIcon(BASE, "glp"),
    },
  },
  [BASE_TESTNET]: {
    VMX: {
      name: "VMX",
      symbol: "VMX",
      decimals: 18,
      address: getContract(BASE_TESTNET, "GMX"),
      imageUrl: "https://app.voodoo.trade" + getIcon(BASE_TESTNET, "gmx"),
    },
    VLP: {
      name: "Staked VMX LP",
      symbol: "sVLP",
      decimals: 18,
      address: getContract(BASE_TESTNET, "StakedGlp"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://app.voodoo.trade" + getIcon(BASE_TESTNET, "glp"),
    },
  },
};

export const ICONLINKS = {
  [ARBITRUM]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      arbitrum: "https://arbiscan.io/token/0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=ARBITRUM",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      arbitrum: "https://arbiscan.io/address/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      arbitrum: "https://arbiscan.io/address/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "https://arbiscan.io/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      arbitrum: "https://arbiscan.io/address/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      arbitrum: "https://arbiscan.io/address/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      arbitrum: "https://arbiscan.io/address/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      arbitrum: "https://arbiscan.io/address/0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    },
  },
  [AVALANCHE]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      avalanche: "https://snowtrace.io/address/0x62edc0692bd897d2295872a9ffcac5425011c661",
    },
    GLP: {
      avalanche: "https://snowtrace.io/address/0x9e295B5B976a184B14aD8cd72413aD846C299660",
      reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=AVAX",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://snowtrace.io/address/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
    },
    WBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      avalanche: "https://snowtrace.io/address/0x50b7545627a5162f82a992c33b87adc75187b218",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin-avalanche-bridged-btc-b",
      avalanche: "https://snowtrace.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      avalanche: "https://snowtrace.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://snowtrace.io/address/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      avalanche: "https://snowtrace.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    },
  },
  [FANTOM_TESTNET]: {
    VMX: {
      // TODO add coingecko once it's live
      // coingecko: "https://www.coingecko.com/en/coins/gmx",
      explorerLink: "https://testnet.ftmscan.com/address/0xF7FF3c23Ae115C86d6Ac9E548039091647087607",
    },
    VLP: {
      explorerLink: "https://testnet.ftmscan.com/address/0x2Be6d53338659D2f93539f19bD4D5C8548965381", // fsGLP or stakedGlpTracker
      // reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=AVAX",
    },
    FTM: {
      coingecko: "https://www.coingecko.com/en/coins/fantom",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      explorerLink: "https://testnet.ftmscan.com/address/0x2A14776A383B6E926aa91cB51eC938B650e49Fa5",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      explorerLink: "https://testnet.ftmscan.com/address/0x5F8cd73416Bc7427d9598791d49a4BE1eDbBEc95",
    },
    USDCM: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      explorerLink: "https://testnet.ftmscan.com/address/0x0F893ca9083c395EE8A9B37eEA08B2A16c1355eD",
    },
  },
  [FANTOM]: {
    VMX: {
      // TODO add coingecko once it's live
      // coingecko: "https://www.coingecko.com/en/coins/gmx",
      explorerLink: "https://ftmscan.com/address/0x1e89e41dF23162d05acaA18539E6173d59640000",
    },
    VLP: {
      explorerLink: "https://ftmscan.com/address/0x35b6c5d66F737199E481454cdB5c909604710b45", // fsGLP or stakedGlpTracker
      // reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=AVAX",
    },
    FTM: {
      coingecko: "https://www.coingecko.com/en/coins/fantom",
    },
    USDC: {
      // multichain USDC
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      explorerLink: "https://ftmscan.com/address/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
    },
  },
  [BASE]: {
    VMX: {
      // TODO add coingecko once it's live
      // coingecko: "https://www.coingecko.com/en/coins/gmx",
      explorerLink: "https://basescan.org/address/0x44d05c7Bf593b4cC4322088fFCa35613079E8D37",
    },
    VLP: {
      explorerLink: "https://basescan.org/address/0x17A9CA5Fb8e1edB970caD49867d5b9299d3D1cA7", // fsGLP or stakedGlpTracker
      // reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=AVAX",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      explorerLink: "https://basescan.org/address/0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
    },
  },
  [BASE_TESTNET]: {
    VMX: {
      // TODO add coingecko once it's live
      // coingecko: "https://www.coingecko.com/en/coins/gmx",
      explorerLink: "https://goerli.basescan.org/address/0xF706c81172e15F450c60D41D24e64b70396abD8c",
    },
    VLP: {
      explorerLink: "https://goerli.basescan.org/address/0x750612B151Ee55fc22FBf07Beb2ad898fb4a34Dd", // fsGLP or stakedGlpTracker
      // reserves: "https://portfolio.nansen.ai/dashboard/gmx?chain=AVAX",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      explorerLink: "https://goerli.basescan.org/address/0x4E3BF16aE7240dE48D8424b8604fD8EAB4Ffa10C",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      explorerLink: "https://goerli.basescan.org/address/0xae126cC799c2715B724A559c9A5Dc436DBF5C23F",
    },
  },
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  // BTC: "#F7931A",
  // WBTC: "#F7931A",
  // USDC: "#2775CA",
  // "USDC.e": "#2A5ADA",
  // USDT: "#67B18A",
  // MIM: "#9695F8",
  // FRAX: "#000",
  // UNI: "#E9167C",
  // AVAX: "#E84142",
  // LINK: "#3256D6",

  FTM: "#2A5ADA",
  USDC: "#67B18A",
  USDCM: "#9695F8",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [ARBITRUM, AVALANCHE, FANTOM_TESTNET, FANTOM, BASE, BASE_TESTNET];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number): Token | undefined {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  const nativeTokens = {
    [FANTOM_TESTNET]: {
      symbol: "FTM",
    },
    [FANTOM]: {
      symbol: "FTM",
    },
    [BASE]: {
      symbol: "ETH",
    },
    [BASE_TESTNET]: {
      symbol: "ETH",
    },
  };

  return nativeTokens[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  if (chainId === FANTOM && symbol === "FTM") {
    symbol = "WFTM";
  }
  if (chainId === BASE && symbol === "ETH") {
    symbol = "WETH";
  }
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function getNormalizedTokenSymbol(tokenSymbol: string) {
  if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
    return tokenSymbol.slice(1);
  }
  return tokenSymbol;
}

const AVAILABLE_CHART_TOKENS = {
  [ARBITRUM]: ["ETH", "BTC", "LINK", "UNI"],
  [AVALANCHE]: ["AVAX", "ETH", "BTC"],
  [FANTOM_TESTNET]: ["FTM", "ETH", "BTC"],
  [FANTOM]: ["FTM", "USDC"],
  [BASE]: ["ETH"], // No need of USDC chart
  [BASE_TESTNET]: ["ETH"],
};

export function isChartAvailableForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;
  return (token.isStable || AVAILABLE_CHART_TOKENS[chainId]?.includes(getNormalizedTokenSymbol(tokenSymbol))) ?? false;
}
