import { ethers } from "ethers";
import { ARBITRUM, AVALANCHE, BASE, BASE_TESTNET, FANTOM, FANTOM_TESTNET } from "./chains";

const { AddressZero } = ethers.constants;

const CONTRACTS = {
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    GlpManager: "0x3963FfC9dff443c2A94f21b129D429891E32ec18",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    GlpRewardRouter: "0xB95DB5B167D75e6d04227CfFFA61069348d271F5",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
  [AVALANCHE]: {
    // avalanche
    Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
    Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
    VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
    Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
    GlpManager: "0xD152c7F25db7F4B95b7658323c5F33d176818EE4",
    RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
    GlpRewardRouter: "0xB70B91CE0771d3f4c81D87660f71Da31d48eB3B3",
    RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
    NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    GLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    GMX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
    ES_GMX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
    BN_GMX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
    USDG: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedGmxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
    BonusGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    FeeGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    StakedGlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
    FeeGlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

    StakedGmxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    StakedGlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

    GmxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
    GlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

    OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

    PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
    PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

    TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
    ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
    ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
  [FANTOM_TESTNET]: {
    Vault: "0xe5AC010e00dffeA9e70104Ef0a1E8a51e05BFE44",
    USDG: "0x6f844133783A76C637AEb483Dbd1AF4Ab13FC892",
    Router: "0x44b4A847B909B7175D28a26994426f55B6E81069",
    VaultPriceFeed: "0x188312eF9c24Da415df31AAc9408D384D7e66767",
    GLP: "0x0EE4bF502597aC8b2324dF3C956A5cB8455C5716",
    ShortsTracker: "0x685911AFC450AAc91184F135Cb93D86D8614896D",
    GlpManager: "0xcA5dfC8d8718A135CBaE21b3cC933233673D7Ad1",
    VaultErrorController: "0xe4a457a36D11663DFB459721a822D349b6990543",
    VaultUtils: "0xBd8b193F4957e5E9fB89c4A21e794074F8B6ee4c",
    PositionRouter: "0x137BFDd7f05df154e2c6B96f6cb67e2dBf6ba386",
    ReferralStorage: "0xe6BCbAb825E3af2EA661De9bc0B46895B2667c74",
    ReferralReader: "0xB27360fe596f1B9f0f6442DF0055225E120A4b09",
    OrderBook: "0x67F7ce9A2B828055F5DF6ea31E5f9b7794Af99dA",
    GMX: "0x640182C413caDb911399aA4601A6803c6101a7ab",
    ES_GMX: "0xFEB90E80D2054F05744dB9AE5ba96E08743F93e1",
    lpToken: "0x535f2c47A1426AAc6609C5EF661819F7D5FB713F",
    BN_LP_TOKEN: "0xC11C2443f100b858ae9FE3de382c99D66A1f7994",
    StakedLpTokenTracker: "0xd4022D42B00b9a0cFbA2b2bE1cfa1c3f95F47AD8",
    StakedLpTokenDistributor: "0x957d759A11d935df185F62BC41988de77b1aa25D",
    BonusLpTokenTracker: "0x5314a5f4208c258a3987D4deab403455E6203598",
    BonusLpTokenDistributor: "0x160FDaFF3fC7A02B4d1c6B0613E22CEFcE2b50a6",
    FeeLpTokenTracker: "0x3E811C7471Fe66BEcE580c9849Dac516d09F8d17",
    FeeLpTokenDistributor: "0x89107eE9d3c529A4aF8d3Ff854716B38525Bb1BE",
    StakedEsGmxTracker: "0x063D184df6208d13AB0988e461899787c1F09086",
    StakedEsGmxDistributor: "0xbAFb53229aC871EaA576c15D04B4342901192dd9",
    FeeEsGmxTracker: "0x8985113aEb555152A8a1462E1cA3FF1aE4d0c5A9",
    FeeEsGmxDistributor: "0x86A4b0E7a9ee572e742C521391CBf8b1723056eE",
    FeeGlpTracker: "0xD1735046893A617c5F60eda624c233243d610254",
    FeeGlpDistributor: "0x570cD3d0548db880B7b30036Aad9087f863b02f2",
    StakedGlpTracker: "0xD53630a900afD499795b495E5716bA9CE30877Aa",
    StakedGlpDistributor: "0x6243620fcBB6760b6FDa38789A3889CE69AbDA23",
    LpTokenVester: "0xE19b44e6d2A373DA39C15d9cd62F2F2c74Ac40cE",
    EsGmxVester: "0x5Af18B3FdA04326Cd89A57F51CC43d8112F990BD",
    GlpVester: "0xfDd66190c5a2359b766e4d54F9899B30F0DB71C1",
    RewardRouter: "0xda504461E59E2C1Df25e7968799f26e504CdE3F8",
    EsGmxBatchSender: "0x36734F92CC0611E6b8964aB7d2dd4F666712fe6F",
    PositionManager: "0xAFce6619b75EF2C8045A61A20237021467Ea54a5",
    FastPriceEvents: "0x7a3C99D6A5FC682Ed9Ca6F8d04789ef5956D3974",
    FastPriceFeed: "0x28E449aFc8a04eD97Ca840Aa83B64C7C1f5f55aD",
    TokenManager: "0xc82E6632Ddb175A2fd37C5d329646FF723C26f9A",
    Timelock: "0xA5Bd838d37d159224B1F3D4DE114973057f24A6d",
    StakedGlp: "0xa4Ac6B4d9CAfCDe013eF732179656903EEaE1D6D",
    GlpBalance: "0x774F9a4C58fc0B716f6964B3bC3772d8844Bca9E",
    BalanceUpdater: "0xeFfE87C1076bC22eeAFD1Ee457C94D8B433AAB83",
    BatchSender: "0x8aBBE1E3dA3E6666F92d660601b3F0cB75d078fc",
    OrderBookReader: "0xa217d5E50844516caD2f5a17c774405d88a9b5A2",
    Reader: "0x9c61D8aEce030567A1Ce5bbbADC4650f88b66bd7",
    RewardReader: "0x77d01d9150F19789DCD2056c29532f94DD35cAc4",
    VaultReader: "0x3A81358872d456cde5f44BC1339154ac14C8aC26",
    StakeManager: "0x541b27C0eFC1E3330cfe80110675BFcAdE4A39dd",
    lpRouter: "0x3fCEe05278B6049C92AcaCcaBD1F4e442b1124f5",

    NATIVE_TOKEN: "0xf1277d1Ed8AD466beddF92ef448A132661956621", // spookyswap WETH
    OrderExecutor: AddressZero, // deprecated
    Multicall: AddressZero, // Can't find usage, probably deprecated
    MultiFaucet: "0x0EE6F6f525678046b9d73BCB9cc6C429b61271b1",
  },
  [FANTOM]: {
    Vault: "0x40cbDDAED8b0d7Ee3cF347aAb09Bf4a8cFa15F01",
    USDG: "0x53889136e908fb9C076AeB16aD9bf92D642ffBcE",
    Router: "0xAA4144B3861f1EB1a478dE7991b8460D4fe09CAA",
    VaultPriceFeed: "0x15fBaa7Ece7308abb844c4177C08abb346085a02",
    GLP: "0x7Ded9AD207232Ab5E35E1dcdDEA4d60e5F22d02a",
    ShortsTracker: "0x77efeF069FaBf78De0c45Cc20ad2788ab9759173",
    GlpManager: "0xB721cb4333845C0eC3bC52e3ef83d4ad9C002886",
    VaultErrorController: "0x671FBEA49c7E90EBfe5761d8cd2Ba769487913dE",
    VaultUtils: "0x7D5D484Fca766677B65D936f070D37051F5B4E84",
    PositionRouter: "0xe0F053D53cddAd4c41e1A9834E24F7c8B7d0649d",
    ReferralStorage: "0xFBC4Ab6B0AF56a9570A6FC75b58C8939D263794a",
    ReferralReader: "0x4B97749c89FD3B177d9041EDac72Fe7c127D1d6a",
    OrderBook: "0x7c040Df91926324523AA09d0F0368a41dfb481ae",
    GMX: "0x1e89e41dF23162d05acaA18539E6173d59640000",
    ES_GMX: "0x82fb4a8F6f13bb88565AAB637e1a1987a5DFCa00",
    lpToken: "0xC42437A6da389D88799A9e706da3EA6628342295",
    BN_LP_TOKEN: "0x180D056f3aCeC5C6A2131fE0785DD00210a3d9B9",
    StakedLpTokenTracker: "0xBf47b011C36F29e7C65b6cf34c1d838EA1b67069",
    StakedLpTokenDistributor: "0x7a575Ec10ed35b1da92723Ec0B2D8f5032A17154",
    BonusLpTokenTracker: "0xdb658F54FEa17E172dFbC070420c3E6b089716b5",
    BonusLpTokenDistributor: "0x5dE4db0CC43227F6e125D12dB2792F173E7D7e20",
    FeeLpTokenTracker: "0x1A11464DF53056502D57c67d215E8bf91E9db868",
    FeeLpTokenDistributor: "0x147B70cb37050EFD410743c75b8F77C864E34b1A",
    StakedEsGmxTracker: "0x943699BEa433F4ea67461A6C6f3c5Da17A0bf8B0",
    StakedEsGmxDistributor: "0x2FDFA751BCb5f2d8967ebaCAea4A9Eb9aEA3467E",
    FeeEsGmxTracker: "0x8CDE719c54192cDC22aC5D6A2D13c265556dC968",
    FeeEsGmxDistributor: "0x316a9d217059cc17C09fdDc7157AC4a177C03B79",
    FeeGlpTracker: "0x9989Fc97C1E565630B0482b977B180a841410a9a",
    FeeGlpDistributor: "0xa09511aFfF33bAd4F2D837A0DAe772ccAD79D430",
    StakedGlpTracker: "0x35b6c5d66F737199E481454cdB5c909604710b45",
    StakedGlpDistributor: "0x5129b9807Ad97CbDdccdE6BdE3fC865B88484a34",
    LpTokenVester: "0xfed3f1C30A0A95ABe5E0B56b2bEfDB37F055b257",
    EsGmxVester: "0xf1016f774fC97fc0FCa38c05dA7572c95ba891fB",
    GlpVester: "0x8f5a0ff2d916b8042B786700EA3f8DBE1ED013ed",
    RewardRouter: "0x9EA54491a052fA3bD64aB07B603Ec17BAf587ef7",
    lpRouter: "0xD15481AB8775C92DB67Cbb55Fe219F1595025369",
    EsGmxBatchSender: "0x2d559e60CF6B35F8fA3D855E2d421739FE3F6CF6",
    PositionManager: "0xb71017D1E9e2E9750f6B34A498dbf25c4fdE5d7F",
    FastPriceEvents: "0x5f99342557Db1cB75fF8064aE08F41183A4b7C6A",
    FastPriceFeed: "0xE9FE23824C1dd7A4728bE87a5e0b38fdcB814043",
    TokenManager: "0xaEd847E91134A6a525cD5F47A6924ed8C52F2A64",
    Timelock: "0xb1240047240378a6E5BF60f68c38c5e28da8fddF",
    StakedGlp: "0xb881239b2780Fc5bb0b71cCB7e82fd7Ac3BE5Be3",
    GlpBalance: "0x036DB49AF3D8C334ad020A648Ef6D14B97d46896",
    BalanceUpdater: "0xe4D44Cb25Fd12997cf92C52ad7Fb45217f288c0e",
    BatchSender: "0xF53f5B6807a560CA0B3D7756aAbF1b674Cf53248",
    OrderBookReader: "0xaA43b4bf0bA149D0f90209809B5e980Ad94DBF2e",
    Reader: "0xc298F28211f1356c7f8e6E16663A094FFf12987d",
    RewardReader: "0xa28f214E692E2504AC38ECb55B3810f17c19eBe7",
    VaultReader: "0x7a3c13287E98687Ef4A0c47b7D5FfB2170952ae2",
    StakeManager: "0x7B63855e228563b0ef4D8C014dB87B1736Ce336F",

    NATIVE_TOKEN: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83", // spookyswap WETH
    OrderExecutor: AddressZero, // deprecated
    Multicall: AddressZero, // Can't find usage, probably deprecated
  },
  [BASE]: {
    Vault: "0x4F188Afdc40e6D2Ddddf5fd1b2DF7AEF7Da52f50",
    USDG: "0x9ADBF75Db88E965e0d522F93F3c373341B62C1F2",
    Router: "0x336B4E044047A3f26b1Eb0a345CB00D2eF61225C",
    VaultPriceFeed: "0x75dBE30332d9C59963CFbC39f7c044254AccDE36",
    GLP: "0x17A9CA5Fb8e1edB970caD49867d5b9299d3D1cA7",
    ShortsTracker: "0x59D8b510EEb0E5E97CecE560CF018021CFa1C43e",
    GlpManager: "0xDA9B1f3C4d7C70582f4Ae1e5B122dEe6cA2D1923",
    VaultErrorController: "0x1142229FBaD4852EA513ceD6F375B5cDDdA74f71",
    VaultUtils: "0x65c5f4B832ffd7BFd7127010A68E82577EedA0E5",
    PositionRouter: "0x066778941Cf6b9e56BC428192960e9ca6772b62d",
    ReferralStorage: "0xA54121a849Ae68e7d3495a768880B250FAB20a0c",
    ReferralReader: "0x651880e029a3DD50a4103cd9E68F0600F8F5F229",
    OrderBook: "0x6f8511B37e3d63cA4739a7Fb2627fa0a3bF146A2",
    GMX: "0x44d05c7Bf593b4cC4322088fFCa35613079E8D37",
    ES_GMX: "0x04742560c19DB19d929403456DB3b36ACB222443",
    lpToken: "0xbF65A2775F0a091a8e667a1c1345c427C9D86761",
    BN_LP_TOKEN: "0x19296b8009e96A647b71fA16255dB280C407b33b",
    StakedLpTokenTracker: "0x1DD46Dd21F152f97848b32D504de491E696bA1C5",
    StakedLpTokenDistributor: "0x4e57E1DC006248986AD6a34cD546A0EE679BE87E",
    BonusLpTokenTracker: "0x262F72aab70AA9fDE299f7Cf6B8c99F1a93DE517",
    BonusLpTokenDistributor: "0xcE575992DeC457ed85695a576230754eD64142c1",
    FeeLpTokenTracker: "0x83C250c45F9820b2dc4069340FA8e83De266a4D9",
    FeeLpTokenDistributor: "0xC2F0F8468a49eC0CB719aB947813aA2f4e884846",
    StakedEsGmxTracker: "0x3ed7c719C902FA6DE8E0DFf0788a62F623964B14",
    StakedEsGmxDistributor: "0x20B705dd287d8DDFf3A1Fd796d54a8Ec42efc34A",
    FeeEsGmxTracker: "0x6A46C468820CF3611A8f9EBB8ec76F4e65Ce0f8C",
    FeeEsGmxDistributor: "0xCe03F039318a2fDFC6988bCC0Daba65e63c6Cb3E",
    FeeGlpTracker: "0x96b9B538E37E94654c01e0ABB2372f1dbDe53a50",
    FeeGlpDistributor: "0x780716534539E6C758D6C841154Ff7235341d5A8",
    StakedGlpTracker: "0x3263A4263c6F60c2D3bf744e2374b9db4052D493",
    StakedGlpDistributor: "0x1fAecA99011a3BE969f5951c7A39Fdb21F1FE114",
    LpTokenVester: "0xa2FE59DcFb1362ac2d0c840B4940CEB843153B95",
    EsGmxVester: "0x487b311Ad2FEf05fb349f367F4B8219933391A66",
    GlpVester: "0xf66dE3E29AA8C642BF8e9f509eEf39d49b69F55C",
    RewardRouter: "0xbcac9235FCE811Bffc89Bd6d80E480Dc19883f94",
    lpRouter: "0x0A91275128eE906A4401EEE5510020525b79Eb51",
    EsGmxBatchSender: "0x45cc5404466DACD5c595961De19103243CcFC89F",
    PositionManager: "0x5D8b575E6968f9F4a59a7E4F07bB6A7730e9590b",
    FastPriceEvents: "0x2Ed7F17BdF9a345c7e09F0c2Dd5B02B287f83bA2",
    FastPriceFeed: "0x4fdd516a93FC937c76F03c6D605648Ed1D62Dd25",
    TokenManager: "0x5B245E69Ae9c8De11183AA37573B33fcC5c4a737",
    Timelock: "0x76AD05929EC24c9ea858185416Ea9BD8270D9A40",
    StakedGlp: "0x717B77E04F92FAa745eaB97332659Eb04Bc8CFc3",
    GlpBalance: "0x4bcDdb0a87283Ebf13b142B7D7B5CD6925A3B94d",
    BalanceUpdater: "0x3c20a2A8aE0CaB83ca146C6c26Ee5ab3c6B55090",
    BatchSender: "0xA6E3CEfbefDc0Af4FC3405DE936191A9E96F3336",
    OrderBookReader: "0x310DF40620f2F423cfCae4D7b083BE5e72BF56B6",
    Reader: "0x8172d3Bc5b8585EE373B2dDe3878fA99618D291B",
    RewardReader: "0x31c426133e35afc6760c4078DbFc0980818d8490",
    VaultReader: "0x1E86a6E44Ded53820fD05650D6FA0935224a0fc4",
    StakeManager: "0xa91C614e3A4F2578585082c1579e592685B152C7",

    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006",
    OrderExecutor: AddressZero, // deprecated
    Multicall: AddressZero, // Can't find usage, probably deprecated
  },

  [BASE_TESTNET]: {
    Vault: "0x486e04170c56AE69f2765B28E068A2a5708B81Cb",
    USDG: "0x96b9B538E37E94654c01e0ABB2372f1dbDe53a50",
    Router: "0x780716534539E6C758D6C841154Ff7235341d5A8",
    VaultPriceFeed: "0x94e92D1F62aAE36Fe1f76D68B7DCE1Bdf810Df85",
    GLP: "0x750612B151Ee55fc22FBf07Beb2ad898fb4a34Dd",
    ShortsTracker: "0x116913aA24294852eeA92Cf88EBfE772E66Cc82E",
    GlpManager: "0x7bEa886F08921ba9683B0e78cCaD6bEBBf205512",
    VaultErrorController: "0x942BFeBDB693e6e8645bB6679eC3d9F37C58957b",
    VaultUtils: "0x4D91FB99B9Fe8618525095Ec4640540a11d0BB6A",
    PositionRouter: "0x3B2c51E75F4827367bf8874d286a74b7f364CC58",
    ReferralStorage: "0x1dBfd2c0479e231B727629fc53610661b28659C8",
    ReferralReader: "0x2710A3c1a155Df8DBADE5B97b1E43336903c5E9a",
    OrderBook: "0x5e4Fa1208AeD957d23530D5a90e66AD834193939",
    GMX: "0xF706c81172e15F450c60D41D24e64b70396abD8c",
    ES_GMX: "0x2Ec09534F948024c9552E1553FDCf925F8447628",
    lpToken: "0x8c676A9cFB1f105B27e5529Dd504adBf4E1bD296",
    BN_LP_TOKEN: "0x15Fd4aA20772C9C51EA8eFfAed718675b5F0f2Bf",
    StakedLpTokenTracker: "0xeAa3b7F07F9d7B91B28e702Fb9f9823bEc9b8B02",
    StakedLpTokenDistributor: "0xF3971BF9a60B81BF4eB6A9e37C7Dd2f5Ffa0fdEE",
    BonusLpTokenTracker: "0x880782BfcE3660dA8eDf9A48ac75551008892f4b",
    BonusLpTokenDistributor: "0x4b4fe2Cf4243c1FdF428f6B92A495d8560aEB91F",
    FeeLpTokenTracker: "0x1Aac88AB5aB384AF4DA3EFEf8124228e6342790E",
    FeeLpTokenDistributor: "0xaE22650C12F669e625C68cA4a5BbAc7b1d33e31C",
    StakedEsGmxTracker: "0xa7797007FEb1F8966a703edaccBA1C5cdF0F4f8c",
    StakedEsGmxDistributor: "0x3B31304a633Db204139C3676d4335D66DEC71A37",
    FeeEsGmxTracker: "0x92F3a629d0d8b2D042BB17134CC0416603416a1F",
    FeeEsGmxDistributor: "0x5D8b575E6968f9F4a59a7E4F07bB6A7730e9590b",
    FeeGlpTracker: "0x36F305e03A9c2e6063050e471D9cb1b2187c39eb",
    FeeGlpDistributor: "0xEe397990F1215581ffB55D4D33485449de161a00",
    StakedGlpTracker: "0x2216A2e9b6B6Be1b54Ae53ADF87194a0f56F9Db5",
    StakedGlpDistributor: "0xdcd088063c81783Ce679032E91FbDF74A6d0Bfb2",
    LpTokenVester: "0x3FdC39d524Ef2A83798C5F3dCBE370305c209C30",
    EsGmxVester: "0x4f7074905284a742703793dA8C1C9379953D127B",
    GlpVester: "0x48bbd8fA4df9CB913C614bCeD3eD66D46886D57C",
    RewardRouter: "0xC626CB922aF3c3d2223A65a3BfBF2836fB319A11",
    lpRouter: "0x77e0B6FEAAc37197119a3aec2801a643574bDAa5",
    EsGmxBatchSender: "0xD08D6759232c9d8c67143868db1540e00c4e14A6",
    PositionManager: "0xbB1CC807a203419576C46286A75fdF122bf72e73",
    FastPriceEvents: "0x41DE4bC6CaDd4d3D0b6B4c4e747e13D2C518ce8c",
    FastPriceFeed: "0x591080E7eB41D1E0E4586c4d7ECfBdA3C31dD11a",
    TokenManager: "0x1B5aF29FFC470d0a6FC060Ed187f980801fd8A81",
    Timelock: "0x8e5e63472919B0A772C8E6Aa7445973D07c68233",
    StakedGlp: "0x569431B7d14712458A45A2acCf4dE4a74E59a7b8",
    GlpBalance: "0x0A91275128eE906A4401EEE5510020525b79Eb51",
    BalanceUpdater: "0x8070d4aa0aC95d2DD763c013ea9aF312265aDB10",
    BatchSender: "0xa4dA3123821d317eCDd8B88375a0744a3f181b3F",
    OrderBookReader: "0xff8C59637808B3136d5D17db685ad529120CF499",
    Reader: "0xc4cbe5927e666785d1C2773e20A47495B8bf9347",
    RewardReader: "0x985E799FE6B5271689Dd311AB22D5876f9a811e2",
    VaultReader: "0x39062264c438B28606FDB9DC04be674304bB5da7",
    StakeManager: "0x29a67a944055C612D6AC2cc45651d3B2BD6966c3",

    NATIVE_TOKEN: "0x4200000000000000000000000000000000000006",
    OrderExecutor: AddressZero, // deprecated
    Multicall: AddressZero, // Can't find usage, probably deprecated
    MultiFaucet: "0x737C1F3f79f5d3747Ff0EcBcAaCADF98392Ba28d",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
