import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import SEO from "components/Common/SEO";
import { getPageTitle, shortenAddress } from "lib/legacy";
import Footer from "components/Footer/Footer";

import "./Leaderboard.css";

import useLeaderboard from "./useLeaderboard";
import { LeaderboardTableLarge } from "components/Leaderboard/LeaderboardTableLarge";
import { LeaderboardCardRow } from "components/Leaderboard/LeaderboardCardRow";
import { LeaderboardSortDropdown } from "components/Leaderboard/LeaderboardSortDropdown";

import TradersSearchImage from "img/TradersSearchImage.svg";
import ClearButtonImage from "img/clearButtonImage.svg";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { Trans } from "@lingui/macro";

import starsImg from "img/Stars.svg";
import potImg from "img/Pot.svg";
import skeletonImg from "img/Skeleton.svg";

export type SortOption = "RoI %" | "Trading Volume" | "Net Profit" | "Margin";

export const sortOptions: SortOption[] = ["RoI %", "Trading Volume", "Net Profit", "Margin"];

export function Leaderboard() {
  const { traderStats: leaderboardData, traderCount, totalProfit, totalVolume } = useLeaderboard();

  const [sortBy, setSortBy] = useState<SortOption>("RoI %");
  const [sortAscending, setSortAscending] = useState(false);

  const [searchAddress, setSearchAddress] = useState<string | null>(null);

  const [searchField, setSearchField] = useState<string | null>(null);

  const { account } = useWeb3React();

  const searchOnClick = () => {
    setSearchAddress(searchField === "" ? null : searchField);
  };

  const clearOnClick = () => {
    setSearchField(null);
  };

  const handleSearchField = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchField(value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchOnClick();
    }
  };

  // Subgraph addresses are lowercase
  const lowercaseAddress = useMemo(() => {
    return searchAddress?.toLowerCase();
  }, [searchAddress]);

  const sortedLeaderboardData = useMemo(() => {
    return searchAddress !== null
      ? [...leaderboardData].filter((item) => {
          return item.wallet === lowercaseAddress;
        })
      : [...leaderboardData].sort((itemA, itemB) => {
          let sortResult = 0;
          switch (sortBy) {
            case "RoI %":
              sortResult = itemA.RoI - itemB.RoI;
              break;

            case "Net Profit":
              sortResult = itemA.netProfit - itemB.netProfit;
              break;

            case "Trading Volume":
              sortResult = itemA.tradingVolume - itemB.tradingVolume;
              break;

            case "Margin":
              sortResult = itemA.totalCollateral - itemB.totalCollateral;
              break;
          }

          return sortResult * (sortAscending ? 1 : -1);
        });
  }, [leaderboardData, lowercaseAddress, sortAscending, sortBy, searchAddress]);

  useEffect(() => {
    if (searchField === "" || searchField === null) {
      setSearchAddress(null);
    }
  }, [searchField]);

  return (
    <SEO title={getPageTitle("Leaderboard")}>
      <div className="background__wrapper">
        <div className="backgroundStars">
          <img src={starsImg} alt="stars" className="backgroundStars__image" />
        </div>
        <div className="backgroundPot">
          <img src={potImg} alt="pot" className="backgroundPot__image" />
        </div>
        <div className="backgroundSkeleton">
          <img src={skeletonImg} alt="pot" className="backgroundSkeleton__image" />
        </div>
      </div>
      <div className="Leaderboard page-layout z-index-100">
        <div className="Leaderboard-container default-container">
          {/* Title */}
          <div className="Leaderboard-title-block-wrapper">
            <div className="Leaderboard-title-wrapper">
              <div className="Leaderboard-title">Leaderboard</div>

              {/* Sort selector for mobile */}
              <div className="Leaderboard-sort-dropdown-wrapper">
                <LeaderboardSortDropdown
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  sortAscending={sortAscending}
                  setSortAscending={setSortAscending}
                />
              </div>
            </div>
            <div className="Leaderboard-title-search-block-wrapper">
              {account != null && (
                <button
                  className="Leaderboard-button-outline"
                  onClick={() => {
                    if (account) {
                      setSearchAddress(account);
                      setSearchField(account);
                    }
                  }}
                >
                  my rank
                </button>
              )}

              <div className="Leaderboard-title-search-wrapper">
                <img
                  src={TradersSearchImage}
                  alt="TradersSearchImage"
                  className="Leaderboard-title-search-image"
                  onClick={searchOnClick}
                />
                <img
                  src={ClearButtonImage}
                  alt="ClearButtonImage"
                  className="Leaderboard-title-clear-image"
                  onClick={clearOnClick}
                />
                <input
                  type="text"
                  className="Leaderboard-title-search"
                  placeholder="Enter or paste address"
                  value={searchField || ""}
                  onChange={handleSearchField}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>

          <div>
            <ExternalLink href="https://medium.com/@voodootrade/voodoo-incentivised-testnet-phase-two-e41fa1c47f5a">
              <Trans>Click for rules</Trans>
            </ExternalLink>
          </div>

          <>
            {/* Stats */}
            <div className="Stats-container">
              <div className="Stats-item">
                <div className="Stats-label">Traders</div>
                <div className="Stats-value">{traderCount}</div>
              </div>
              <div className="Stats-item">
                <div className="Stats-label">Volume</div>
                <div className="Stats-value">$ {totalVolume.toLocaleString()}</div>
              </div>
              <div className="Stats-item">
                <div className="Stats-label">Net profit</div>
                <div className={totalProfit > 0 ? "Stats-value-profit" : "Stats-value-loss"}>
                  $ {totalProfit.toLocaleString()}
                </div>
              </div>
            </div>

            {/* Table body- large */}
            <div className="Leaderboard-table-wrapper">
              <LeaderboardTableLarge
                sortedLeaderboardData={sortedLeaderboardData}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortAscending={sortAscending}
                setSortAscending={setSortAscending}
              />
            </div>

            {/* Table body- mobile */}
            <div className="Leaderboard-cards-wrapper">
              {sortedLeaderboardData.map((data) => {
                const { wallet, wins, losses, tradingVolume, netProfit, RoI, top, totalCollateral } = data;

                const showRoI = RoI === 0 ? "0" : RoI.toFixed(2);

                const winAndLoss = `${wins} | ${losses}`;

                const shortenWalletAddress = shortenAddress(wallet, 13);

                return (
                  <div key={top} className="Leaderboard-card">
                    <LeaderboardCardRow top={top} label="Top" value={top} />
                    <LeaderboardCardRow label="Wallet" value={shortenWalletAddress} wallet={true} />
                    <LeaderboardCardRow label="RoI %" value={showRoI} />
                    <LeaderboardCardRow label="Win | Loss" value={winAndLoss} />
                    <LeaderboardCardRow label="Trading Volume" value={tradingVolume.toLocaleString()} usd={true} />
                    <LeaderboardCardRow label="Margin" value={totalCollateral.toLocaleString()} usd={true} />
                    <LeaderboardCardRow
                      label="Net Profit"
                      value={Math.abs(netProfit).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      valueColor={netProfit >= 0 ? "#16d156" : "#df1417"}
                      usd={true}
                    />
                  </div>
                );
              })}
            </div>
          </>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
