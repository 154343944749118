import { BigNumber, ethers } from "ethers";
import { gql } from "@apollo/client";
import { useState, useEffect, useMemo } from "react";
import useSWR from "swr";

import ReferralStorage from "abis/ReferralStorage.json";
import { MAX_REFERRAL_CODE_LENGTH, isAddressZero, isHashZero } from "lib/legacy";
import { getContract } from "config/contracts";
import { REGEX_VERIFY_BYTES32 } from "components/Referrals/referralsHelper";
import { ARBITRUM, AVALANCHE, BASE, BASE_TESTNET, FANTOM, FANTOM_TESTNET } from "config/chains";
import {
  arbitrumReferralsGraphClient,
  avalancheReferralsGraphClient,
  fantomReferralsGraphClient,
  fantomTestnetReferralsGraphClient,
  baseReferralsGraphClient,
  baseTestnetReferralsGraphClient,
} from "lib/subgraph/clients";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { REFERRAL_CODE_KEY } from "config/localStorage";
import { getProvider } from "lib/rpc";
import { Web3Provider } from "@ethersproject/providers";

const ACTIVE_CHAINS = [ARBITRUM, AVALANCHE, FANTOM_TESTNET, FANTOM, BASE, BASE_TESTNET];
const DISTRIBUTION_TYPE_REBATES = "1";
const DISTRIBUTION_TYPE_DISCOUNT = "2";

function getGraphClient(chainId: number) {
  if (chainId === ARBITRUM) {
    return arbitrumReferralsGraphClient;
  } else if (chainId === AVALANCHE) {
    return avalancheReferralsGraphClient;
  } else if (chainId === FANTOM_TESTNET) {
    return fantomTestnetReferralsGraphClient;
  } else if (chainId === FANTOM) {
    return fantomReferralsGraphClient;
  } else if (chainId === BASE) {
    return baseReferralsGraphClient;
  } else if (chainId === BASE_TESTNET) {
    return baseTestnetReferralsGraphClient;
  }
  throw new Error(`Unsupported chain ${chainId}`);
}

export function decodeReferralCode(hexCode: string) {
  try {
    return ethers.utils.parseBytes32String(hexCode);
  } catch (ex) {
    let code = "";
    hexCode = hexCode.substring(2);
    for (let i = 0; i < 32; i++) {
      code += String.fromCharCode(parseInt(hexCode.substring(i * 2, i * 2 + 2), 16));
    }
    return code.trim();
  }
}

export function encodeReferralCode(code: string) {
  let final = code.replace(/[^\w_]/g, ""); // replace everything other than numbers, string  and underscor to ''
  if (final.length > MAX_REFERRAL_CODE_LENGTH) {
    return ethers.constants.HashZero;
  }
  return ethers.utils.formatBytes32String(final);
}

async function getCodeOwnersData(network: number, account: string, codes: string[] = []) {
  if (codes.length === 0 || !account || !network) {
    return undefined;
  }
  const query = gql`
    query allCodes($codes: [String!]!) {
      referralCodes(where: { code_in: $codes }) {
        owner
        id
      }
    }
  `;
  return getGraphClient(network)
    .query({ query, variables: { codes } })
    .then(({ data }) => {
      const { referralCodes } = data;
      const codeOwners = referralCodes.reduce((acc, cv) => {
        acc[cv.id] = cv.owner;
        return acc;
      }, {});
      return codes.map((code) => {
        const owner = codeOwners[code];
        return {
          code,
          codeString: decodeReferralCode(code),
          owner,
          isTaken: Boolean(owner),
          isTakenByCurrentUser: owner && owner.toLowerCase() === account.toLowerCase(),
        };
      });
    });
}

interface ReferrerStat {
  referralCode: string;
  volume: string;
  trades: string;
  tradedReferralsCount: string;
  registeredReferralsCount: string;
  totalRebateUsd: string;
  discountUsd: string;
}

export interface ParsedReferrerStat {
  volume: BigNumber;
  trades: number;
  tradedReferralsCount: number;
  registeredReferralsCount: number;
  totalRebateUsd: BigNumber;
  discountUsd: BigNumber;
  referralCode: string;
}

interface ParsedDistribution {
  receiver: string;
  amount: ethers.BigNumber;
  typeId: string;
  token: string;
  transactionHash: string;
  timestamp: number;
}

interface Distribution {
  receiver: string;
  amount: string;
  typeId: string;
  token: string;
  transactionHash: string;
  timestamp: string;
}

interface ReferralsDataResp {
  distributions: Distribution[];
  statsPerCode: ReferrerStat[];
  referrerLastDayStats: ReferrerStat[];
  referralCodes: { code: string }[];
  referralTotalStats: {
    volume: string;
    discountUsd: string;
  } | null;
  referrerTierInfo: {
    tierId: string;
    id: string;
    discountShare: string;
  } | null;
}

interface CumulativeStats {
  totalRebateUsd: BigNumber;
  volume: BigNumber;
  discountUsd: BigNumber;
  trades: number;
  tradedReferralsCount: number;
  registeredReferralsCount: number;
}

export interface ReferralsData {
  rebateDistributions: ParsedDistribution[];
  discountDistributions: ParsedDistribution[];
  referrerTotalStats: ParsedReferrerStat[];
  referrerTierInfo: {
    tierId: string;
    id: string;
    discountShare: string;
  } | null;
  referrerLastDayStats: ParsedReferrerStat[];
  cumulativeStats: CumulativeStats;
  codes: string[];
  referralTotalStats: {
    volume: BigNumber;
    discountUsd: BigNumber;
  };
}

export function useReferralsData(chainId: number | undefined, account: string | null | undefined) {
  const [data, setData] = useState<ReferralsData>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!chainId || !account) {
      setLoading(false);
      return;
    }
    const startOfDayTimestamp = Math.floor(Math.floor(Date.now() / 1000) / 86400) * 86400;

    const query = gql`
      query referralData($typeIds: [String!]!, $account: String!, $timestamp: Int!, $referralTotalStatsId: String!) {
        distributions(
          first: 1000
          orderBy: timestamp
          orderDirection: desc
          where: { receiver: $account, typeId_in: $typeIds }
        ) {
          receiver
          amount
          typeId
          token
          transactionHash
          timestamp
        }
        # Total stats per referral code
        statsPerCode: referrerStats(
          first: 1000
          orderBy: volume
          orderDirection: desc
          where: { period: total, referrer: $account }
        ) {
          referralCode
          volume
          trades
          tradedReferralsCount
          registeredReferralsCount
          totalRebateUsd
          discountUsd # meaning?
        }
        referrerLastDayStats: referrerStats(
          first: 1000
          where: { period: daily, referrer: $account, timestamp: $timestamp }
        ) {
          referralCode
          volume
          trades
          tradedReferralsCount
          registeredReferralsCount
          totalRebateUsd
          discountUsd
        }
        referralCodes(first: 1000, where: { owner: $account }) {
          code
        }
        referralTotalStats: referralStat(id: $referralTotalStatsId) {
          volume
          discountUsd
        }
        referrerTierInfo: referrer(id: $account) {
          tierId
          id
          discountShare
        }
      }
    `;
    setLoading(true);

    getGraphClient(chainId)
      .query<ReferralsDataResp>({
        query,
        variables: {
          typeIds: [DISTRIBUTION_TYPE_REBATES, DISTRIBUTION_TYPE_DISCOUNT],
          account: (account || "").toLowerCase(),
          timestamp: startOfDayTimestamp,
          referralTotalStatsId: account && `total:0:${account.toLowerCase()}`,
        },
      })
      .then((res) => {
        const rebateDistributions: ParsedDistribution[] = [];
        const discountDistributions: ParsedDistribution[] = [];
        res.data.distributions.forEach((d) => {
          const item = {
            timestamp: parseInt(d.timestamp),
            transactionHash: d.transactionHash,
            receiver: ethers.utils.getAddress(d.receiver),
            amount: BigNumber.from(d.amount),
            typeId: d.typeId,
            token: ethers.utils.getAddress(d.token),
          };
          if (d.typeId === DISTRIBUTION_TYPE_REBATES) {
            rebateDistributions.push(item);
          } else {
            discountDistributions.push(item);
          }
        });

        const { referrerTierInfo, referralCodes, statsPerCode, referrerLastDayStats, referralTotalStats } = res.data;

        const parsedReferrerTotalStats = statsPerCode.map(numberifyStat);
        const parsedReferrerLastDayStats = referrerLastDayStats.map(numberifyStat);

        // Sum of array items
        const cumulativeStats = parsedReferrerTotalStats.reduce(
          (acc, cv) => {
            acc.totalRebateUsd = acc.totalRebateUsd.add(cv.totalRebateUsd);
            acc.volume = acc.volume.add(cv.volume);
            acc.discountUsd = acc.discountUsd.add(cv.discountUsd);
            acc.trades = acc.trades + cv.trades;
            acc.tradedReferralsCount = acc.tradedReferralsCount + cv.tradedReferralsCount;
            acc.registeredReferralsCount = acc.registeredReferralsCount + cv.registeredReferralsCount;
            return acc;
          },
          {
            totalRebateUsd: BigNumber.from(0),
            volume: BigNumber.from(0),
            discountUsd: BigNumber.from(0),
            trades: 0,
            tradedReferralsCount: 0,
            registeredReferralsCount: 0,
          }
        );

        setData({
          rebateDistributions,
          discountDistributions,
          referrerTotalStats: parsedReferrerTotalStats,
          referrerTierInfo,
          referrerLastDayStats: parsedReferrerLastDayStats,
          cumulativeStats,
          codes: referralCodes.map((e) => decodeReferralCode(e.code)),
          referralTotalStats: {
            volume: BigNumber.from(referralTotalStats?.volume ?? 0),
            discountUsd: BigNumber.from(referralTotalStats?.discountUsd ?? 0),
          },
        });
      })
      // eslint-disable-next-line no-console
      .catch(console.warn)
      .finally(() => {
        setLoading(false);
      });
  }, [setData, chainId, account]);

  return {
    data: data,
    loading,
  };
}

function numberifyStat(e: ReferrerStat): ParsedReferrerStat {
  return {
    volume: BigNumber.from(e.volume),
    trades: parseInt(e.trades),
    tradedReferralsCount: parseInt(e.tradedReferralsCount),
    registeredReferralsCount: parseInt(e.registeredReferralsCount),
    totalRebateUsd: BigNumber.from(e.totalRebateUsd),
    discountUsd: BigNumber.from(e.discountUsd),
    referralCode: decodeReferralCode(e.referralCode),
  };
}

export function registerReferralCode(chainId: number, referralCode: string, library: Web3Provider, opts): Promise<any> {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const referralCodeHex = encodeReferralCode(referralCode);
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, library.getSigner());
  return callContract(chainId, contract, "registerCode", [referralCodeHex], opts);
}

export async function setTraderReferralCodeByUser(chainId: number, referralCode: string, library: Web3Provider, opts) {
  const referralCodeHex = encodeReferralCode(referralCode);
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, library.getSigner());
  const codeOwner = await contract.codeOwners(referralCodeHex);
  if (isAddressZero(codeOwner)) {
    const errorMsg = "Referral code does not exist";
    helperToast.error(errorMsg);
    return Promise.reject(errorMsg);
  }
  return callContract(chainId, contract, "setTraderReferralCodeByUser", [referralCodeHex], opts);
}

export async function getReferralCodeOwner(chainId: number, referralCode: string) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const provider = getProvider(undefined, chainId);
  const contract = new ethers.Contract(referralStorageAddress, ReferralStorage.abi, provider);
  const codeOwner = await contract.codeOwners(referralCode);
  return codeOwner;
}

export function useUserReferralCode(library: Web3Provider, chainId: number, account: string | null | undefined) {
  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY);
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const { data: onChainCode } = useSWR<string>(
    account != null ? ["ReferralStorage", chainId, referralStorageAddress, "traderReferralCodes", account] : null,
    { fetcher: contractFetcher(library, ReferralStorage) }
  );

  const { data: localStorageCodeOwner } = useSWR(
    localStorageCode && REGEX_VERIFY_BYTES32.test(localStorageCode)
      ? ["ReferralStorage", chainId, referralStorageAddress, "codeOwners", localStorageCode]
      : null,
    { fetcher: contractFetcher(library, ReferralStorage) }
  );

  const [attachedOnChain, userReferralCode, userReferralCodeString] = useMemo(() => {
    if (onChainCode && !isHashZero(onChainCode)) {
      return [true, onChainCode, decodeReferralCode(onChainCode)];
    } else if (localStorageCode && localStorageCodeOwner && !isAddressZero(localStorageCodeOwner)) {
      return [false, localStorageCode, decodeReferralCode(localStorageCode)];
    }
    return [false];
  }, [localStorageCode, localStorageCodeOwner, onChainCode]);

  return {
    userReferralCode,
    userReferralCodeString,
    attachedOnChain,
  };
}

export function useReferrerTier(library: Web3Provider | undefined, chainId: number, account?: string) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const { data: referrerTier, mutate: mutateReferrerTier } = useSWR<BigNumber>(
    account !== undefined
      ? [`ReferralStorage:referrerTiers`, chainId, referralStorageAddress, "referrerTiers", account]
      : null,
    {
      fetcher: contractFetcher(library, ReferralStorage),
    }
  );
  return {
    referrerTier,
    mutateReferrerTier,
  };
}

export function useCodeOwner(
  library: Web3Provider | undefined,
  chainId: number,
  account: string | null | undefined,
  codeBytes32?: string
) {
  const referralStorageAddress = getContract(chainId, "ReferralStorage");
  const { data: codeOwner, error } = useSWR<string>(
    account !== undefined && codeBytes32 !== undefined
      ? [`ReferralStorage:codeOwners`, chainId, referralStorageAddress, "codeOwners", codeBytes32]
      : null,
    {
      fetcher: contractFetcher(library, ReferralStorage),
    }
  );
  return codeOwner;
}

export async function validateReferralCodeExists(referralCode, chainId) {
  const referralCodeBytes32 = encodeReferralCode(referralCode);
  const referralCodeOwner = await getReferralCodeOwner(chainId, referralCodeBytes32);
  return !isAddressZero(referralCodeOwner);
}

interface ReferralStats {
  referrerTotalStats: {
    referralCode: string;
  }[];
}

export interface AffiliateCode {
  code: string | undefined;
  success: boolean;
}
export function useAffiliateCode(chainId: number, account: string): AffiliateCode {
  const [affiliateCodes, setAffiliateCodes] = useState<AffiliateCode>({ code: undefined, success: false });
  const query = gql`
    query userReferralCodes($account: String!) {
      referrerTotalStats: referrerStats(
        first: 1000
        orderBy: volume
        orderDirection: desc
        where: { period: total, referrer: $account }
      ) {
        referralCode
      }
    }
  `;
  useEffect(() => {
    if (!chainId) return;
    getGraphClient(chainId)
      .query<ReferralStats>({ query, variables: { account: account?.toLowerCase() } })
      .then((res) => {
        const parsedAffiliateCodes = res?.data?.referrerTotalStats.map((c) => decodeReferralCode(c?.referralCode));
        setAffiliateCodes({ code: parsedAffiliateCodes[0], success: true });
      });
    return () => {
      setAffiliateCodes({ code: undefined, success: false });
    };
  }, [chainId, query, account]);
  return affiliateCodes;
}
