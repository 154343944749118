import { Trans, t } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Modal from "components/Modal/Modal";
import { approveTokens } from "domain/tokens";
import { BigNumber, ethers } from "ethers";
import { contractFetcher, callContract } from "lib/contracts";
import { formatAmount, formatAmountFree, parseValue } from "lib/numbers";
import { useEffect, useState } from "react";
import useSWR from "swr";

import RewardRouter from "abis/RewardRouterV3.json";
import Token from "abis/Token.json";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";

const { AddressZero } = ethers.constants;

interface StakeEsGmxModalProps {
  stakeEsGmx: boolean;
  setStakeEsGmx: React.Dispatch<React.SetStateAction<boolean>>;
  esGmxBalance: BigNumber | undefined;
  setPendingTxns: any;
}

export default function StakeLpTokenModal({
  stakeEsGmx,
  setStakeEsGmx,
  esGmxBalance,
  setPendingTxns,
}: StakeEsGmxModalProps) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const [value, setValue] = useState<string>("");
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const lpTokenAddress = getContract(chainId, "lpToken");
  const esGmxAddress = getContract(chainId, "ES_GMX");
  const stakedLpTokenTracker = getContract(chainId, "StakedLpTokenTracker");
  const stakedEsGmxTracker = getContract(chainId, "StakedEsGmxTracker");

  const isVisible = stakeEsGmx;
  function setIsVisible(visible: boolean) {
    setStakeEsGmx(visible);
  }

  const title = "Stake esVMX";
  const maxAmount = esGmxBalance;
  const stakingTokenSymbol = "esVMX";
  const stakingTokenAddress = esGmxAddress;
  const farmAddress = stakedEsGmxTracker;
  const stakeMethodName = "stakeEsGmx";

  const { data: tokenAllowance } = useSWR<BigNumber>(
    active && stakingTokenAddress !== undefined
      ? [active, chainId, stakingTokenAddress, "allowance", account, farmAddress]
      : null,
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = farmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return t`Enter an amount`;
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (stakingTokenAddress === undefined) {
      throw Error("stakingTokenAddress is undefined");
    }
    if (farmAddress === undefined) {
      throw Error("Farm is undefined");
    }

    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: farmAddress,
        chainId,
      });
      return;
    }

    if (stakeMethodName === undefined) {
      throw Error("stakeMethodName is undefined");
    }

    setIsStaking(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: t`Stake submitted!`,
      failMsg: t`Stake failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error || stakingTokenAddress === undefined || isApproving || isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return t`Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return t`Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return t`Staking...`;
    }
    return t`Stake`;
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">
                <Trans>Stake</Trans>
              </div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 4))}>
              {/* <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}> */}
              <Trans>Max: {formatAmount(maxAmount, 18, 4, true)}</Trans>
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button
            className="StakeV2-button-solid Exchange-swap-button"
            onClick={onClickPrimary}
            disabled={!isPrimaryEnabled()}
          >
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}
