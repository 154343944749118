/**
 * Estimates the amount of LP tokens received and its composition of GMX and native token.
 *
 * Rewritten in functional style for React.
 */
export function getZapEstimate({
  correctOrder,
  pairSupply,
  reserve0,
  reserve1,
  ethIn,
  slippageBps,
}: {
  correctOrder: boolean;
  pairSupply: bigint | undefined;
  reserve0: bigint | undefined;
  reserve1: bigint | undefined;
  ethIn: bigint;
  slippageBps: bigint;
}) {
  if (pairSupply === undefined || reserve0 === undefined || reserve1 === undefined) {
    throw Error("Pool state not fetched");
  }
  const [reserveGmx, reserveEth] = correctOrder ? [reserve0, reserve1] : [reserve1, reserve0];

  // Get ETH to swap, then subtract from initial amount to get eth added in  LP
  const swapFee = 25n; // 25bps i.e. 0.2% swap fee on Cake and Dackie
  const D = 10000n; // denominator
  const R = D - swapFee; // r number

  const ethToSwap =
    (sqrt(reserveEth * (ethIn * 4n * D * R + reserveEth * (D + R) * (D + R))) - reserveEth * (R + D)) / (R * 2n);

  const ethInWithFee = ethToSwap * 9975n;
  const gmxToLp = (ethInWithFee * reserveGmx) / (reserveEth * 10000n + ethInWithFee);
  const ethToLp = ethIn - ethToSwap;

  // Pool composition after swap
  const reserveGmxNew = reserveGmx - gmxToLp;
  const reserveEthNew = reserveEth + ethToSwap;
  // Amount of LP tokens added

  const liquidityIfGmxIsLess = (gmxToLp * pairSupply) / reserveGmxNew;
  const liquidityIfEthIsLess = (ethToLp * pairSupply) / reserveEthNew;
  const liquidity = liquidityIfGmxIsLess < liquidityIfEthIsLess ? liquidityIfGmxIsLess : liquidityIfEthIsLess;

  const liquidityMin = (liquidity * (D - slippageBps)) / D;

  const newPairSupply = pairSupply + liquidityMin;

  return {
    ethToSwap,
    gmxToLp,
    ethToLp,
    reserveGmxNew,
    reserveEthNew,
    liquidity,
    liquidityMin,
    newPairSupply,
  };
}

/**
 * Find squre root of a big integer, approximated to an integer
 * Eg. sqrt(7n) will be approximated to 2
 *
 * Source- https://stackoverflow.com/a/53684036
 *
 * @param value
 * @returns
 */
function sqrt(value: bigint) {
  if (value < 0n) {
    throw "square root of negative numbers is not supported";
  }

  if (value < 2n) {
    return value;
  }

  function newtonIteration(n: bigint, x0: bigint) {
    const x1 = (n / x0 + x0) >> 1n;
    if (x0 === x1 || x0 === x1 - 1n) {
      return x0;
    }
    return newtonIteration(n, x1);
  }

  return newtonIteration(value, 1n);
}
