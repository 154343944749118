import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Trans, t } from "@lingui/macro";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import TooltipComponent from "components/Tooltip/Tooltip";

import hexToRgba from "hex-to-rgba";
import { BigNumber, ethers } from "ethers";

import {
  USD_DECIMALS,
  GMX_DECIMALS,
  GLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDG_AMOUNT,
  getPageTitle,
  importImage,
} from "lib/legacy";

import Footer from "components/Footer/Footer";

import "./DashboardV2.css";

import AssetDropdown from "./AssetDropdown";
import SEO from "components/Common/SEO";
import useStats from "domain/useStats";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { useInfoTokens } from "domain/tokens";
import { getWhitelistedTokens, GLP_POOL_COLORS } from "config/tokens";
import { BigZero, bigNumberify, expandDecimals, formatAmount, formatKeyAmount, numberWithCommas } from "lib/numbers";
import { useChainId } from "lib/chains";
import { getIcons } from "config/icons";
import {
  getGmxPriceE30,
  usePoolReserves,
  useTeamGmxSupply,
  useStakedLpTokenSupply,
  useTokenSupply,
  useGmxSupply,
} from "../../domain/gmxHooks";
import { useTotalTokenWeights, useUncollectedFees } from "./vaultHooks";
import WeightText from "./WeightText";
import CustomTooltip from "./CustomTooltip";
import { useGlpAumUsdE30 } from "domain/glpHooks";
import { useNativeTokenPriceE30 } from "domain/prices";

import starsImg from "img/Stars.svg";
import potImg from "img/Pot.svg";
import skeletonImg from "img/Skeleton.svg";

export default function DashboardV2() {
  // Hooks
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();
  const stats = useStats();
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden);

  const currentIcons = getIcons(chainId);

  // Contract hooks
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  const gmxSupply = useGmxSupply();
  const teamGmxSupply = useTeamGmxSupply();
  const circulatingGmxSupply = gmxSupply?.sub(teamGmxSupply ?? BigNumber.from(0));

  const glpSupply = useTokenSupply("GLP");
  const lpTokenSupply = useTokenSupply("lpToken");
  const stakedLpTokenSupply = useStakedLpTokenSupply();

  const { gmxReserve, nativeTokenReserve: ethReserve } = usePoolReserves();

  const gmxInStakedLp =
    gmxReserve !== undefined &&
    stakedLpTokenSupply !== undefined &&
    lpTokenSupply !== undefined &&
    !lpTokenSupply.isZero()
      ? gmxReserve.mul(stakedLpTokenSupply).div(lpTokenSupply)
      : undefined;

  const nativeTokenPriceE30 = useNativeTokenPriceE30();
  const gmxPriceE30 = getGmxPriceE30(gmxReserve, ethReserve, nativeTokenPriceE30);

  const gmxMarketCapE30 =
    gmxPriceE30 && circulatingGmxSupply
      ? gmxPriceE30.mul(circulatingGmxSupply).div(expandDecimals(1, GMX_DECIMALS))
      : undefined;

  const gmxDilutedMarketCapE30 =
    gmxPriceE30 && gmxSupply ? gmxPriceE30.mul(gmxSupply).div(expandDecimals(1, GMX_DECIMALS)) : undefined;

  const lpTokenUsdE30 =
    gmxPriceE30 && gmxReserve ? gmxPriceE30.mul(gmxReserve).div(expandDecimals(1, GMX_DECIMALS)) : undefined;

  const glpAumUsdE30 = useGlpAumUsdE30();
  const totalTokenWeights = useTotalTokenWeights();
  const uncollectedFees = useUncollectedFees(infoTokens);

  let glpPriceE30: BigNumber | undefined;
  let glpMarketCapE30: BigNumber | undefined;

  if (glpAumUsdE30 && glpSupply) {
    glpPriceE30 =
      glpAumUsdE30 && glpAumUsdE30.gt(0) && glpSupply!.gt(0)
        ? glpAumUsdE30.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply!)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCapE30 = glpPriceE30.mul(glpSupply!).div(expandDecimals(1, GLP_DECIMALS));
  }

  const tvlE30 = (lpTokenUsdE30 ?? BigZero).add(glpMarketCapE30 ?? BigZero);

  let adjustedUsdgSupply = BigZero;

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  const stakedGmxPercent =
    gmxInStakedLp !== undefined && gmxSupply !== undefined && !gmxSupply.isZero()
      ? gmxInStakedLp
          .mul(100 * 100)
          .div(gmxSupply)
          .toNumber() / 100 // Show 2 decimal places
      : 0;

  let notStakedPercent = 100 - stakedGmxPercent;

  let gmxDistributionData = [
    {
      name: t`staked`,
      value: stakedGmxPercent,
      color: "#581DAD",
    },
    {
      name: t`not staked`,
      value: notStakedPercent,
      color: "#CC8CFB",
    },
  ];

  const totalStatsStartDate = "March 1, 2023";

  let stableGlp = 0;
  let totalGlp = 0;

  let glpPool: {
    fullname: string;
    name: string;
    value: number;
  }[] = [];
  for (const token of tokenList) {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdgAmount && adjustedUsdgSupply && adjustedUsdgSupply.gt(0)) {
      const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply);
      if (tokenInfo.isStable) {
        stableGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      glpPool.push({
        fullname: token.name,
        name: token.symbol,
        value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
      });
    }
  }
  glpPool = glpPool.sort(function (a, b) {
    // They could sort in the same loop
    if (a!.value < b!.value) return 1;
    else return -1;
  });

  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : "0.0";

  gmxDistributionData = gmxDistributionData.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  const [gmxActiveIndex, setGMXActiveIndex] = useState(null);

  const onGMXDistributionChartEnter = (_, index) => {
    setGMXActiveIndex(index);
  };

  const onGMXDistributionChartLeave = () => {
    setGMXActiveIndex(null);
  };

  const [glpActiveIndex, setGLPActiveIndex] = useState(null);

  const onGLPPoolChartEnter = (_, index) => {
    setGLPActiveIndex(index);
  };

  const onGLPPoolChartLeave = () => {
    setGLPActiveIndex(null);
  };

  return (
    <SEO title={getPageTitle("Dashboard")}>
      <div className="background__wrapper">
        <div className="backgroundStars">
          <img src={starsImg} alt="stars" className="backgroundStars__image" />
        </div>
        <div className="backgroundPot">
          <img src={potImg} alt="pot" className="backgroundPot__image" />
        </div>
        <div className="backgroundSkeleton">
          <img src={skeletonImg} alt="pot" className="backgroundSkeleton__image" />
        </div>
      </div>
      <div className="default-container DashboardV2 DashboardV2-page-layout dashboard z-index-100">
        <div className="DashboardV2-content">
          {/* Right stats card */}
          <div className="DashboardV2-right-cards dashboard__cards">
            {/* Table should be in this block */}
            <div className="section-title-block dashboard__section_title-block">
              <div className="section-title-icon"></div>
              <div className="section-title-content">
                <div className="Page-title section__title">
                  <Trans>Stats</Trans>
                </div>
                <div>
                  <Trans>
                    <div className="section__description">
                      Since {totalStatsStartDate}.<br />
                    </div>
                  </Trans>
                </div>
              </div>
            </div>

            {/* Overview card */}
            <div className="App-card dashboard__card">
              <div>
                <Trans>
                  <div className="dashboard__card_title">Overview</div>
                </Trans>
              </div>
              <div className="dashboard-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row dashboard__card_row">
                  <div className="label">
                    <Trans>
                      <div className="dashboard__card_label">Total value locked</div>
                    </Trans>
                  </div>
                  <div>${formatAmount(tvlE30, USD_DECIMALS, 3, true)}</div>
                </div>

                <div className="App-card-row dashboard__card_row">
                  <div className="label">
                    <Trans>
                      <div className="dashboard__card_label">24h Volume</div>
                    </Trans>
                  </div>
                  <div>${formatAmount(stats?.dailyVolume, USD_DECIMALS, 0, true)}</div>
                </div>

                <div className="App-card-row dashboard__card_row">
                  <div className="label">
                    <Trans>
                      <div className="dashboard__card_label">Long Positions</div>
                    </Trans>
                  </div>
                  <div>${formatAmount(stats?.longPositions, 0, 0, true)}</div>
                </div>
                <div className="App-card-row dashboard__card_row">
                  <div className="label">
                    <Trans>
                      <div className="dashboard__card_label">Short Positions</div>
                    </Trans>
                  </div>
                  <div>${formatAmount(stats?.shortPositions, 0, 0, true)}</div>
                </div>
                {true ? (
                  <div className="App-card-row dashboard__card_row">
                    <div className="label">
                      <Trans>
                        <span className="dashboard__card_label">
                          Uncollected fees since {stats?.lastWithdrawDate ?? totalStatsStartDate}
                        </span>
                      </Trans>
                    </div>
                    <div>${formatAmount(uncollectedFees, USD_DECIMALS, 2, true)}</div>
                  </div>
                ) : null}
              </div>
            </div>

            {/* Total stats card */}
            <div className="App-card dashboard__card">
              <div>
                <Trans>
                  <div className="dashboard__card_title">Total Stats</div>
                </Trans>
              </div>
              <div className="dashboard-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row dashboard__card_row">
                  <div className="label">
                    <Trans>
                      <div className="dashboard__card_label">Total Fees</div>
                    </Trans>
                  </div>
                  <div>${numberWithCommas(stats?.fees)}</div>
                </div>
                <div className="App-card-row dashboard__card_row">
                  <div className="label">
                    <Trans>
                      <div className="dashboard__card_label">Total Volume</div>
                    </Trans>
                  </div>
                  <div>${formatAmount(stats?.totalVolume, USD_DECIMALS, 0, true)}</div>
                </div>
              </div>
            </div>
          </div>

          {/* Left tokens card */}
          <div className="DashboardV2-left-cards">
            <div className="Page-title section__title">
              <Trans>Tokens</Trans>
            </div>
            <div>
              <Trans>
                <div className="section__description">Platform and VLP index tokens.</div>
              </Trans>
            </div>

            {/* Token cards section */}
            <div className="stats-wrapper stats-wrapper--gmx tokens__cards">
              {/* GLP card */}
              <div className="App-card dashboard__card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark dashboard__stats_title">
                      <div className="App-card-title-mark-icon">
                        {/* Update icon */}
                        <img src={currentIcons.gmx} width="40" alt="GMX Token Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">VMX</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="VMX" />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider separator"></div>

                  <div className="App-card-content">
                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Price</Trans>
                      </div>
                      <div>
                        {gmxPriceE30 !== undefined ? "$" + formatAmount(gmxPriceE30, USD_DECIMALS, 4, true) : "$0"}
                      </div>
                    </div>

                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Supply</Trans>
                      </div>
                      <div className="card__mainInfo">{formatAmount(gmxSupply, GMX_DECIMALS, 0, true)} VMX</div>
                    </div>

                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div>{formatAmount(gmxInStakedLp, GMX_DECIMALS, 4, true)} VMX</div>
                    </div>
                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Pancakeswap TVL</Trans>
                      </div>
                      <div>${formatAmount(lpTokenUsdE30, USD_DECIMALS, 2, true)}</div>
                    </div>
                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Market Cap</Trans>
                      </div>
                      <div className="card__mainInfo">${formatAmount(gmxMarketCapE30, USD_DECIMALS, 2, true)}</div>
                    </div>
                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Diluted market Cap</Trans>
                      </div>
                      <div className="card__mainInfo">
                        ${formatAmount(gmxDilutedMarketCapE30, USD_DECIMALS, 0, true)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseLeave={onGMXDistributionChartLeave}>
                  {gmxDistributionData.length > 0 && (
                    <div>
                      <PieChart width={210} height={210}>
                        <Pie
                          data={gmxDistributionData}
                          cx={100}
                          cy={100}
                          innerRadius={73}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                          startAngle={90}
                          endAngle={-270}
                          paddingAngle={2}
                          onMouseEnter={onGMXDistributionChartEnter}
                          onMouseOut={onGMXDistributionChartLeave}
                          onMouseLeave={onGMXDistributionChartLeave}
                        >
                          {gmxDistributionData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={entry.color}
                              style={{
                                filter:
                                  gmxActiveIndex === index
                                    ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})`
                                    : "none",
                                cursor: "pointer",
                              }}
                              stroke={entry.color}
                              strokeWidth={gmxActiveIndex === index ? 1 : 1}
                            />
                          ))}
                        </Pie>
                        <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                          <Trans>Distribution</Trans>
                        </text>
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>
                      <div className="dashboard__chart_info">
                        {stakedGmxPercent && (
                          <div className="dashboard__chart_info-value dashboard__chart_info-staked">
                            {stakedGmxPercent}% staked
                          </div>
                        )}
                        {notStakedPercent && (
                          <div className="dashboard__chart_info-value dashboard__chart_info-notStaked">
                            {notStakedPercent}% not staked
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* VLP card */}
              <div className="App-card dashboard__card">
                <div className="stats-block">
                  <div className="App-card-title dashboard__stats_title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={currentIcons.glp} width="40" alt="GLP Icon" />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">VLP</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="VLP" />
                      </div>
                    </div>
                  </div>
                  <div className="separator"></div>
                  <div className="App-card-content">
                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Price</Trans>
                      </div>
                      <div className="card__mainInfo">${formatAmount(glpPriceE30, USD_DECIMALS, 3, true)}</div>
                    </div>
                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Supply</Trans>
                      </div>
                      <div className="card__mainInfo">{formatAmount(glpSupply, GLP_DECIMALS, 0, true)} VLP</div>
                    </div>
                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Total Staked</Trans>
                      </div>
                      <div className="card__mainInfo">${formatAmount(glpMarketCapE30, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row dashboard__card_row">
                      <div className="label card__label">
                        <Trans>Market Cap</Trans>
                      </div>
                      <div className="card__mainInfo">${formatAmount(glpMarketCapE30, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label card__label">
                        <Trans>Stablecoin Percentage</Trans>
                      </div>
                      <div className="card__mainInfo">{stablePercentage}%</div>
                    </div>
                  </div>
                </div>

                {/* GLP pie chart */}
                <div className="stats-piechart" onMouseOut={onGLPPoolChartLeave}>
                  {glpPool.length > 0 && (
                    <div>
                      <PieChart width={210} height={210}>
                        <Pie
                          data={glpPool}
                          cx={100}
                          cy={100}
                          innerRadius={73}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                          startAngle={90}
                          endAngle={-270}
                          onMouseEnter={onGLPPoolChartEnter}
                          onMouseOut={onGLPPoolChartLeave}
                          onMouseLeave={onGLPPoolChartLeave}
                          paddingAngle={2}
                        >
                          {glpPool.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={GLP_POOL_COLORS[entry.name]}
                              style={{
                                filter:
                                  glpActiveIndex === index
                                    ? `drop-shadow(0px 0px 6px ${hexToRgba(GLP_POOL_COLORS[entry.name], 0.7)})`
                                    : "none",
                                cursor: "pointer",
                              }}
                              stroke={GLP_POOL_COLORS[entry.name]}
                              strokeWidth={glpActiveIndex === index ? 1 : 1}
                            />
                          ))}
                        </Pie>
                        <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                          VLP Pool
                        </text>
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>
                      <div className="dashboard__chart_bottom">
                        {glpPool.map((entry, index) => (
                          <div key={index} className="dashboard__chart_info-row">
                            <div
                              className="dashboard__chart_info-circle"
                              style={{ background: GLP_POOL_COLORS[entry.name] }}
                            ></div>
                            <div>
                              {entry.value}% {entry.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="DashboardV2-token-cards dashboard__table">
          <div className="token-table-wrapper App-card dashboard__card">
            <div className="App-card-title">
              <Trans>VLP Index Composition</Trans>
            </div>
            <table className="token-table">
              <thead>
                <tr>
                  <th className="dashboard__table_head-cell dashboard__table_head-cell_start">
                    <Trans>
                      <span className="dashboard__table_head-title">Token</span>
                    </Trans>
                  </th>
                  <th className="dashboard__table_head-cell">
                    <Trans>
                      <span className="dashboard__table_head-title">Price</span>
                    </Trans>
                  </th>
                  <th className="dashboard__table_head-cell">
                    <Trans>
                      <span className="dashboard__table_head-title">Pool</span>
                    </Trans>
                  </th>
                  <th className="dashboard__table_head-cell">
                    <Trans>
                      <span className="dashboard__table_head-title">Weight</span>
                    </Trans>
                  </th>
                  <th className="dashboard__table_head-cell dashboard__table_head-cell_end">
                    <Trans>
                      <span className="dashboard__table_head-title">Utilization</span>
                    </Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {visibleTokens.map((token) => {
                  const tokenInfo = infoTokens[token.address];
                  let utilization = bigNumberify(0);
                  if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                    utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                  }
                  let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
                  if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                    maxUsdgAmount = tokenInfo.maxUsdgAmount;
                  }
                  const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_40.svg");

                  return (
                    <tr key={token.symbol}>
                      <td>
                        <div className="token-symbol-wrapper">
                          <div className="App-card-title-info dashboard__table_item-name">
                            <div className="App-card-title-info-icon">
                              <img src={tokenImage} alt={token.symbol} width="32px" />
                            </div>
                            <div className="App-card-title-info-text">
                              <div className="App-card-info-title dashboard__table_token">{token.name}</div>
                              <div className="App-card-info-subtitle dashboard__table_symbol">{token.symbol}</div>
                            </div>
                            <div className="dashboard__table_dropdown">
                              <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="dashboard__table_price">
                        ${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}
                      </td>
                      <td className="dashboard__table_pool-cell">
                        <TooltipComponent
                          handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 2, true)}`}
                          position="right-bottom"
                          className="nowrap dashboard__table_pool"
                          renderContent={() => {
                            return (
                              <div className="tip__block tip__block_visible">
                                <StatsTooltipRow
                                  label={t`Pool Amount`}
                                  value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                    token.symbol
                                  }`}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={t`Target Min Amount`}
                                  value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                    token.symbol
                                  }`}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={t`Max ${tokenInfo.symbol} Capacity`}
                                  value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                  showDollar={true}
                                />
                              </div>
                            );
                          }}
                        />
                      </td>
                      <td className="dashboard__table_weight">
                        <WeightText
                          tokenInfo={tokenInfo}
                          adjustedUsdgSupply={adjustedUsdgSupply}
                          totalTokenWeights={totalTokenWeights}
                        />
                      </td>
                      <td>{formatAmount(utilization, 2, 2, false)}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="token-grid">
            {visibleTokens.map((token) => {
              const tokenInfo = infoTokens[token.address];
              let utilization = bigNumberify(0);
              if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
              }
              let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
              if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
                maxUsdgAmount = tokenInfo.maxUsdgAmount;
              }

              const tokenImage = importImage("ic_" + token.symbol.toLowerCase() + "_24.svg");
              return (
                <div className="dashboard__card" key={token.symbol}>
                  <div className="App-card-title">
                    <div className="mobile-token-card dashboard__table_item-name">
                      <img src={tokenImage} alt={token.symbol} width="20px" />
                      <div className="token-symbol-text">{token.symbol}</div>
                      <div>
                        <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Price</Trans>
                      </div>
                      <div>${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, 2, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Pool</Trans>
                      </div>
                      <div>
                        <TooltipComponent
                          handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 2, true)}`}
                          position="right-bottom"
                          renderContent={() => {
                            return (
                              <div className="tip__block tip__block_visible">
                                <StatsTooltipRow
                                  label={t`Pool Amount`}
                                  value={`${formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 0, true)} ${
                                    token.symbol
                                  }`}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={t`Target Min Amount`}
                                  value={`${formatKeyAmount(tokenInfo, "bufferAmount", token.decimals, 0, true)} ${
                                    token.symbol
                                  }`}
                                  showDollar={false}
                                />
                                <StatsTooltipRow
                                  label={t`Max ${tokenInfo.symbol} Capacity`}
                                  value={formatAmount(maxUsdgAmount, 18, 0, true)}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Weight</Trans>
                      </div>
                      <div>
                        <WeightText
                          tokenInfo={tokenInfo}
                          adjustedUsdgSupply={adjustedUsdgSupply}
                          totalTokenWeights={totalTokenWeights}
                        />
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Utilization</Trans>
                      </div>
                      <div>{formatAmount(utilization, 2, 2, false)}%</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </SEO>
  );
}
