import "./Referrals.css";
import React from "react";
// import { useLocalStorage } from "react-use";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import SEO from "components/Common/SEO";
// import Tab from "components/Tab/Tab";
import Loader from "components/Common/Loader";
import Footer from "components/Footer/Footer";
import { getPageTitle, isHashZero } from "lib/legacy";
import {
  useReferralsData,
  registerReferralCode,
  useCodeOwner,
  useReferrerTier,
  useUserReferralCode,
} from "domain/referrals";
import JoinReferralCode from "components/Referrals/JoinReferralCode";
import AffiliatesStats from "components/Referrals/AffiliatesStats";
import TradersStats from "components/Referrals/TradersStats";
import AddAffiliateCode from "components/Referrals/AddAffiliateCode";
import {
  deserializeSampleStats,
  isRecentReferralCodeNotExpired,
  ReferralCodeInfo,
} from "components/Referrals/referralsHelper";
import { ethers } from "ethers";
import { useLocalStorageSerializeKey } from "lib/localStorage";
// import { REFERRALS_SELECTED_TAB_KEY } from "config/localStorage";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ReferralsLink } from "components/Referrals/ReferralsLink";

import ReferralsConnectAccountWalletImage from "img/ReferralsConnectAccountWalletImage.svg";
import ReferralsDisconnectAccountWalletImage from "img/ReferralsDisconnectAccountWalletImage.svg";

import starsImg from "img/Stars.svg";
import potImg from "img/Pot.svg";
import skeletonImg from "img/Skeleton.svg";

const TRADERS = "Traders";
const AFFILIATES = "Affiliates";
// const TAB_OPTIONS = [TRADERS, AFFILIATES];

function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { active, account: walletAccount, library } = useWeb3React();
  const { account: queryAccount } = useParams<{ account?: string }>();

  const account =
    queryAccount && ethers.utils.isAddress(queryAccount) ? ethers.utils.getAddress(queryAccount) : walletAccount;

  const { chainId } = useChainId();
  const { data: referralsData, loading } = useReferralsData(chainId, account);

  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey<ReferralCodeInfo[]>(
    [chainId, "REFERRAL", account].toString(),
    [],
    {
      // @ts-ignore
      deserializer: deserializeSampleStats,
    }
  );

  const { userReferralCode, userReferralCodeString } = useUserReferralCode(library, chainId, account);
  const codeOwner = useCodeOwner(library, chainId, account, userReferralCode);
  const { referrerTier: traderTier } = useReferrerTier(library, chainId, codeOwner);

  function handleCreateReferralCode(referralCode: string): Promise<any> {
    return registerReferralCode(chainId, referralCode, library, {
      sentMsg: t`Referral code submitted!`,
      failMsg: t`Referral code creation failed.`,
      pendingTxns,
    });
  }

  // Performance of referral codes created by the user
  function AffiliatesTab() {
    if (loading) return <Loader />;
    if (referralsData && recentlyAddedCodes) {
      return (
        <AffiliatesStats
          referralsData={referralsData}
          handleCreateReferralCode={handleCreateReferralCode}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
          recentlyAddedCodes={recentlyAddedCodes.filter(isRecentReferralCodeNotExpired)}
          chainId={chainId}
        />
      );
    } else {
      return (
        <AddAffiliateCode
          handleCreateReferralCode={handleCreateReferralCode}
          active={active}
          connectWallet={connectWallet}
          recentlyAddedCodes={recentlyAddedCodes}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
        />
      );
    }
  }

  // The user's performance, currently active referral code and rebates earned from own volume
  function TradersTab() {
    if (loading) return <Loader />;
    if (isHashZero(userReferralCode) || !account || !userReferralCode) {
      return (
        <JoinReferralCode
          connectWallet={connectWallet}
          active={active}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
        />
      );
    }
    return (
      <TradersStats
        userReferralCodeString={userReferralCodeString}
        chainId={chainId}
        referralsData={referralsData}
        setPendingTxns={setPendingTxns}
        pendingTxns={pendingTxns}
        traderTier={traderTier}
      />
    );
  }

  return (
    <SEO title={getPageTitle("Referrals")}>
      <div className="background__wrapper">
        <div className="backgroundStars">
          <img src={starsImg} alt="stars" className="backgroundStars__image" />
        </div>
        <div className="backgroundPot">
          <img src={potImg} alt="pot" className="backgroundPot__image" />
        </div>
        <div className="backgroundSkeleton">
          <img src={skeletonImg} alt="pot" className="backgroundSkeleton__image" />
        </div>
      </div>
      <div className="default-container Referrals-page-layout Referrals z-index-100">
        <div className="Referrals-title-block">
          <div className="section-title-icon" />
          <div className="section-title-content">
            <div className="Referrals-page-title">
              <Trans>Referrals</Trans>
            </div>
          </div>
        </div>
        <div className="Referrals-block-wrapper">
          <div className="Referrals-block-left">
            <div className="Referrals-about">
              <Trans>Get fee discounts and earn rebates through the VMX referral program</Trans>
            </div>
            {active ? (
              <div className="Referrals-about-account-block-wrapper">
                <img
                  className="Referrals-about-account-block-wrapper-image"
                  src={ReferralsConnectAccountWalletImage}
                  alt="ReferralsConnectAccountWalletImage"
                />
                <div className="Referrals-about-account">{walletAccount}</div>
              </div>
            ) : (
              <div className="Referrals-about-account-block-wrapper">
                <img
                  className="Referrals-about-account-block-wrapper-image"
                  src={ReferralsDisconnectAccountWalletImage}
                  alt="ReferralsDisconnectAccountWalletImage"
                />
                <div className="Referrals-about-account-button-wrapper">
                  <button
                    className="referral-card-custom-button Referrals-about-account-button"
                    type="submit"
                    onClick={connectWallet}
                  >
                    <Trans>Connect Wallet</Trans>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="Referrals-block-right">
            <div className="referrals-tabs-wrapper">
              <div className="Referrals-about">
                <Trans>Your referrals</Trans>
              </div>
              <AffiliatesTab />
              <div className="Referrals-about">
                <Trans>Your stats</Trans>
              </div>
              {/* Trader stats */}
              <TradersTab />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </SEO>
  );
}

export default Referrals;
