import { Menu } from "@headlessui/react";
import InfoIcon from "../../img/info.svg";
import "./AssetDropdown.css";
import ArrowIcon from "../../img/arrowRight.svg";
import { useWeb3React } from "@web3-react/core";

import { Trans } from "@lingui/macro";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ICONLINKS, PLATFORM_TOKENS } from "config/tokens";
import { addTokenToMetamask } from "lib/wallets";
import { useChainId } from "lib/chains";
import { Token } from "domain/tokens";
import { FANTOM } from "config/chains";

type Props = {
  assetSymbol: string;
  assetInfo?: Token;
};

function AssetDropdown({ assetSymbol, assetInfo }: Props) {
  const { active } = useWeb3React();
  const { chainId } = useChainId();

  let { coingecko, explorerLink, reserves } = ICONLINKS[chainId][assetSymbol] || {};

  const unavailableTokenSymbols =
    {
      43114: ["AVAX"],
      [FANTOM]: ["FTM"]
    }[chainId] || [];

  return (
    <Menu>
      <div className="token__links">
        <Menu.Button as="div" className="dropdown-arrow center-both">
          <img src={InfoIcon} alt="info" />
        </Menu.Button>
        <div className="asset-menu-items tip__block tip__block_visible-left">
          {/* GMX opens GLP proof of reserves in Nansen. Disable for now */}
          <Menu.Item>
            <>
              {reserves && assetSymbol === "VLP" && (
                <ExternalLink href={reserves} className="token__links_link">
                  <div>
                    <Trans>Proof of Reserves</Trans>
                  </div>
                  <img src={ArrowIcon} alt="arrow" />
                </ExternalLink>
              )}
            </>
          </Menu.Item>

          <Menu.Item>
            <>
              {coingecko && (
                <ExternalLink href={coingecko} className="token__links_link">
                  <div>
                    <Trans>Coingecko</Trans>
                  </div>
                  <img src={ArrowIcon} alt="arrow" />
                </ExternalLink>
              )}
            </>
          </Menu.Item>
          <Menu.Item>
            <>
              {explorerLink && (
                <ExternalLink href={explorerLink} className="token__links_link">
                  <div>
                    <Trans>Explorer</Trans>
                  </div>
                  <img src={ArrowIcon} alt="arrow" />
                </ExternalLink>
              )}
            </>
          </Menu.Item>
          <Menu.Item>
            <>
              {active && !unavailableTokenSymbols.includes(assetSymbol) && (
                <div
                  onClick={() => {
                    let token = assetInfo
                      ? { ...assetInfo, image: assetInfo.imageUrl }
                      : PLATFORM_TOKENS[chainId][assetSymbol];

                    addTokenToMetamask(token);
                  }}
                  className="token__links_link link-hover"
                >
                  <div>
                    <Trans>Add to wallet</Trans>
                  </div>
                  <img src={ArrowIcon} alt="arrow" />
                </div>
              )}
            </>
          </Menu.Item>
        </div>
      </div>
    </Menu>
  );
}

export default AssetDropdown;
